import { useEffect } from "react";
import { Navigate, Route, Routes, generatePath, useLocation, useParams, Outlet } from "react-router-dom";
import { useFirstLoginCheck } from "./shared/hooks/useFirstLoginCheck";
import { useSignupCheck } from "./shared/hooks/useSignupCheck";
import { lazyWithRefresh } from "./lazyWithRefresh";
import { ProfilePage } from "./routes/ProfilePage";
import { ProtectedRoute } from "./shared/components/ProtectedRoute";
import { rudderstack } from "./rudderstack";
import { AddGhostPage } from "./meta/AddGhostPage";
import { AddMetaTags } from "./AddMetaTags";
import { FantasyLeaderboardPage } from "./routes/FantasyPage/routes/FantasyLeaderboardPage";
import { LeaderboardLayout } from "./routes/FantasyPage/routes/FantasyLeaderboardPage/components/LeaderboardLayout";
import { FantasyJoinPage } from "./routes/FantasyPage/routes/FantasyJoinPage";
import { ProfileView } from "./routes/ProfilePage/components/ProfileView";
import { ProfileRedeem } from "./routes/ProfilePage/components/ProfileRedeem";
import { ProfileRewards } from "./routes/ProfilePage/components/ProfileRewards";
import { usePointsEvents } from "./shared/hooks/usePointsEvents";
import { CSGuessrPage } from "./routes/CSGuessr";
import { usePostPage } from "./shared/hooks/usePostPage";
import { useAuth } from "./auth/AuthProvider/hooks/useAuth";
import { CSGuessrLocalPage } from "./routes/CSGuessrLocal";
import { useUserWallet } from "./shared/hooks/useUserWallet";
import { ClipEmbedPage } from "./routes/Clip/embed";
import { RainbowSixPage } from "./routes/RainbowSixPage";

const AccreditationPage = lazyWithRefresh(() => import("./routes/Accreditation"));
const CounterStriklePage = lazyWithRefresh(() => import("./routes/CounterStriklePage"));
const LivePage = lazyWithRefresh(() => import("./routes/Live"));
const BroadcastEnded = lazyWithRefresh(() => import("./routes/BroadcastEnded"));
const ContentPage = lazyWithRefresh(() => import("./routes/ContentPage"));
const HomePage = lazyWithRefresh(() => import("./routes/HomePage"));
const PickemsPage = lazyWithRefresh(() => import("./routes/Pickems"));
const TeamPage = lazyWithRefresh(() => import("./routes/TeamPage").then((module) => ({ default: module.TeamPage })));
const MatchPage = lazyWithRefresh(() => import("./routes/MatchPage"));
const RewatchPage = lazyWithRefresh(() => import("./routes/Rewatch"));
const ClipPage = lazyWithRefresh(() => import("./routes/Clip"));
const EpisodePage = lazyWithRefresh(() => import("./routes/4kEpisodePage"));
const ValvePickemsPage = lazyWithRefresh(() => import("./routes/ValvePickems"));
const FantasyPage = lazyWithRefresh(() => import("./routes/FantasyPage"));
const SignUpPage = lazyWithRefresh(() => import("./routes/SignUpPage"));
const ForgotPasswordPage = lazyWithRefresh(() => import("./routes/ForgotPasswordPage"));
const VerifyEmailErrorPage = lazyWithRefresh(() => import("./routes/VerifyEmailErrorPage"));
const PlayerPickemsPage = lazyWithRefresh(() => import("./routes/PlayerPickemsPage"));
const ModuleFederationTestPage = lazyWithRefresh(() => import("./routes/ModuleFederationTestPage"));
const PlayerProfilePage = lazyWithRefresh(() => import("./routes/Player/PlayerPage"));
const PlayerRedirect = lazyWithRefresh(() => import("./routes/Player/PlayerPageRedirect"));
const HigherOrLower = lazyWithRefresh(() => import("./routes/HigherLower/index"));
const HigherOrLowerClassic = lazyWithRefresh(() => import("./routes/HigherLower/ClassicHigherLower"));
const HigherOrLowerDaily = lazyWithRefresh(() => import("./routes/HigherLower/DailyHigherLower"));
const RocketLeagueLeaderboardPage = lazyWithRefresh(() => import("./routes/rocketleague/LeaderboardPage"));
const RocketLeagueEventPage = lazyWithRefresh(() => import("./routes/rocketleague/EventPage"));
const CSCompeteCreateTeamPage = lazyWithRefresh(() => import("./routes/CSCompetePage/routes/CreateTeam"));
const CSCompeteJoinTeamPage = lazyWithRefresh(() => import("./routes/CSCompetePage/routes/JoinTeam"));
const CSCompeteTournaments = lazyWithRefresh(() => import("./routes/CSCompetePage/routes/CSCompeteTournaments"));
const CSCompeteTournament = lazyWithRefresh(() => import("./routes/CSCompetePage/routes/CSCompeteTournament"));
const CSQuizPage = lazyWithRefresh(() => import("./routes/CSQuizPage"));
const DotaTournamentPage = lazyWithRefresh(() => import("./routes/Dota/TournamentPage"));

const Redirect = ({ to }: { to: string }) => {
  const params = useParams();
  const path = generatePath(to, params);
  return <Navigate to={path} replace />;
};

export function Routing(): JSX.Element {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const { mutate: postPointsPageEvent } = usePostPage();
  const { data: wallet } = useUserWallet();

  const walletExists = !!wallet;

  useEffect(() => {
    rudderstack?.page();
    if (isAuthenticated && walletExists) {
      postPointsPageEvent();
    }
  }, [location.pathname, isAuthenticated, postPointsPageEvent, walletExists]);

  useSignupCheck();
  useFirstLoginCheck();
  usePointsEvents();

  return (
    <>
      <AddGhostPage pathname={location.pathname} />
      <AddMetaTags />
      <Routes>
        {/* ContentPage can load the home page data from the CMS  */}
        <Route path="/" element={<HomePage />} />
        <Route path="/press" element={<AccreditationPage />} />
        <Route path="/live">
          <Route index element={<LivePage />} />
          <Route path="end" element={<BroadcastEnded />} />
          <Route path=":broadcastSlug" element={<LivePage />} />
        </Route>

        <Route path="/pickems" element={<PickemsPage />} />
        <Route path="/major/manager" element={<PlayerPickemsPage />} />
        <Route path="/teams/:slug" element={<TeamPage />} />
        <Route path="/rewatch/:id" element={<RewatchPage />} />
        <Route path="/clips/embed/:id/:slug?" element={<ClipEmbedPage />} />
        <Route path="/clips/:id/:slug?" element={<ClipPage />} />
        <Route path="/major/pickems" element={<ValvePickemsPage />} />

        <Route path="/fantasy">
          <Route index element={<FantasyPage />} />
          <Route path="join/:joinCode" element={<FantasyJoinPage />} />
          <Route path="leaderboard" element={<LeaderboardLayout />}>
            <Route index element={<FantasyLeaderboardPage />} />
            <Route path=":seasonId" element={<FantasyLeaderboardPage />} />
            <Route path=":seasonId/:eventId" element={<FantasyLeaderboardPage />} />
          </Route>
          <Route path="league/:leagueId" element={<LeaderboardLayout />}>
            <Route index element={<FantasyLeaderboardPage />} />
            <Route path=":seasonId" element={<FantasyLeaderboardPage />} />
            <Route path=":seasonId/:eventId" element={<FantasyLeaderboardPage />} />
          </Route>
          {/* Redirect Legacy Routes */}
          <Route path="community/:seasonId" element={<Redirect to="/fantasy/leaderboard/:seasonId" />}></Route>
          <Route
            path="community/:seasonId/:eventId"
            element={<Redirect to="/fantasy/leaderboard/:seasonId/:eventId" />}
          ></Route>
          <Route
            path="private/:seasonId/:eventId/:leagueId"
            element={<Redirect to="/fantasy/league/:leagueId/:seasonId/:eventId" />}
          ></Route>
        </Route>
        <Route path="/higher-lower" element={<HigherOrLower />} />
        <Route path="/higher-lower/classic" element={<HigherOrLowerClassic />} />
        <Route path="/higher-lower/daily" element={<HigherOrLowerDaily />} />

        <Route path="/player/:playerId/:nickname" element={<PlayerProfilePage />} />
        <Route path="/player/:playerId" element={<PlayerRedirect />} />

        <Route path="/:circuitSlug/:tournamentSlug/match/:shortSeriesId" element={<MatchPage />} />
        <Route path="/:circuitSlug/:tournamentSlug/match/:shortSeriesId/:teams" element={<MatchPage />} />

        <Route path="/rl">
          <Route path="" element={<HomePage />} />
          <Route path="leaderboard" element={<RocketLeagueLeaderboardPage />} />
          <Route path="leaderboard/:subCategory/:category" element={<RocketLeagueLeaderboardPage />} />
          <Route path="tournaments/:event/:region?" element={<RocketLeagueEventPage />} />
        </Route>

        <Route path="/r6/gottem" element={<RainbowSixPage />} />

        <Route path="/cs">
          <Route path="" element={<HomePage />} />
        </Route>
        <Route path="/dota">
          <Route path="" element={<HomePage />} />
          <Route path="tournaments/:tournamentId" element={<DotaTournamentPage />} />
        </Route>
        {/* User management */}
        <Route
          path="/profile/*"
          element={
            <ProtectedRoute>
              <ProfilePage />
            </ProtectedRoute>
          }
        >
          <Route path="" element={<ProfileView />} />
          <Route path="redeem" element={<ProfileRedeem />} />
          <Route path="rewards" element={<ProfileRewards />} />
        </Route>
        <Route path="/sign-up" element={<SignUpPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />

        <Route path="/confirmation-error" element={<VerifyEmailErrorPage />} />

        {/* Content Hub */}
        <Route path="/watch/:series/:episode" element={<EpisodePage />} />
        <Route path="/watch/*" element={<ContentPage />} />

        <Route path="/counter-strikle" element={<CounterStriklePage />} />
        <Route path="/cs-guessr" element={<CSGuessrPage />} />
        <Route path="/cs-quiz" element={<CSQuizPage />} />
        <Route
          path="/cs/compete"
          element={
            <ProtectedRoute requiredFlag="frontend_cs_compete">
              <Outlet />
            </ProtectedRoute>
          }
        >
          <Route index element={<Redirect to="tournaments" />} />
          <Route path="create-team" element={<CSCompeteCreateTeamPage />} />
          <Route path="join-team" element={<CSCompeteJoinTeamPage />} />
          <Route path="tournaments" element={<CSCompeteTournaments />} />
          <Route path="tournaments/:tournamentId" element={<CSCompeteTournament />} />
        </Route>
        <Route path="/cs-guessr-blast" element={<CSGuessrLocalPage />} />
        {/* Allows us to test that the remote module is loading OK */}
        <Route path="/module-federation-test" element={<ModuleFederationTestPage />} />
        {/* Routes can be defined in the CMS, handled by ContentPage  */}
        <Route path="*" element={<ContentPage />} />
      </Routes>
    </>
  );
}
