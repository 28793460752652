import { useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { VideoPlayerModule } from "../../remoteComponents/VideoPlayerModule";
import { useGetVideoClip } from "../../api/data/videoClips/getVideoClip/useGetVideoClip";

export const ClipEmbedPage = () => {
  const { id: eventId } = useParams();
  const { data: eventData } = useGetVideoClip(eventId);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (window.top === window) {
      navigate(`/clips/${eventId}`, { replace: true });
    }
  }, [eventId, navigate]);

  return <VideoPlayerModule controls autoPlay muted src={eventData?.src} />;
};
