/**
 * Groups an array of objects by a specified key, assuming each key has a single corresponding item.
 * @param items The array of items to group.
 * @param key The key to group the items by.
 * @returns An object where each key corresponds to a single item with that key.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const groupBySingle = <T extends Record<string, any>>(items: T[], key: keyof T): Record<string, T> =>
  items.reduce(
    (result: Record<string, T>, item: T) => ({
      ...result,
      [item[key]]: item,
    }),
    {},
  );
