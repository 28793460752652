import { client } from "../../../apiClients/sanity";
import { CurrentSeasonSchema } from "../shared/types/fantasy";

export async function getFantasySeasons() {
  const sanityQuery = `
    *[_type == "fantasySeason"] | order(startTime desc) {
      ...,
      "events": *[_type=='fantasyEvent' && references(^._id)] | order(endTime asc) {
        ...,
        season->{
          ...,
        },
      },
    }`;

  const data = await client.fetch(sanityQuery).catch((error) => {
    throw new Error("failed to fetch CMS data", { cause: error });
  });

  if (!data) {
    throw new Error("No data returned from CMS");
  }
  return CurrentSeasonSchema.array()
    .parse(data)
    .filter((season) => season.events?.length);
}
