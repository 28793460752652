import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { RandomInfoModal } from "../../../../RandomInfoModal";

const MAX_CLICKS = 8;

function NavbarLogoItem({
  children,
  className,
  onClick,
}: {
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
}) {
  const [clickCount, setClickCount] = useState(0);

  const onLogoClicked = useCallback(() => {
    setClickCount((clickCount) => (clickCount >= MAX_CLICKS ? 0 : clickCount + 1));
  }, []);

  return (
    <div>
      <Link to="/" className={className} title="Go to homepage" onClick={onClick ? onClick : onLogoClicked}>
        {children}
      </Link>
      {clickCount >= MAX_CLICKS && <RandomInfoModal onClose={() => setClickCount(0)} />}
    </div>
  );
}

export { NavbarLogoItem };
