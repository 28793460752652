import { Typography } from "@blastorg/portal-pattern-library";
import { ResponsiveImage } from "../../../../../../shared/components/ResponsiveImage";
import { FantasyEvent } from "../../../../../../api/data/sanity/shared/types/fantasy";

interface FantasyHeaderProps {
  fantasyData: FantasyEvent;
}

export function Sponsor({ fantasyData }: FantasyHeaderProps) {
  if (!fantasyData.sponsorBanner || !fantasyData.sponsorBanner?.image) return null;

  return (
    <>
      <div className="flex place-content-center place-items-center pt-6">
        <Typography variant="mobileH4" color="white" component="h2" className="italic ">
          {fantasyData.sponsorBanner.title}
        </Typography>
        <div className=" ml-4 rounded bg-canvas-90 p-2 shadow-sm">
          <ResponsiveImage
            image={fantasyData.sponsorBanner.image}
            width={fantasyData.sponsorBanner.width || 100}
            fit="crop"
          />
        </div>
      </div>
    </>
  );
}
