import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { IconButton } from "../../../../../../shared/components/Icon";

interface JoinLeagueModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  children: React.ReactNode;
}

export function FantasyModal({ open, setOpen, children }: JoinLeagueModalProps) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10 " onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="duration-300 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-200 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-canvas-80/75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center  text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="duration-300 ease-out"
              enterFrom="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
              enterTo="translate-y-0 opacity-100 sm:scale-100"
              leave="duration-200 ease-in"
              leaveFrom="translate-y-0 opacity-100 sm:scale-100"
              leaveTo="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative overflow-hidden rounded bg-canvas-95 px-[4.5rem] pb-10 pt-11 text-left shadow-xl transition-all sm:w-full sm:max-w-sm lg:w-[30rem] lg:max-w-none">
                <div className="absolute right-4 top-4 w-fit">
                  <IconButton
                    icon={"crossCircleFilledWhite"}
                    onClick={() => setOpen(false)}
                    label="Toggle navigation"
                  />
                </div>

                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
