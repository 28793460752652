interface DetailProps {
  detailName: string;
  detailValue: string;
}

const Detail = ({ detailName, detailValue }: DetailProps) => {
  return (
    <div className="mb-7 flex flex-col gap-2">
      <span className="text-neutral-50 font-style-b2-body-copy">{detailName}</span>
      <span className="font-style-b2-body-copy">{detailValue}</span>
    </div>
  );
};

export { Detail };
