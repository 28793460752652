import React from "react";
import classNames from "classnames";
import { PointsCollection } from "../../../../../api/PointsApiClient/types/pointsCollections";

type Item = {
  id: string;
  name: string;
  rarity: string;
  imageSrc: string;
};

type AvatarGridProps = {
  collection: PointsCollection | null;
  overviewList: Item[] | null;
};

const AvatarGrid: React.FC<AvatarGridProps> = ({ collection, overviewList }) => {
  if (!collection || !overviewList) {
    return null;
  }

  return (
    <>
      <span className="h-full pt-2 text-center font-style-label-4">
        You will win <span className="text-purple">one</span> of these {collection.type}
      </span>
      <div className="grid grid-cols-3 gap-4 text-neutral-20">
        {overviewList.map((item) => (
          <div key={item.id} className="flex flex-col items-center gap-y-2">
            <div
              className={classNames("rounded-small bg-canvas-90", {
                "bg-gradient-to-b from-[#ADB2FF80] via-[#DDB3FF80] to-transparent": item.rarity === "diamond",
                "bg-gradient-to-b from-yellow/50 to-canvas-90": item.rarity === "gold",
              })}
            >
              <img className="aspect-square size-full p-2" src={item.imageSrc} />

              <div
                className={classNames("-mt-1 h-1 rounded-b-small", {
                  "bg-neutral-50": item.rarity === "common",
                  "bg-gradient-to-r from-[#FFF3B1] via-[#FFDD26] to-[#ECC701]": item.rarity === "gold",
                  "bg-gradient-to-r from-[#FFFFFF] via-[#EBB2FF] to-[#A3B1FF]": item.rarity === "diamond",
                })}
              ></div>
            </div>
            <span className="max-w-full truncate font-style-b3-body-copy">{item.name}</span>
          </div>
        ))}
      </div>
    </>
  );
};

export { AvatarGrid };
