import styled, { css } from "styled-components";
import { borderBottomRadius } from "../../../../../styles/borders";
import { colors } from "../../../../../designSystemLegacy/colors";
import { toRem } from "@blastorg/portal-pattern-library";
import { mobileE2Eyebrow } from "../../../../../designSystemLegacy/typography/Text/styled";
import { NavLink } from "../NavLink";

export const MenuTitleWrapper = styled.div<{ isDropdownParent: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  svg {
    margin-left: ${toRem(4)};
  }

  ${({ isDropdownParent }) =>
    isDropdownParent
      ? css`
          cursor: default;
        `
      : css`
          cursor: pointer;
        `}
`;

export const Container = styled.div<{
  isActive?: boolean;
}>`
  ${mobileE2Eyebrow}
  letter-spacing: 0.22em;

  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 2px solid transparent;
  border-top: 2px solid transparent;
  color: ${colors.blastCanvas90};
  z-index: 2;

  * {
    transition: all 0.25s ease;
  }

  &:hover {
    color: ${colors.white};

    > ${MenuTitleWrapper} > svg > path {
      fill: ${colors.blastYellow};
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      border-bottom: 2px solid ${colors.blastYellow};
    `}
`;

export const DropdownContent = styled.div`
  ${borderBottomRadius};
  background-color: ${colors.blastCanvas};
  position: absolute;
  top: ${toRem(58)};
  left: ${toRem(-20)};
  padding: ${toRem(12)};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`;

export const DropdownItem = styled(NavLink)<{ isActive?: boolean }>`
  width: 100%;
  text-align: start;
  white-space: nowrap;
  padding: ${toRem(8)};
  transition: color 0.25s ease;
  color: ${colors.blastCanvas90};
  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${colors.blastYellow};
    `}
  ${({ isActive }) =>
    !isActive &&
    css`
      :hover {
        color: ${colors.white};
      }
    `}
`;
