import { useMemo } from "react";
import { generateImageUrl } from "../../../../../api/data/assets/helpers/generateImageUrl";
import { useUserProfile } from "../../../../../auth/useUserProfile";
import { useGetEmojis } from "../../../../../api/data/assets/getEmojis/useGetEmojis";

export function EmojiRewards() {
  const user = useUserProfile();

  const { data: emojis, isLoading } = useGetEmojis();

  const emojisToShow = useMemo(() => {
    if (!user?.avatars) return [];

    return emojis?.filter((emoji) => user.emojis?.includes(emoji.id));
  }, [user, emojis]);

  const randomLoading = useMemo(() => {
    // Generate random number between 1 and 5
    return Math.floor(Math.random() * 5) + 1;
  }, []);

  return (
    <div className="flex h-fit w-full flex-col gap-3 rounded-small bg-canvas-95 px-5 py-4">
      <div className="">
        <span className="font-style-label-3">Emojis</span>
      </div>
      <div className="h-px w-full bg-neutral-50/50" />
      <div className="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
        {emojisToShow?.map((emoji) => (
          <div key={emoji.id} className="flex flex-col place-content-center items-center pb-2 text-center">
            <img
              src={generateImageUrl("emojis", emoji.id, { format: "auto", width: "64", height: "64" })}
              className="h-16"
              alt={`emoji ${emoji.name}`}
            />
            <span className="text-neutral-20 font-style-b3-body-copy">{emoji.name}</span>
          </div>
        ))}
        {isLoading && emojisToShow?.length === 0
          ? Array.from({ length: randomLoading })?.map((_, index) => (
              <div
                key={index}
                className="flex cursor-pointer flex-col place-content-center items-center gap-3 rounded pb-2 text-center hover:bg-canvas-80"
              >
                <div className="h-20 w-16 animate-pulse rounded-small bg-neutral-50/20 py-2" />
                <div className="h-4 w-24 animate-pulse rounded-small bg-neutral-50/20" />
              </div>
            ))
          : null}
      </div>
      {!isLoading && emojisToShow?.length === 0 ? (
        <div className=" w-full items-center py-12 text-center">
          <span className="text-neutral-20 font-style-b3-body-copy">No emoji{`'`}s to show</span>
        </div>
      ) : null}
    </div>
  );
}
