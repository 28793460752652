import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { borderRadius } from "../../../../../styles/borders";
import { Checkbox } from "../../../Checkbox";
import { colors } from "../../../../../designSystemLegacy/colors";
import { zIndex } from "../../../../../styles/zIndex";
import { desktopH6 } from "../../../../../designSystemLegacy/typography/Heading/styled";
import {
  desktopE2Eyebrow,
  desktopB3Body,
  mobileE2Eyebrow,
  mobileB3Body,
} from "../../../../../designSystemLegacy/typography/Text/styled";
import { Button, breakpoints } from "@blastorg/portal-pattern-library";
import { modalTransitionTime } from "../../../../../styles/constants";
import { ScrollableContainer } from "../../../ScrollableContainer";
import { Input } from "../Input";

const bodyFontStyle = css`
  ${desktopB3Body};
  font-weight: 300;

  @media (max-width: ${breakpoints.tablet}px) {
    ${mobileB3Body};
    font-weight: 300;
  }
`;

export const BlurredBackground = styled(ScrollableContainer)`
  position: fixed;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  z-index: ${zIndex.blurredBackground};
  background-color: ${colors.white}1A;
  backdrop-filter: blur(2px);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 1rem;

  @media (max-width: ${breakpoints.tablet}px) {
    padding: 0 0 4rem;
  }

  &.fade-enter {
    opacity: 0;
  }

  &.fade-enter-active {
    opacity: 1;
    transition: all ${modalTransitionTime}ms ease;
  }

  &.fade-exit {
    opacity: 1;
  }

  &.fade-exit-active {
    opacity: 0;
    transition: all ${modalTransitionTime}ms ease;
  }

  &.fade-exit-done {
    opacity: 0;
  }
`;

export const Modal = styled.div`
  position: relative;
  margin: auto;
  background-color: ${colors.blastCardCanvas};
  padding: 3rem 1rem 3rem;
  width: 100%;
  max-width: 600px;
  height: fit-content;
  ${borderRadius};

  @media (max-width: ${breakpoints.tablet}px) {
    border-radius: 0;
  }
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 440px;
  margin: auto;
`;

export const Header = styled.span`
  ${desktopH6};
  text-align: center;
  margin: 1rem 0;
`;

const anchorStyles = css`
  user-select: none;
  ${mobileE2Eyebrow};
  font-weight: 700;
  color: ${colors.blastYellow};
  margin-top: 1rem;
  cursor: pointer;
  width: fit-content;
  margin: 0 0.5rem;
  letter-spacing: 0.1rem;
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: all 0.25s ease;

  &:hover {
    text-decoration-color: ${colors.blastYellow};
  }
`;

export const StyledAnchor = styled.a`
  ${anchorStyles};
`;

export const StyledLink = styled(Link)`
  ${anchorStyles};
`;

export const Description = styled.span`
  ${bodyFontStyle};
  text-align: center;
  margin: 1rem 0;
`;

export const Divider = styled.div`
  user-select: none;
  width: 100%;
  height: 1px;
  background-color: ${colors.white}80;
  margin: 1rem 0;
`;

export const EmailInput = styled(Input)`
  margin-bottom: 0.75rem;
`;

export const Label = styled.label`
  ${desktopE2Eyebrow};
  font-weight: 600;
  text-transform: none;
  margin-bottom: 0.25rem;
`;

export const EmailLabel = styled(Label)`
  margin-top: 1rem;
`;

export const PasswordLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const OptInCheckbox = styled(Checkbox)`
  margin-top: 0.5rem;
`;

export const CheckboxText = styled.span`
  ${bodyFontStyle};
  padding-left: 0.5rem;

  ${StyledAnchor} {
    ${bodyFontStyle};
    text-transform: none;
    margin: 0;
  }
`;

export const SubmitButton = styled(Button)`
  margin-top: 1rem;
`;
export const CancelButton = styled(Button)`
  margin-top: 1rem;
`;

export const CopyText = styled.div`
  ${bodyFontStyle};
  margin: 0.5rem 0;
`;

export const Bullet = styled.span`
  margin: 0 0.5rem;
  color: ${colors.white};
`;

export const FootNote = styled.div`
  ${desktopB3Body};
  color: ${colors.white}80;
  text-align: center;
  margin-top: 1rem;

  @media (max-width: ${breakpoints.tablet}px) {
    ${mobileB3Body};
  }
`;
