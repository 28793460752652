import { z } from "zod";
import { TournamentSchema } from "./Tournament";
import { TeamSchema } from "./Team";
import { MapSchema } from "./Map";

export const NextSeriesSchema = z.object({
  tournamentUUID: z.string(),
  seriesUUID: z.string(),
  bracketPosition: z.enum(["POSITION_A", "POSITION_B"]),
});

export const SeriesSchema = z.object({
  uuid: z.string(),
  name: z.string(),
  timeOfSeries: z.coerce.date(),
  type: z.string(),
  index: z.number(),
  tournament: TournamentSchema,
  teamA: TeamSchema.nullable()
    .optional()
    .transform((team) => team ?? undefined),
  teamB: TeamSchema.nullable()
    .optional()
    .transform((team) => team ?? undefined),
  teamAScore: z.number(),
  teamBScore: z.number(),
  maps: z.array(MapSchema).nullable().optional(),
});

export const SeriesByTournamentSchema = SeriesSchema.extend({
  winnerGoesTo: NextSeriesSchema.nullable().optional(),
  loserGoesTo: NextSeriesSchema.nullable().optional(),
});

export type SeriesByTournament = z.infer<typeof SeriesByTournamentSchema>;
export type Series = z.infer<typeof SeriesSchema>;
