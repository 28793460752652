import { useQuery } from "@tanstack/react-query";
import { getUserPicksDetailed } from ".";
import { queryKeys } from "../shared/queryKeys";

export const useGetUserPicksDetailed = ({
  calculationSchemaId,
  seasonId,
  eventId,
  enabled,
}: {
  calculationSchemaId?: string;
  seasonId?: string;
  eventId?: string;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: queryKeys.myPicksDetailed({
      seasonId,
      eventId,
    }),
    queryFn:
      seasonId && eventId && calculationSchemaId
        ? () => getUserPicksDetailed({ seasonId, eventId, calculationSchemaId })
        : undefined,
    enabled,
  });
};
