import { Transition } from "@headlessui/react";
import { Fragment } from "react";

const getClassNamesForRarity = (rarity: string) => {
  switch (rarity) {
    case "gold":
      return "bg-gradient-to-l from-[#FFD700] to-[#FFF8D3] bg-clip-text text-transparent";
    case "diamond":
      return "bg-gradient-to-l from-[#FFFFFF] via-[#A5B4FF] to-[#E5B3FF] bg-clip-text text-transparent";
    default:
      return "text-neutral-20";
  }
};

type RarityChipProps = {
  winner: {
    rarity: string;
  };
  animationCompleted: boolean;
};
const RarityChip = ({ winner, animationCompleted }: RarityChipProps) => {
  return (
    winner &&
    (animationCompleted ? (
      <Transition
        appear
        show={Boolean(animationCompleted && winner)}
        as={Fragment}
        enter="transition-all delay-300 duration-500"
        enterFrom="scale-125 opacity-0"
        enterTo="scale-100 opacity-100"
      >
        <span
          className={`rounded-full bg-canvas-80 px-2 py-1 font-style-label-3 ${getClassNamesForRarity(winner.rarity)}`}
          style={{ height: "someHeight", width: "someWidth" }}
        >
          <span className={getClassNamesForRarity(winner.rarity)}>{winner.rarity}</span>
        </span>
      </Transition>
    ) : (
      <span className="bg-transparent px-2 py-1 font-style-label-3">&nbsp;</span>
    ))
  );
};

export { RarityChip };
