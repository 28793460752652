import { urlFor } from "../../../api/apiClients/sanity";
import { Image } from "../../../api/data/sanity/types/Image";

// https://www.sanity.io/docs/image-urls#fit-45b29dc6f09f
type FitMode = "clip" | "crop" | "fill" | "fillmax" | "max" | "scale" | "min";
type CropMode = "top" | "bottom" | "left" | "right" | "center" | "focalpoint" | "entropy";

function buildUrl({
  image,
  width,
  height,
  fit = "clip",
  crop,
}: {
  image: Image;
  width: number;
  height?: number;
  fit?: FitMode;
  crop?: CropMode;
}) {
  let builder = urlFor(image).width(Math.round(width));

  if (height) {
    builder = builder.height(Math.round(height));
  }
  if (crop) {
    builder = builder.crop(crop);
  }

  return builder.fit(fit).auto("format").url();
}

interface HiResImageProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  image?: Image;
  width: number;
  height?: number;
  fit?: FitMode;
  crop?: CropMode;
}

function HiResImage({ image, width, height, fit, crop, alt, ...rest }: HiResImageProps) {
  if (!image) return null;

  const src = buildUrl({
    image,
    width,
    height,
    fit,
  });

  const srcSet = [
    buildUrl({ image, width, height, fit, crop }),
    `${buildUrl({
      image,
      width: width * 2,
      height: height ? height * 2 : height,
      fit,
      crop,
    })} 2x`,
  ].join(", ");

  return <img srcSet={srcSet} src={src} alt={alt || image?.altText || ""} {...rest} />;
}

export { HiResImage };
