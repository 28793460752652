import { useEffect, useState } from "react";
import { useCSGuessrGame } from "../../../../../../../../hooks/useCSGuessrGame";
import { Transition } from "@headlessui/react";
import { Button, breakpoints } from "@blastorg/portal-pattern-library";
import { STATE_TRANSITION_DURATION } from "../../../../constants";
import { useMediaQuery } from "react-responsive";
import classnames from "classnames";

const GuessingState = ({ show }: { show: boolean }) => {
  const {
    currentRound,
    selectedMap,
    setSelectedPosition,
    selectMap,
    selectedPosition,
    submitUserAnswer,
    isSubmittingAnswer,
  } = useCSGuessrGame();

  const [mapTitle, setMapTitle] = useState<string>("");

  useEffect(() => {
    if (!selectedMap) return;

    setMapTitle(selectedMap.title);
  }, [selectedMap]);

  const isTablet = useMediaQuery({ maxWidth: breakpoints.tablet });

  if (!currentRound) return null;

  return (
    <Transition
      show={show}
      enter="transition-all"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-all"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      style={{
        transitionDuration: `${STATE_TRANSITION_DURATION}ms`,
      }}
      className="absolute left-0 top-0 w-full"
    >
      {isTablet && <span className="block font-style-label-3">place your location on the map</span>}
      <button
        className={classnames(
          "my-4 flex w-full justify-between rounded-small border-none p-5 outline-none max-md:my-2 max-md:p-3",
          "cursor-pointer bg-canvas-95/80 hover:bg-canvas-95",
        )}
        onClick={() => {
          setSelectedPosition(undefined);
          selectMap(undefined);
        }}
      >
        <span className="block text-neutral-20 font-style-label-3">{mapTitle}</span>
        <span className="block text-yellow font-style-label-3">Change</span>
      </button>
      {!isTablet && (
        <Button
          variant="default"
          onClick={() => submitUserAnswer()}
          isDisabled={!selectedPosition || isSubmittingAnswer}
          className="p-2"
          isLoading={isSubmittingAnswer}
        >
          Confirm Guess
        </Button>
      )}
    </Transition>
  );
};

export { GuessingState };
