import { useMutation } from "@tanstack/react-query";
import { CSGuessrUserAnswer } from "../shared/types";
import { postUserAnswer } from ".";
import { queryKeys } from "../shared/queryKeys";

export const usePostUserAnswer = ({ onSuccess }: { onSuccess: (data: CSGuessrUserAnswer) => void }) => {
  return useMutation({
    mutationKey: queryKeys.userAnswers(),
    mutationFn: postUserAnswer,
    onSuccess,
  });
};
