import { useCSGuessrGame } from "../../../../hooks/useCSGuessrGame";
import { useMemo } from "react";
import { RoundsOverview } from "./components/RoundsOverview";
import { GameControlsArea } from "./components/GameControlsArea";
import { breakpoints } from "@blastorg/portal-pattern-library";
import { useMediaQuery } from "react-responsive";
import { MobileView } from "./components/MobileView";
import { MapControlsArea } from "./components/MapControlsArea";

const GuessLocationView = () => {
  const { selectedMap, currentRound } = useCSGuessrGame();

  const isCorrectMapSelected = useMemo(() => {
    if (!selectedMap || !currentRound?.userAnswer?.location) return false;

    return selectedMap.id === currentRound.userAnswer.location.mapId;
  }, [selectedMap, currentRound?.userAnswer]);

  const isTablet = useMediaQuery({ maxWidth: breakpoints.tablet });

  if (!currentRound) return null;

  if (isTablet) return <MobileView isCorrectMapSelected={isCorrectMapSelected} />;

  return (
    <>
      <div className="flex items-start justify-between gap-4">
        <div className="flex min-h-[42rem] flex-[3] flex-col justify-between">
          <MapControlsArea isCorrectMapSelected={isCorrectMapSelected} />
          <RoundsOverview />
        </div>
        <div className="flex min-h-[42rem] flex-[2] flex-col">
          <img
            src={currentRound.location.image}
            alt={"location"}
            className="block h-fit w-full grow-0 rounded-small object-contain"
          />
          <GameControlsArea className="flex-1" />
        </div>
      </div>
    </>
  );
};

export { GuessLocationView };
