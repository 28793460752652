import { Button } from "@blastorg/portal-pattern-library";
import { Icon } from "../../../../../../../../shared/components/Icon";
import { useUserWallet } from "../../../../../../../../shared/hooks/useUserWallet";
import { convertToCurrencyFormat } from "../../../../../../../../shared/helpers/convertToCurrencyFormat";
import { PointsExplainerModal } from "../../../PointsExplainerModal";
import { useState } from "react";

interface ProfileAvatarProps {
  username: string;
  avatarImageUrl: string;
  onClickChangeAvatar: () => void;
}

const ProfileAvatar = ({ username, avatarImageUrl, onClickChangeAvatar }: ProfileAvatarProps) => {
  const { data: wallet } = useUserWallet();
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <PointsExplainerModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <div className="absolute bottom-0 z-[2] flex size-full flex-col items-center justify-center gap-5 md:flex-row">
        <div
          className="relative flex size-44 flex-col place-content-center items-center justify-center rounded-full bg-neutral-20/10 align-middle hover:cursor-pointer hover:bg-neutral-50/20 md:size-64"
          onClick={onClickChangeAvatar}
        >
          <img className="w-44 md:w-56" src={avatarImageUrl} alt="" />
          <Button className="absolute bottom-4 scale-75 md:bottom-7 md:block" variant="tertiary">
            Change Avatar
          </Button>
        </div>
        <div className="flex flex-col text-center md:text-left">
          <span className="font-style-mobile-h2 md:font-style-desktop-h2" color="white">
            {username}
          </span>

          <div className="flex flex-col items-center gap-4 pt-4 md:flex-row md:gap-5">
            <div className="flex w-fit place-content-center items-center gap-2 rounded-full bg-neutral-20/20 p-2">
              <Icon icon="blastCoin" className="text-yellow" />
              <span className="font-style-label-2">{wallet && convertToCurrencyFormat(wallet.amount)}</span>
            </div>

            <Button className="" variant="tertiary" onClick={() => setIsModalOpen(true)}>
              BLAST.TV Points? Tell me more
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export { ProfileAvatar };
