import { Series } from "../../../../api/data/tournament/shared/types/Series";
import { getMatchResultTeams } from "./getMatchWinner";
import { TeamMap } from "./types";

export function getTeamsData(data: Series[]) {
  const teams: TeamMap = {};

  // For each team, get the wins, losses, and undecided matches
  for (const series of data) {
    const { teamA, teamB } = series;

    const seriesTeams = [teamA, teamB];

    for (const team of seriesTeams) {
      if (!team) {
        continue;
      }

      const teamUUID = team.uuid;
      const teamDetails = teams[teamUUID] ?? {
        team,
        won: [],
        lost: [],
        undecided: [],
      };

      const winnerTeam = getMatchResultTeams(series);

      if (winnerTeam && winnerTeam.winner?.uuid === teamUUID) {
        teamDetails.won.push(series);
      } else if (winnerTeam && winnerTeam.loser?.uuid === teamUUID) {
        teamDetails.lost.push(series);
      } else {
        teamDetails.undecided.push(series);
      }

      teams[teamUUID] = teamDetails;
    }
  }

  // For each team, get the next match based on the undecided matches
  for (const teamUUID in teams) {
    const teamDetails = teams[teamUUID];
    const { undecided } = teamDetails;

    if (undecided.length === 0) {
      continue;
    }

    const now = new Date();

    undecided.sort((a, b) => {
      const aDate = new Date(a.timeOfSeries);
      const bDate = new Date(b.timeOfSeries);

      // Sort by date closest to after now
      return aDate.getTime() - now.getTime() - (bDate.getTime() - now.getTime());
    });

    const nextMatch = undecided[0];
    teamDetails.nextMatch = {
      series: nextMatch,
      opponent: nextMatch.teamA?.uuid === teamUUID ? nextMatch.teamB : nextMatch.teamA,
    };
  }

  return teams;
}
