import classNames from "classnames";
import { AdContainer } from "../AdContainer";

/**
  This component is used to display sticky side rail ads on the left and right side of the page slot.
*/
export const StickySideRailAds = ({ className }: { className?: string }) => {
  return (
    <div className={classNames("sticky top-0 h-0 w-full max-xl:hidden", className)}>
      <div className="relative mx-auto h-0 w-full max-w-8xl">
        <AdContainer type="skyscraper" className="absolute left-0 top-0 translate-x-[-110%]" />
        <AdContainer type="skyscraper" className="absolute right-0 top-0 translate-x-[110%]" />
      </div>
    </div>
  );
};
