import classNames from "classnames";

export function InputField({
  className,
  ...props
}: React.InputHTMLAttributes<HTMLInputElement> & { className?: string }) {
  return (
    <input
      // eslint-disable-next-line tailwindcss/migration-from-tailwind-2, tailwindcss/no-custom-classname
      className={classNames(
        "ring-gray-300 relative flex w-full justify-between",
        "rounded-small border border-neutral-50 bg-transparent",
        "px-3 py-2 text-white placeholder-neutral-20 outline-none",
        "ring-0 focus:border focus:border-purple",
        className,
      )}
      {...props}
    >
      {props.children}
    </input>
  );
}
