import { useCSGuessrGame } from "../../../../hooks/useCSGuessrGame";
import { ResultCardsContainer } from "./ResultCardsContainer";
import { useMediaQuery } from "react-responsive";
import { Button, breakpoints } from "@blastorg/portal-pattern-library";
import { Transition } from "@headlessui/react";
import { ShareButton } from "./components/ShareButton";
import { useNavigate } from "react-router-dom";

const GameEndedView = () => {
  const { dailyScore, rounds } = useCSGuessrGame();

  const isTablet = useMediaQuery({ maxWidth: breakpoints.tablet });
  const navigate = useNavigate();

  return (
    <>
      {!isTablet && (
        <Transition
          appear
          show
          enter="transition-all duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          style={{
            transitionDelay: `${(rounds.length + 2) * 250}ms`,
          }}
          className="my-4"
        >
          <span className="block text-center text-neutral-20 font-style-label-3">TOTAL SCORE</span>
          <span className="block text-center text-neutral-0 font-style-label-1">{dailyScore} / 500</span>
        </Transition>
      )}
      <ResultCardsContainer />
      <Transition
        appear
        show
        enter="transition-all duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        style={{
          transitionDelay: `${(rounds.length + 2) * 250}ms`,
        }}
        className="my-4"
      >
        <ShareButton className="mx-auto mt-10" />
        <Button variant="secondary" className="mx-auto mt-4" onClick={() => navigate("/counter-strikle")}>
          Play counter-strikle
        </Button>
      </Transition>
    </>
  );
};

export { GameEndedView };
