import { z } from "zod";

export const MapSchema = z.object({
  id: z.string(),
  name: z.string(),
  scheduledAt: z.string(),
  startedAt: z.string().nullable(),
  endedAt: z.string().nullable().optional(),
  teamAScore: z.number(),
  teamBScore: z.number(),
});
