import { YoutubeVideoModule as YoutubeVideoModuleType } from "../../../../api/data/sanity/types/YoutubeVideoModule";
import { LegacyContentFrame } from "../../LegacyContentFrame";
import { StyledIframe, ResponsiveFrame } from "./styled";

interface YoutubeVideoProps {
  content: YoutubeVideoModuleType;
}

const generateYoutubeSrc = (content: YoutubeVideoModuleType) => {
  const { videoId, autoplay, loop } = content;
  const params = new URLSearchParams();
  params.append("autoplay", autoplay ? "1" : "0");
  params.append("loop", loop ? "1" : "0");
  params.append("playlist", videoId);
  params.append("mute", "1");
  return `https://www.youtube.com/embed/${videoId}?${params.toString()}`;
};

function YoutubeVideoModule({ content }: YoutubeVideoProps) {
  return (
    <LegacyContentFrame>
      <ResponsiveFrame>
        <StyledIframe
          src={generateYoutubeSrc(content)}
          title="YouTube video player"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        />
      </ResponsiveFrame>
    </LegacyContentFrame>
  );
}
// eslint-disable-next-line import/no-default-export
export default YoutubeVideoModule;
export { YoutubeVideoModule };
