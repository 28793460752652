import { useMemo, useState } from "react";
import { useGetProducts } from "../../../../api/PointsApiClient/hooks/useGetProducts";
import { LoadingRewards } from "./components/Loading";
import { generateImageUrl } from "../../../../api/data/assets/helpers/generateImageUrl";
import { BLASTLogo } from "../../../../shared/components/Footer/assets/BLASTLogo";
import { ProductPurchaseModal } from "./components/ProductPurchaseModal";
import { PointsProduct } from "../../../../api/PointsApiClient/schemas";
import { convertToCurrencyFormat } from "../../../../shared/helpers/convertToCurrencyFormat";

export function ProfileRedeem() {
  const { data: products, isLoading } = useGetProducts();

  const [selectedProduct, setSelectedProduct] = useState<PointsProduct | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const filteredProducts = useMemo(() => {
    if (!products) return [];
    return products.products.filter((product) => !product.isHidden && !product.isRedeemableFromChat);
  }, [products]);

  if (isLoading) {
    return <LoadingRewards />;
  }

  return (
    <div className="mb-12 grid grid-cols-1 flex-col gap-5 md:grid-cols-2">
      <ProductPurchaseModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        product={selectedProduct}
      />
      {filteredProducts.map((product, index) => (
        <div
          key={index}
          className="group flex h-24 w-full rounded-small bg-canvas-95 hover:cursor-pointer hover:bg-canvas-90"
          onClick={() => {
            setSelectedProduct(product);
            setIsModalOpen(true);
          }}
        >
          <div className="flex w-28 place-content-center items-center rounded-l-small bg-canvas-90 px-4  ">
            <img
              alt={product.name}
              className="size-16 object-contain"
              src={generateImageUrl("products", product.id, {
                height: "200",
                format: "auto",
              })}
            />
          </div>
          <div className="flex size-full justify-between gap-2 overflow-hidden">
            <div className="flex h-full max-w-[60%] flex-col place-content-center gap-2 pl-2">
              <span className="truncate font-style-label-2">{product.name}</span>
              <span className=" truncate text-neutral-50 font-style-b2-body-copy ">{product.description}</span>
            </div>
            <div className="flex h-full place-content-center items-center pr-2 ">
              <button className=" rounded bg-canvas-90 [all:unset] ">
                <div className="flex place-content-center items-center gap-3   px-2 py-1">
                  <div className="flex size-4 place-content-center items-center rounded-full bg-yellow">
                    <BLASTLogo className="h-3 pb-0.5 text-canvas-100" />
                  </div>
                  <span className="font-style-label-2">{convertToCurrencyFormat(product.price)}</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
