import classnames from "classnames";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";

const CountDownTimer = ({ className }: { className?: string }) => {
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);

  useEffect(() => {
    // timer ends at midnight UTC
    const endTime = DateTime.utc().endOf("day");
    const interval = setInterval(() => {
      const now = DateTime.utc();

      if (now < endTime) {
        const diff = endTime.diff(now, ["hours", "minutes", "seconds"]);
        setHours(diff.hours);
        setMinutes(diff.minutes);
        setSeconds(diff.seconds);
      } else {
        setHours(0);
        setMinutes(0);
        setSeconds(0);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <h3 className={classnames(className, "block font-style-desktop-h3 max-md:font-style-mobile-h4")}>
      <span className="inline-block w-8 text-right max-md:w-6">{hours.toFixed(0).padStart(2, "0")}</span>
      <span>H</span>
      <span className="mx-2 max-md:mx-1">•</span>
      <span className="inline-block w-8 text-right max-md:w-6">{minutes.toFixed(0).padStart(2, "0")}</span>
      <span>M</span>
      <span className="mx-2 max-md:mx-1">•</span>
      <span className="inline-block w-8 text-right max-md:w-6">{seconds.toFixed(0).padStart(2, "0")}</span>
      <span className="mr-4 max-md:mr-2">S</span>
      <span>until new game</span>
    </h3>
  );
};

export { CountDownTimer };
