import { Button, Modal } from "@blastorg/portal-pattern-library";
import scoreSvg from "./assets/score.svg";
import pinSvg from "./assets/pin.svg";
import calendarSvg from "./assets/calendar.svg";
import mapSvg from "./assets/map.svg";
import shareSvg from "./assets/share.svg";

const HowToPlayModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} className="max-w-lg rounded-small bg-canvas-95 p-10 sm:p-10">
      <span className="font-style-desktop-h3">How To Play</span>
      <div className="my-8 h-[1px] w-full bg-neutral-0/20" />
      <div className="flex items-start text-left">
        <img src={scoreSvg} alt="score" className="my-[0.125rem] mr-4 size-4" />
        <span className="block font-style-b2-body-copy">
          Your score is collected over <b>5</b> rounds
        </span>
      </div>
      <div className="mt-4 flex items-start">
        <img src={mapSvg} alt="score" className="my-[0.125rem] mr-4 size-4" />
        <span className="block text-left font-style-b2-body-copy">
          First step is guessing which map relates to the image{" "}
          <span className="inline text-neutral-50">
            (You will score <b>0</b> for the whole round if you guess the wrong map)
          </span>
        </span>
      </div>
      <div className="mt-4 flex items-start">
        <img src={pinSvg} alt="score" className="my-[0.125rem] mr-4 size-4" />
        <span className="block text-left font-style-b2-body-copy">
          Select the <b>correct location</b> on the map
        </span>
      </div>
      <div className="mt-4 flex items-start">
        <img src={shareSvg} alt="score" className="my-[0.125rem] mr-4 size-4" />
        <span className="block text-left font-style-b2-body-copy">
          <b>Share</b> and show off your scores
        </span>
      </div>
      <div className="mt-4 flex items-start">
        <img src={calendarSvg} alt="score" className="my-[0.125rem] mr-4 size-4" />
        <span className="block text-left font-style-b2-body-copy">
          Come back <b>tomorrow</b> for a new batch of guesses
        </span>
      </div>
      <Button className="mt-8 w-full" onClick={onClose}>
        OK, Cool!
      </Button>
    </Modal>
  );
};

export { HowToPlayModal };
