import { Button, Link, Modal } from "@blastorg/portal-pattern-library";
import { ProfileIcon } from "./components/ProfileIcon";
import { GraphIcon } from "./components/GraphIcon";
import { CaptainIcon } from "../CaptainIcon";
import { TrophyIcon } from "./components/TrophyIcon";

const HowToPlayModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} className="m-8 bg-canvas-95 p-8 max-md:mx-4 max-md:my-16">
      <div className="pb-4 font-style-desktop-h3">How to play</div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4">
        <CardContainer>
          <ProfileIcon />
          <div className="mt-4 flex flex-col gap-4">
            <div className="text-neutral-0 font-style-b2-body-copy">
              Pick 5 players. You can only have 1 player per category.
            </div>
            <div className="flex flex-col items-center gap-1 text-neutral-50 font-style-label-4">
              <div>Diamond</div>
              <div>Platinum</div>
              <div>Gold</div>
              <div>Silver</div>
              <div>Bronze</div>
            </div>
            <div className="text-neutral-0 font-style-b2-body-copy">
              You can only have 2 players from the same team.
            </div>
          </div>
        </CardContainer>
        <CardContainer>
          <GraphIcon />
          <div className="mt-4 flex h-full flex-col justify-between gap-4">
            <div className="text-neutral-0 font-style-b2-body-copy">
              Every match, your players will earn points based on their performance.
            </div>
            <div className="mx-auto flex w-full max-w-36 flex-col items-center gap-1 text-neutral-50 font-style-label-4">
              <div className="flex w-full justify-between">
                <div>Ranked #1</div>
                <div>100 PTS</div>
              </div>
              <div className="flex w-full justify-between">
                <div>Ranked #2</div>
                <div>90 PTS</div>
              </div>
              <div className="flex w-full justify-between">
                <div>Ranked #3</div>
                <div>80 PTS</div>
              </div>
              <div className="flex w-full justify-between">
                <div>Ranked #10</div>
                <div>10 PTS</div>
              </div>
            </div>
            <div className="text-neutral-0 font-style-b2-body-copy">
              If your players are on the winning team, they get 10 extra points!
            </div>
          </div>
        </CardContainer>
        <CardContainer>
          <CaptainIcon className="fill-yellow" />
          <div className="mt-4 flex h-full flex-col justify-between gap-4">
            <div className="text-neutral-0 font-style-b2-body-copy">
              Your captain will receive a points multiplier for every match.
            </div>
            <div className="mx-auto flex w-full max-w-36 flex-col items-center gap-1 text-neutral-50 font-style-label-4">
              <div className="flex w-full justify-between text-neutral-50">
                <div>Diamond</div>
                <div>x1.2</div>
              </div>
              <div className="flex w-full justify-between">
                <div>Platinum</div>
                <div>x1.4</div>
              </div>
              <div className="flex w-full justify-between">
                <div>Gold</div>
                <div>x1.6</div>
              </div>
              <div className="flex w-full justify-between">
                <div>Silver</div>
                <div>x1.8</div>
              </div>
              <div className="flex w-full justify-between">
                <div>Bronze</div>
                <div>x2.0</div>
              </div>
            </div>
            <div className="text-neutral-0 font-style-b2-body-copy">
              Your captain will be auto-assigned but you can change your captain.
            </div>
          </div>
        </CardContainer>
        <CardContainer>
          <TrophyIcon />
          <div className="mt-4 flex h-full flex-col justify-between gap-4">
            <div className="text-neutral-0 font-style-b2-body-copy">
              Compete in Community Leagues with prizes for the winners.
            </div>
            <div className="mx-auto flex w-full flex-col items-center gap-1 text-neutral-50 font-style-label-4">
              <div>{"All expense paid trip to an event"}</div>
              <div>{"blast.tv bots and emoji's"}</div>
              <div>{"Steam gift cards "}</div>
              <div>{"and much more!"}</div>
            </div>
            <div className="text-neutral-0 font-style-b2-body-copy">
              Challenge your friends by creating your own private leagues
            </div>
          </div>
        </CardContainer>
      </div>
      <Link href="/fantasy/rulebook" target="_blank" variant="tertiary" className="mx-auto my-4">
        More info
      </Link>
      <Button variant="default" className="mx-auto" onClick={onClose}>
        OK, Got it!
      </Button>
    </Modal>
  );
};

const CardContainer = ({ children }: { children: React.ReactNode }) => (
  <div className="flex w-full flex-col items-center rounded-small bg-canvas-90 p-5 text-xs md:w-72">{children}</div>
);

export { HowToPlayModal };
