import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../shared/queryKeys";
import { getUserAnswers } from ".";

export const useGetUserAnswers = ({ enabled }: { enabled?: boolean }) => {
  return useQuery({
    queryKey: queryKeys.userAnswers(),
    queryFn: getUserAnswers,
    enabled,
  });
};
