import { Typography } from "@blastorg/portal-pattern-library";

export interface FooterItemProps {
  text: string;
  linkTo?: string;
  isNewPage?: boolean;
}
export function FooterItem({ text, linkTo, isNewPage }: FooterItemProps) {
  if (linkTo) {
    return (
      <div className="">
        <a
          className="line-clamp-1 text-inherit no-underline"
          href={linkTo}
          rel="noreferrer"
          target={isNewPage ? "_blank" : "_self"}
        >
          <Typography variant="label2" color="inherit">
            {text}
          </Typography>
        </a>
      </div>
    );
  }

  return (
    <div className="">
      <Typography variant="label2" color="inherit">
        {text}
      </Typography>
    </div>
  );
}
