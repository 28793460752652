import { z } from "zod";
import { useEffect } from "react";
import { CurrentState, PickedPlayers } from "../types";
import { UseQueryResult } from "@tanstack/react-query";
import { FantasyEvent } from "../../../../../api/data/sanity/shared/types/fantasy";
import { MyPicksSchema } from "../../../../../api/data/fantasy/getUserPicksDetailed";

export const useFantasyStateAutoUpdateEffect = (
  setCurrentState: (state: CurrentState) => void,
  myPicks: UseQueryResult<z.infer<typeof MyPicksSchema> | null>,
  overrideAutoState: boolean,
  pickedPlayers: PickedPlayers,
  isSubmitting: boolean,
  fatansyData?: FantasyEvent,
  userId?: string,
) => {
  useEffect(() => {
    if (isSubmitting) {
      return;
    }

    if (userId) {
      if (overrideAutoState) {
        return;
      }
      if (myPicks.isError || myPicks.isLoading) {
        return;
      }
    }

    const fantasyDataEndTime = fatansyData?.endTime;
    if (!fantasyDataEndTime) {
      return;
    }

    const arePicksLocked = new Date() > new Date(fantasyDataEndTime);
    const hasPicks = !myPicks.data?.picks ? false : myPicks.data?.picks?.length > 0;

    if (arePicksLocked && !hasPicks) {
      setCurrentState("tooLate");
      return;
    }

    if (arePicksLocked && hasPicks) {
      setCurrentState("pickingClosedWithPicks");
      return;
    }

    if (hasPicks) {
      setCurrentState("picksSubmittedCanBeEdited");
      return;
    }

    if (Object.values(pickedPlayers).length === 5) {
      setCurrentState("pickingCaptain");
      return;
    }
    setCurrentState("pickingPlayers");
  }, [
    myPicks.data,
    myPicks.isError,
    myPicks.isLoading,
    myPicks.isSuccess,
    overrideAutoState,
    pickedPlayers,
    setCurrentState,
    isSubmitting,
    fatansyData,
    userId,
  ]);
};
