import { maxWidthMobile } from "../../../styles/constants";
import { urlFor } from "../../../api/apiClients/sanity";
import { Image } from "../../../api/data/sanity/types/Image";

type FitMode = "clip" | "crop" | "fill" | "fillmax" | "max" | "scale" | "min";

function url({ image, width, height, fit }: { image: Image; width: number; height?: number; fit?: FitMode }) {
  let builder = urlFor(image).width(width);

  if (height) {
    builder = builder.height(height);
  }

  if (fit) {
    // https://www.sanity.io/docs/image-urls#fit-45b29dc6f09f
    builder = builder.fit(fit);
  }

  return builder.auto("format").url();
}

function sizes(width: number, mobileWidth?: number) {
  return mobileWidth ? `(max-width: ${maxWidthMobile}) ${mobileWidth}px, ${width}px` : `${width}px`;
}

interface ResponsiveImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  image?: Image;
  width: number;
  height?: number;
  fit?: FitMode;
  mobileWidth?: number;
  alt?: string;
  className?: string;
}

function ResponsiveImage({ alt, image, width, mobileWidth, height, fit, className, ...rest }: ResponsiveImageProps) {
  const altText = alt || image?.altText;
  const widths = [width, width * 2 /*  for higher density devices*/];
  if (mobileWidth) {
    widths.unshift(mobileWidth);
  }

  if (!image) return null;

  return (
    <>
      <img
        className={className}
        sizes={sizes(width, mobileWidth)}
        srcSet={widths.map((width) => `${url({ image, width, height, fit })} ${width}w`).join(", ")}
        src={url({ image, width, height, fit })}
        alt={altText || ""}
        {...rest}
      />
    </>
  );
}

export { ResponsiveImage };
