import { SanityClient } from "@sanity/client";
import { DateTime } from "luxon";
import { captureMessage } from "@sentry/browser";

export const BUDGET_REQUEST_COUNT = 10;
export const BUDGET_PERIOD_MS = 5_000;

export let budgetStartTimestamp = DateTime.utc().toMillis();
export let fetchCount = 0;

/**
 * Wraps the Sanity client fetch function logs when too many requests are made.
 * This is useful to detect when a Sanity client is making too many requests which may cause inflate hosting costs.
 *
 * The budget is reset after the budget period.
 *
 * @param client - the Sanity client
 * @returns a client with a budget
 **/

export const clientWithBudget = (client: SanityClient) => ({
  fetch: async (query: string) => {
    const now = DateTime.utc().toMillis();

    // reset the budget after the budget period
    if (now - budgetStartTimestamp > BUDGET_PERIOD_MS) {
      fetchCount = 0;
      budgetStartTimestamp = now;
    }

    // log if the budget is exceeded
    if (++fetchCount > BUDGET_REQUEST_COUNT) {
      const message = `Too many requests (${fetchCount}) to the Sanity API - allowed ${BUDGET_REQUEST_COUNT} requests in ${BUDGET_PERIOD_MS} milliseconds.`;
      captureMessage(message);
    }

    return client.fetch(query);
  },
  _reset: () => {
    budgetStartTimestamp = DateTime.utc().toMillis();
    fetchCount = 0;
  },
});
