import { Button } from "@blastorg/portal-pattern-library";
import { useUserProfile } from "../../../../../../auth/useUserProfile";
import { useCallback, useEffect, useState } from "react";
import { useGetSteamConnectionLink } from "../../../../../../api/UsersApiClient/hooks/useGetSteamConnectionLink";
import steamSvg from "./assets/steam.svg";
import { RemoveSteamModal } from "./components/RemoveSteamModal";
import checkCircledSvg from "./assets/check_circled.svg";
import linkArrowSvg from "./assets/link_arrow.svg";
import { captureException } from "@sentry/browser";

export const ConnectionsSection = () => {
  const userProfile = useUserProfile();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const { data: steamRedirectData, isLoading: isLoadingSteamLink } = useGetSteamConnectionLink({
    redirectPath: `${location.pathname}${location.search}`,
  });

  const handleClick = useCallback(async () => {
    if (!userProfile || isLoadingSteamLink) return;

    if (!steamRedirectData?.url) {
      captureException("Unable to fetch steam redirect link", {
        tags: {
          component: "ConnectionsSection",
        },
      });

      return;
    }

    if (userProfile.steamId) {
      setIsRemoveModalOpen(true);
      return;
    } else {
      // open in same tab
      window.open(steamRedirectData.url, "_self");
      return;
    }
  }, [userProfile, steamRedirectData, isLoadingSteamLink]);

  useEffect(() => {
    // check query params for "steamConnectionError"
    const urlParams = new URLSearchParams(window.location.search);
    const steamConnectionError = urlParams.get("steamConnectionError");
    if (steamConnectionError) {
      setErrorMessage(
        steamConnectionError === "steam-account-already-linked-to-another-user"
          ? "Steam account is already linked to another user."
          : steamConnectionError === "different-steam-account-already-linked-to-user"
            ? "Please remove the current steam connection first."
            : "Uknown error occurred. Please try again.",
      );
      // remove query param from url
      window.history.replaceState({}, document.title, location.pathname);
    }
  }, []);

  return (
    <>
      <div className="col-span-1 flex flex-col rounded-small bg-canvas-95 p-7 max-md:col-span-2 max-md:p-5">
        <div className="flex items-center justify-between">
          <span className="font-style-label-3">Connections</span>
        </div>
        <div className="my-3 h-0.5 w-full bg-neutral-50/30" />
        <div className="flex items-center justify-between rounded-small bg-canvas-90 p-4">
          <div className="flex flex-1 flex-wrap items-center gap-4 max-lg:gap-2">
            <div className="flex items-center gap-4 max-lg:gap-2">
              <img src={steamSvg} alt="Steam" />
              <span className="font-style-label-3">Steam</span>
              {userProfile?.steamId && <img src={checkCircledSvg} />}
            </div>
            {userProfile?.steamUsername && (
              <span className="rounded-small bg-canvas-85 p-2 font-style-b3-body-copy">
                {userProfile.steamUsername}
              </span>
            )}
            {errorMessage && <span className="text-red font-style-b3-body-copy">{errorMessage}</span>}
          </div>
          <Button
            variant={userProfile?.steamId ? "tertiary" : "default"}
            onClick={handleClick}
            className="ml-2 shrink-0 grow-0"
          >
            {userProfile?.steamId ? "Disconnect" : "Connect"}
            {!userProfile?.steamId && <img src={linkArrowSvg} width={8} className="ml-3 inline-block" />}
          </Button>
        </div>
      </div>
      <RemoveSteamModal isOpen={isRemoveModalOpen} onClose={() => setIsRemoveModalOpen(false)} />
    </>
  );
};
