import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../queryKeys";
import { getTeams } from ".";
import { GameId } from "@providers/GameIdProvider";

export function useFetchTeams(game?: GameId) {
  return useQuery({
    queryKey: [queryKeys.teams(), game],
    queryFn: () => getTeams(game),
    staleTime: 30000,
  });
}
