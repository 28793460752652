import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../queryKeys";
import { useUsersApi } from "../../ApiProvider/hooks";

export const useGetSteamConnectionLink = ({ redirectPath }: { redirectPath: string }) => {
  const { getSteamConnectionLink } = useUsersApi();
  return useQuery({
    queryKey: queryKeys.steamConnection(),
    queryFn: () => getSteamConnectionLink({ redirectPath }),
    enabled: redirectPath.length > 0,
  });
};
