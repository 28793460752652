import { useQuery } from "@tanstack/react-query";
import { getLeaderboard } from ".";
import { queryKeys } from "../shared/queryKeys";

export const useGetLeaderboard = ({
  seasonId,
  eventId,
  leagueId,
}: {
  seasonId?: string;
  eventId?: string;
  leagueId?: string;
}) => {
  return useQuery({
    queryKey: queryKeys.leaderBoard({ eventId, seasonId, leagueId }),
    queryFn: () => {
      return getLeaderboard({ eventId, seasonId, leagueId });
    },
  });
};
