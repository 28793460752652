import { GameId } from "@providers/GameIdProvider";
import { TeamSchema } from "../shared/types/Team";
import { TeamV2Schema, mapTeamV2SchemaToTeam } from "../shared/types/v2/team";
import { blastApi } from "../../../apiClients/blastApi";

export const getTeams = async (game?: GameId) => {
  const { data } = await blastApi.get("v2/teams", { params: { game } });

  const v2Teams = TeamV2Schema.array().parse(data);
  const mapped = v2Teams.map(mapTeamV2SchemaToTeam);
  return TeamSchema.array().parse(mapped);
};
