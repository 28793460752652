import styled from "styled-components";
import { borderRadius } from "../../../styles/borders";
import { colors } from "../../../designSystemLegacy/colors";
import { toRem } from "@blastorg/portal-pattern-library";
import { zIndex } from "../../../styles/zIndex";
import { breakpoints } from "@blastorg/portal-pattern-library";
import { ScrollableContainer } from "../ScrollableContainer";

export const BlurredBackground = styled(ScrollableContainer)`
  position: fixed;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  z-index: ${zIndex.blurredBackground};
  background-color: ${colors.white}1A;
  backdrop-filter: blur(2px);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 1rem;

  @media (max-width: ${breakpoints.tablet}px) {
    padding: 0 0 4rem;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  margin: auto;
  background-color: ${colors.blastCardCanvas};
  ${borderRadius};
  max-width: 600px;
  height: fit-content;
  position: relative;
  padding: ${toRem(32)} ${toRem(32)} ${toRem(32)};
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: ${toRem(30)};
  align-items: center;
`;
