import { ConnectionsSection } from "./components/ConnectionsSection";
import { DetailsTab } from "./components/DetailsTab";
import { FavoriteTeamsSection } from "./components/FavoriteTeamsSection";
import { PreferencesTab } from "./components/PreferencesTab";

export function ProfileView() {
  return (
    <>
      <div className="flex flex-col gap-5">
        <FavoriteTeamsSection />
        <ConnectionsSection />
        <div className="mb-12 grid grid-cols-2 flex-col gap-5">
          <DetailsTab />
          <PreferencesTab />
        </div>
      </div>
    </>
  );
}
