import { z } from "zod";
import { CSGuessrUserAnswer } from "./types";
import { UserAnswerResponseSchema } from "./UserAnswerResponseSchema";

export const mapUserAnswerResponseToCSGuessrUserAnswer = (
  response: z.infer<typeof UserAnswerResponseSchema>,
): CSGuessrUserAnswer => ({
  location: {
    id: response.id,
    image: response.image,
    mapId: response.mapId,
    position: response.position,
  },
  guess: {
    mapId: response.guessMapId,
    position: response.guessPosition,
  },
  score: response.score,
  distance: response.distance,
});
