import { useQuery } from "@tanstack/react-query";
import { getLocationsForToday } from ".";
import { queryKeys } from "../shared/queryKeys";

export const useGetLocations = () => {
  return useQuery({
    queryKey: queryKeys.locations(),
    queryFn: getLocationsForToday,
  });
};
