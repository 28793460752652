import { z } from "zod";
import { SeriesByTournamentSchema } from "./Series";
import { TeamSchema } from "./Team";

export const BracketTeamSchema = TeamSchema.omit({ socialLinks: true, gameId: true }).extend({
  isEliminated: z.boolean(),
});

export const BracketSeriesSchema = SeriesByTournamentSchema.partial({
  tournament: true,
  maps: true,
}).extend({
  isLive: z.boolean(),
  isCompleted: z.boolean(),
  teamA: BracketTeamSchema.nullable(),
  teamB: BracketTeamSchema.nullable(),
  posADescription: z.string().optional(),
  posBDescription: z.string().optional(),
  info: z.any(),
});

export const BracketGroupSchema = z.object({
  id: z.string(),
  format: z.string(),
  date: z.coerce.date(),
  matches: z.array(BracketSeriesSchema),
  numberEliminatedTeams: z.number().nullable().optional(),
  numberChallengerTeams: z.number().nullable().optional(),
  numberLegendsTeams: z.number().nullable().optional(),
  numberChampionsTeams: z.number().nullable().optional(),
  hasPlayoffs: z.boolean().nullable().optional(),
  hasLastChance: z.boolean().nullable().optional(),
});

export const BracketBaseSchema = z.object({
  tournamentUuid: z.string(),
  tournamentName: z.string(),
  parentTournamentFormat: z.string(),
  parentTournamentName: z.string(),
  circuitName: z.string(),
  startDate: z.coerce.date().nullable().optional(),
  endDate: z.coerce.date().nullable().optional(),
  label: z.string(),
  groups: z.array(BracketGroupSchema).nullable().optional(),
  matches: z.array(BracketSeriesSchema).nullable().optional(),
  numberOfTeams: z.number().nullish(),
  index: z.number(),
});

export const BracketSchema = z.discriminatedUnion("format", [
  BracketBaseSchema.extend({ format: z.literal("double-elim-4-gauntlet") }),
  BracketBaseSchema.extend({ format: z.literal("gsl-4-playoff") }),
  BracketBaseSchema.extend({ format: z.literal("double-elim-6") }),
  BracketBaseSchema.extend({ format: z.literal("double-elim-8") }),
  BracketBaseSchema.extend({ format: z.literal("double-elim-4-skewed") }),
  BracketBaseSchema.extend({ format: z.literal("double-elim-8-skewed") }),
  BracketBaseSchema.extend({ format: z.literal("double-elim-8-no-gf") }),
  BracketBaseSchema.extend({ format: z.literal("double-elim-16-skewed") }),
  BracketBaseSchema.extend({ format: z.literal("gauntlet-4") }),
  BracketBaseSchema.extend({ format: z.literal("gauntlet-9") }),
  BracketBaseSchema.extend({ format: z.literal("single-elim-8") }),
  BracketBaseSchema.extend({ format: z.literal("single-elim-6") }),
  BracketBaseSchema.extend({ format: z.literal("single-elim-6-2") }),
  BracketBaseSchema.extend({ format: z.literal("single-elim-16-no-gf") }),
  BracketBaseSchema.extend({ format: z.literal("swiss") }),
  BracketBaseSchema.extend({ format: z.literal("swiss-16") }),
  BracketBaseSchema.extend({ format: z.literal("showmatch") }),
  BracketBaseSchema.extend({ format: z.literal("gauntlet-2-match") }),
  BracketBaseSchema.extend({ format: z.literal("last-chance-6") }),
  BracketBaseSchema.extend({ format: z.literal("europeb") }),
  BracketBaseSchema.extend({ format: z.literal("group-bracket-4") }),
  BracketBaseSchema.extend({ format: z.literal("challengers") }),
  BracketBaseSchema.extend({ format: z.literal("knockout-6") }),
  BracketBaseSchema.extend({
    format: z.literal("round-robin"),
    metadata: z.object({ numberOfTeamsAdvances: z.number() }).nullable(),
  }),
  BracketBaseSchema.extend({ format: z.literal("tie-breaker") }),
]);

export type BracketTeam = z.infer<typeof BracketTeamSchema>;
export type BracketGroup = z.infer<typeof BracketGroupSchema>;
export type Bracket = z.infer<typeof BracketSchema>;
export type BracketSeries = z.infer<typeof BracketSeriesSchema>;
