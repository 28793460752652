import { Button } from "@blastorg/portal-pattern-library";
import { DateTime } from "luxon";
import { useState } from "react";
import { useUserProfile } from "../../../../../../auth/useUserProfile";
import { getCountryNameFromCode } from "../../../../../../shared/helpers/countries";
import { getGenderDisplayName } from "../../../../helpers/getGenderDisplayName";
import { Detail } from "./components/Detail";
import { EditDetailsModal } from "./components/EditDetailsModal";
import { ChangeEmailModal } from "./components/ChangeEmailModal";

const DetailsTab = () => {
  const userProfile = useUserProfile();
  const [openChangeDetailsModal, setOpenChangeDetailsModal] = useState(false);
  const [openChangeEmail, setOpenChangeEmail] = useState(false);

  if (!userProfile) {
    return null;
  }

  return (
    <>
      <ChangeEmailModal closeModal={() => setOpenChangeEmail(false)} isOpen={openChangeEmail} />
      <EditDetailsModal
        closeModal={() => setOpenChangeDetailsModal(false)}
        isOpen={openChangeDetailsModal}
        onEmailChangeOpen={() => {
          setOpenChangeDetailsModal(false);
          setOpenChangeEmail(true);
        }}
      />
      <div className="col-span-2 flex flex-col rounded-small bg-canvas-95 p-7 max-md:p-5 md:col-span-1">
        <div className="flex items-center justify-between">
          <span className="font-style-label-3">About you</span>
          <Button onClick={() => setOpenChangeDetailsModal(true)} variant="tertiary">
            Edit
          </Button>
        </div>
        <div className="my-3 h-0.5 w-full bg-neutral-50/30" />
        <div className="mt-3 grid grid-cols-2">
          <Detail detailName="Username" detailValue={userProfile.username} />
          <Detail detailName="Email address" detailValue={userProfile.email} />
          <Detail
            detailName="Joined on"
            detailValue={DateTime.fromJSDate(userProfile.createdAt).toFormat("dd/MM/yyyy")}
          />
          <Detail detailName="Country" detailValue={getCountryNameFromCode(userProfile.country)} />
          <Detail
            detailName="Birthday"
            detailValue={DateTime.fromISO(userProfile.birthDate.toISOString()).toFormat("dd/MM/yyyy")}
          />
          <Detail detailName="Gender" detailValue={getGenderDisplayName(userProfile.gender)} />
        </div>
      </div>
    </>
  );
};

export { DetailsTab };
