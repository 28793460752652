import styled from "styled-components";
import { colors } from "../../../../../designSystemLegacy/colors";
import { toRem } from "@blastorg/portal-pattern-library";
import { breakpoints } from "@blastorg/portal-pattern-library";

export const Container = styled.div`
  position: relative;
  -webkit-tap-highlight-color: transparent;
`;

export const AvatarContainer = styled.div`
  width: ${toRem(36)};
  height: ${toRem(36)};
  border: 1px solid ${colors.white};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (max-width: ${breakpoints.tablet}px) {
    width: ${toRem(32)};
    height: ${toRem(32)};
  }
`;

export const Avatar = styled.img`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: ${toRem(4)};
`;

export const CtaContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LoginButton = styled.button`
  background-color: transparent;
  color: ${colors.white};
  border: none;
  height: 100%;
  padding: ${toRem(8)} ${toRem(16)};
  cursor: pointer;
`;
