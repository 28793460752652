import uniq from "lodash/uniq";
import classNames from "classnames";
import { useCallback, useContext, useMemo, useState } from "react";
import { Button, Modal } from "@blastorg/portal-pattern-library";
import { MagnifyingGlassIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { GameIcon } from "@shared/components/GameIcon";
import { GameId, GameIdContext } from "@providers/GameIdProvider";
import { notEmpty } from "@shared/helpers/notEmpty";
import { useUserProfile } from "../../../../../../../auth/useUserProfile";
import { ProfileTeams } from "../../../../../../../shared/components/ProfileTeams";
import { useUpdateProfile } from "../../../../../hooks/useUpdateProfile";
import { useFetchTeams } from "../../../../../../../api/data/tournament/getTeams/useFetchTeams";

const FavoriteTeamsModal = ({ onClose, selectedGame }: { onClose: () => void; selectedGame: GameId }) => {
  const userProfile = useUserProfile();
  const [favoriteTeams, setFavoriteTeams] = useState<string[]>(userProfile?.favoriteTeams || []);

  const [selectedGameInternal, setSelectedGameInternal] = useState<GameId>(selectedGame);
  const { data: allTeams } = useFetchTeams();

  const gameContext = useContext(GameIdContext);

  const { mutate: updateUserProfile, isPending } = useUpdateProfile({
    userId: userProfile?.id,
  });

  const [teamSearch, setTeamSearch] = useState<string>("");
  const filteredTeams = useMemo(
    () =>
      allTeams?.filter(
        (team) => team.gameId === selectedGameInternal && team.name.toLowerCase().includes(teamSearch.toLowerCase()),
      ) ?? [],
    [allTeams, teamSearch, selectedGameInternal],
  );

  const updateSelectedTeams = useCallback(
    (teamId: string) => {
      if (favoriteTeams.includes(teamId)) {
        setFavoriteTeams(favoriteTeams.filter((id) => id !== teamId));
      } else {
        setFavoriteTeams([...favoriteTeams, teamId]);
      }
    },
    [favoriteTeams],
  );

  const saveFavoriteTeams = useCallback(() => {
    const preferredGames = uniq([
      gameContext.enabledGames[0], // default to first game if no teams are selected
      ...favoriteTeams.map((teamId) => allTeams?.find((team) => team.uuid === teamId)?.gameId).filter(notEmpty),
    ]);

    updateUserProfile({
      favoriteTeams: favoriteTeams,
      preferredGames,
    });
    onClose();
  }, [allTeams, onClose, favoriteTeams, updateUserProfile, gameContext.enabledGames]);

  return (
    <Modal isOpen onClose={onClose} className="relative bg-canvas-95">
      <div className="absolute right-3 top-2 flex items-center gap-3">
        <Button variant="tertiary" onClick={() => setFavoriteTeams([])} className="hidden md:block">
          Clear all
        </Button>
        <button
          onClick={onClose}
          className="flex w-8 items-center border-none bg-transparent hover:cursor-pointer hover:bg-neutral-50/30"
        >
          <XCircleIcon className="size-6 text-white/80" onClick={onClose} />
        </button>
      </div>
      <div className="">
        <div className="py-5">
          <span className="font-style-desktop-h3">Favorite teams</span>
        </div>
        <div className="w-full">
          <div className="relative flex h-12 w-full rounded-small border border-neutral-50 bg-canvas-100">
            <MagnifyingGlassIcon className="absolute right-3 top-3 size-5 text-neutral-50" />
            <input
              className="size-full rounded-small border-none bg-transparent pl-3 pr-10 text-white/80 outline-none font-style-b2-body-copy focus:ring-green"
              placeholder="Search for a team"
              value={teamSearch}
              onChange={(e) => setTeamSearch(e.target.value)}
            />
          </div>
        </div>
        {gameContext.enabledGames.length > 1 && (
          <div className="flex justify-center gap-x-6 pt-5">
            {gameContext.enabledGames.map((gameId) => (
              <Button
                key={gameId}
                variant="secondary"
                className={classNames({
                  "border-neutral-50 hover:border-neutral-50 active:border-neutral-50": selectedGameInternal !== gameId,
                  "border-white hover:border-white active:border-white": selectedGameInternal === gameId,
                })}
                onClick={() => setSelectedGameInternal(gameId)}
              >
                <GameIcon gameId={gameId} color={selectedGameInternal == gameId ? "#fff" : "#8D858C"} />
              </Button>
            ))}
          </div>
        )}

        <div className=" my-5 size-full max-h-96 overflow-y-auto ">
          <ProfileTeams
            teams={filteredTeams || []}
            checkedTeamIds={favoriteTeams || []}
            onTeamClick={updateSelectedTeams}
          />
        </div>

        <div className="flex w-full place-content-center items-center gap-4">
          <Button isLoading={isPending} variant="default" onClick={saveFavoriteTeams}>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export { FavoriteTeamsModal };
