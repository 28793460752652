import { useMemo } from "react";

export function LoadingRewards() {
  const loadingProducts = useMemo(() => {
    // Randomize the amount of products to show
    const randomAmount = Math.floor(Math.random() * 3) + 5;

    type shape = "circle" | "square" | "oblong";

    const randomProducts = Array.from({ length: randomAmount }).map((_, index) => {
      const randomShape = ["circle", "square", "oblong"][Math.floor(Math.random() * 3)] as shape;
      return {
        key: `loading_${index}`,
        productShape: randomShape,
        titleLength: Math.floor(Math.random() * 10) + 8,
        descriptionLength: Math.floor(Math.random() * 15) + 8,
      };
    });

    return randomProducts;
  }, []);

  return (
    <div className="mb-12 grid grid-cols-1 flex-col gap-5 md:grid-cols-2">
      {loadingProducts.map((product, index) => (
        <div key={index} className="flex h-24 w-full rounded-small bg-canvas-95">
          <div className="flex w-28 animate-pulse place-content-center items-center rounded-l-small bg-canvas-90 px-4">
            {product.productShape === "circle" && (
              <div className="size-12 animate-pulse rounded-full bg-canvas-95/50 " />
            )}
            {product.productShape === "oblong" && <div className="h-8 w-12 animate-pulse bg-canvas-95/50 " />}
            {product.productShape === "square" && (
              <div className="size-12 animate-pulse rounded-small bg-canvas-95/50 " />
            )}
          </div>
          <div className="flex size-full justify-between p-4">
            <div className="flex h-full flex-col place-content-center gap-2">
              <div
                className="h-4 animate-pulse bg-canvas-100/50"
                style={{
                  width: `${product.titleLength}ch`,
                }}
              ></div>
              <div
                className="h-4 animate-pulse bg-canvas-100/50"
                style={{
                  width: `${product.descriptionLength}ch`,
                }}
              ></div>
            </div>
            <div className="flex h-full place-content-center items-center ">
              <div className="flex h-7 w-20 animate-pulse justify-between rounded bg-canvas-100/50 p-1.5">
                <div className="size-4 rounded-full bg-canvas-95" />
                <div className=" h-4 w-11 rounded-small bg-canvas-95" />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
