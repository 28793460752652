import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postJoinLeague } from ".";
import { queryKeys } from "../shared/queryKeys";

export const usePostJoinLeague = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: { joinCode: string }) => postJoinLeague(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.myLeagues(),
      });
      onSuccess?.();
    },
    onError: (error) => {
      onError?.(error);
    },
  });
};
