import { z } from "zod";
import { blastApi } from "../../../apiClients/blastApi";

export const GetLeagueDetailsSchema = z.object({
  isPublic: z.boolean(),
  leagueId: z.string(),
  leagueName: z.string(),
  joinCode: z.string().optional(),
});

export type GetLeagueDetails = z.infer<typeof GetLeagueDetailsSchema>;

export interface GetLeagueDetailsOptions {
  leagueId: string | undefined;
}

export const getLeagueDetails = async ({ leagueId }: GetLeagueDetailsOptions): Promise<GetLeagueDetails | null> => {
  if (!leagueId) {
    return null;
  }

  const { data } = await blastApi.get(`/v1/fantasy/leagues/${leagueId}`);
  return GetLeagueDetailsSchema.parse(data);
};
