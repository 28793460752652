import { z } from "zod";
import { blastApi } from "../../../apiClients/blastApi";

export const LeaveFantasyLeagueSchema = z.object({
  success: z.boolean(),
});

export const postLeaveLeague = async ({ leagueId }: { leagueId: string }) => {
  const { data } = await blastApi.post(`/v1/fantasy/leagues/${leagueId}/leave`);
  return LeaveFantasyLeagueSchema.parse(data);
};
