import { useNavigate, useParams } from "react-router-dom";
import { usePostJoinLeague } from "../../../../api/data/fantasy/postJoinLeague/usePostJoinLeague";
import { ErrorResponse, isAxiosError } from "./types";
import { LoadingPage } from "../../../LoadingPage";
import { useEffect } from "react";

export const FantasyJoinPage = () => {
  const { joinCode } = useParams();
  const navigate = useNavigate();

  const { mutate } = usePostJoinLeague({
    onSuccess: () => {
      navigate("/fantasy?leagueJoined=true");
    },
    onError: (error) => {
      if (isAxiosError<ErrorResponse>(error)) {
        navigate(`/fantasy?leagueJoined=false&error=${error.response?.data?.code}`);
      } else {
        navigate("/fantasy?leagueJoined=false");
        return null;
      }
    },
  });

  useEffect(() => {
    if (joinCode) mutate({ joinCode });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!joinCode) {
    navigate("/fantasy");
    return null;
  }

  return <LoadingPage />;
};
