import { blastApi } from "../../../apiClients/blastApi";
import { z } from "zod";

export const JoinFantasyLeagueSchema = z.object({
  leagueId: z.string(),
  leagueName: z.string(),
  joinCode: z.string().optional(),
});

export const postJoinLeague = async (payload: { joinCode: string }) => {
  const { data } = await blastApi.post(`/v1/fantasy/leagues/join`, payload);
  return JoinFantasyLeagueSchema.parse(data);
};
