import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, PageSlot, Select, Typography } from "@blastorg/portal-pattern-library";
import { LoadingPage } from "../../../LoadingPage";
import { FantasyHeader } from "../../components/FantasyHeader";
import { LeaderboardActionsHeader } from "./components/LeaderboardActionsHeader";
import { NoLeaderboard } from "./components/NoLeaderboard";
import { LeaveLeagueModal } from "../../components/FantasyLeagues/components/LeaveLeagueModal";
import { FantasyModal } from "../../components/FantasyLeagues/components/FantasyModal";
import { Leaderboard } from "./components/Leaderboard";
import { useGetLeaderboard } from "../../../../api/data/fantasy/getLeaderboard/useGetLeaderboard";
import { generateUrl } from "../../generateUrl";
import { useFetchFantasySeasons } from "../../../../api/data/sanity/getFantasySeasons/useFetchFantasySeasons";
import { GetLeaderBoardType, getLeaderboard } from "../../../../api/data/fantasy/getLeaderboard";
import { useGetLeagueDetails } from "../../../../api/data/fantasy/getLeagueDetails/useGetLeagueDetails";

export const FantasyLeaderboardPage = () => {
  const navigate = useNavigate();
  const [openLeaveLeague, setOpenLeaveLeague] = useState<boolean>(false);

  // Get the ID from the params
  const { seasonId, eventId, leagueId } = useParams();

  const { data: fantasySeasons } = useFetchFantasySeasons();

  const { data: leagueDetails } = useGetLeagueDetails({ leagueId });

  const {
    data: leaderboard,
    isLoading: isLeaderboardLoading,
    isError: isLeaderboardError,
  } = useGetLeaderboard({
    seasonId,
    eventId,
    leagueId,
  });

  const getNextLeaderboard10 = async (calculationId: string, offset: number) => {
    const data: GetLeaderBoardType = await getLeaderboard({
      seasonId,
      eventId,
      leagueId,
      calculationId,
      offset,
    });
    return data;
  };

  const navigateToSeason = useCallback(
    (uid: string) => {
      const pathParams: {
        leagueId?: string;
        seasonId?: string;
      } = {
        leagueId,
        seasonId: uid,
      };

      const url = generateUrl(pathParams);
      navigate(url);
    },
    [leagueId, navigate],
  );

  const navigateToEvent = useCallback(
    (uid: string) => {
      const pathParams: {
        leagueId?: string;
        seasonId?: string;
        eventId?: string;
      } = {
        leagueId,
        seasonId,
      };

      if (uid !== seasonId) {
        pathParams.eventId = uid;
      }

      const url = generateUrl(pathParams);
      navigate(url);
    },
    [leagueId, navigate, seasonId],
  );

  if (!fantasySeasons) {
    return <LoadingPage />;
  }

  const fantasySeason = fantasySeasons.find((season) => season.uid === seasonId);

  if (!fantasySeason) {
    // eslint-disable-next-line no-console
    console.error(`The season ${seasonId} was not found in the list of seasons. Does the season have an event?`);
    return <LoadingPage />;
  }

  const selectedEvent = eventId ? fantasySeason.events.find((event) => event.uid === eventId) : undefined;

  const latestEvent = fantasySeason.events[0];
  const fantasyEvent = selectedEvent ?? latestEvent;

  const toggleLeaveLeagueModal = () => {
    setOpenLeaveLeague(!openLeaveLeague);
  };

  if (!seasonId) {
    navigate("/fantasy");
    return null;
  }

  const players = {
    diamondPlayers: fantasyEvent.diamondPlayers,
    platinumPlayers: fantasyEvent.platinumPlayers,
    goldPlayers: fantasyEvent.goldPlayers,
    silverPlayers: fantasyEvent.silverPlayers,
    bronzePlayers: fantasyEvent.bronzePlayers,
  };

  return (
    <>
      <FantasyHeader fantasyEvent={fantasyEvent} isSeasonLeaderboard={eventId === undefined} />

      {leagueDetails?.isPublic === false && (
        <FantasyModal open={openLeaveLeague} setOpen={setOpenLeaveLeague}>
          <LeaveLeagueModal leagueId={leagueDetails.leagueId} setOpen={setOpenLeaveLeague} />
        </FantasyModal>
      )}
      <PageSlot>
        <div className="flex w-full flex-col place-content-center place-items-center">
          <LeaderboardActionsHeader
            handleLeaveLeague={toggleLeaveLeagueModal}
            fantasyEvent={fantasyEvent}
            league={leagueDetails}
            seasonId={seasonId}
            eventId={eventId ? eventId : seasonId}
          />

          <div className="flex w-full justify-end">
            <Select
              className="mt-4 w-1/2 place-self-end md:w-60"
              value={seasonId}
              options={fantasySeasons.map((season) => ({ value: season.uid, label: season.seasonNameTitle }))}
              onChange={navigateToSeason}
            />
            <Select
              className="mt-4 w-1/2 place-self-end md:w-60"
              value={selectedEvent ? selectedEvent.uid : fantasySeason.uid}
              options={[
                { value: seasonId, label: "Full Season" },
                ...fantasySeason.events.map(({ uid: value, eventNameTitle: label }) => ({ value, label })),
              ]}
              onChange={navigateToEvent}
            />
          </div>

          {isLeaderboardError ? (
            <NoLeaderboard />
          ) : (
            <Leaderboard
              leaderboard={leaderboard}
              isLoading={isLeaderboardLoading}
              players={players}
              getNextData={getNextLeaderboard10}
            />
          )}
          {leaderboard?.playerCount && (
            <div className="mt-2 flex w-full">
              <Typography variant="navigation" color="neutral50" className="p-4">
                Out of <span className="text-neutral-0">{leaderboard.playerCount}</span> community managers
              </Typography>
            </div>
          )}
          {leagueId && leagueDetails?.isPublic === false && (
            <Button variant="tertiary" onClick={toggleLeaveLeagueModal} className="block pt-5 md:hidden">
              Exit League
            </Button>
          )}
        </div>
      </PageSlot>
    </>
  );
};
