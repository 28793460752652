export function generateUrl({
  seasonId,
  eventId,
  leagueId,
}: {
  seasonId?: string | null;
  eventId?: string | null;
  leagueId?: string | null;
}) {
  const seasonAndEventPath = [seasonId, eventId].filter(Boolean).join("/");

  if (leagueId) {
    return `/fantasy/league/${leagueId}/${seasonAndEventPath}`;
  } else {
    return `/fantasy/leaderboard/${seasonAndEventPath}`;
  }
}
