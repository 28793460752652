import classNames from "classnames";
import { useBreakpoints } from "@blastorg/portal-pattern-library";

type CaseItemProps = {
  item: {
    rarity: string;
    id: string;
    name: string;
    imageSrc: string;
  };
  inline?: boolean;
  secondary?: boolean;
};

const CaseItem = ({ item, inline = false, secondary = false }: CaseItemProps) => {
  return (
    <div
      className="relative"
      style={
        inline
          ? {
              width: "150px",
              height: "150px",
              display: "inline-block",
              marginLeft: "10px",
            }
          : {
              width: "150px",
              height: secondary ? "130px" : "150px",
              display: "inline-block",
              marginLeft: "10px",
            }
      }
    >
      <div
        className="absolute w-full overflow-hidden"
        style={{
          height: "calc(75% - 5px)",
        }}
      >
        {/* img_div */}
        <img src={item.imageSrc} className="absolute inset-0 z-[2] size-full max-w-full object-cover p-3" />
      </div>
      <div
        className={classNames("h-3/4 w-full overflow-hidden rounded-small opacity-75", {
          "bg-canvas-90": item.rarity === "common",
          "bg-gradient-to-b from-yellow/60 to-canvas-90": item.rarity === "gold",
          "bg-gradient-to-b from-[#ADB2FF80] via-[#DDB3FF80] to-transparent": item.rarity === "diamond",
        })}
      ></div>
      <div
        className={classNames("mt-[-5px] h-[5px] rounded-b-small", {
          "bg-neutral-50": item.rarity === "common",
          "bg-gradient-to-r from-[#FFF3B1] via-[#FFDD26] to-[#ECC701]": item.rarity === "gold",
          "bg-gradient-to-r from-[#FFFFFF] via-[#EBB2FF] to-[#A3B1FF]": item.rarity === "diamond",
        })}
        style={{
          clipPath: "polygon(0% -100px, 100% -100px, 100% 100%, 0% 100%)",
        }}
      ></div>
    </div>
  );
};

type CaseSpinnerProps = {
  animating: boolean;
  items: {
    rarity: string;
    id: string;
    name: string;
    imageSrc: string;
  }[];
  inline?: boolean;
};

const CaseSpinner = ({ items, animating, inline = false }: CaseSpinnerProps) => {
  const { isTablet } = useBreakpoints();

  return (
    <>
      <div
        className={classNames("relative mx-auto ", {
          // "mt-16 w-80": inline,
          "h-[150px] w-full md:h-[350px] md:w-[700px]": !inline,
        })}
      >
        <div className="absolute left-0 top-5 z-10 w-full">
          <div
            className={classNames("relative z-[5] w-full overflow-hidden whitespace-nowrap", {
              "h-[150px] md:h-[350px] md:w-[700px]": !inline,
              "h-[133px]": inline,
            })}
          >
            <div
              className="visible absolute top-0 z-10 size-full transform-gpu overflow-hidden"
              style={
                isTablet && !inline
                  ? {
                      clipPath: "circle(25.1% at 50% 50%)",
                      transform: "scale(1.2)",
                    }
                  : {
                      WebkitMaskImage:
                        "linear-gradient(to left, rgba(255, 255, 255, 0) 0%, #000 10%, #000 90%, rgba(255, 255, 255, 0) 100%)",
                    }
              }
            >
              {/* case_spin_zoomed */}
              <div
                className={classNames("my-5", {
                  "md:mt-[115px]": !inline,
                  "ml-[-76550px] sm:ml-[-7550px] md:ml-[-5642px] [@media(max-width:474px)]:ml-[-7500px]":
                    animating && !inline,
                  "ml-[-5755px] md:ml-[-5861px] [@media(max-width:460px)]:ml-[-5880px]": animating && inline,
                  "-ml-[55px]": !animating,
                })}
                style={{
                  transition: "margin-top 0s, all 6s cubic-bezier(0, 0.11, 0.33, 1) 0s",
                }}
              >
                {/* #items .case_items */}
                {items.map((item, index) => (
                  <CaseItem key={`zoomed_${index}`} item={item} inline={inline} />
                ))}
              </div>
              <div
                className={classNames("absolute left-1/2 top-5 z-[3] w-[2px] bg-yellow/80", {
                  "h-[110px] md:top-[115px]": !inline,
                  "h-[112px] md:top-5": inline,
                })}
              >
                {/* case_items_middle */}
              </div>
            </div>
            <div
              className={classNames("relative z-[2]", {
                "hidden h-[110px] md:block": !inline,
                "block ": inline,
              })}
              style={{
                WebkitMaskImage: "radial-gradient(circle closest-side, #fff0 20%, #000 166px)",
              }}
            >
              {/* case_preitemscircle */}
              <div
                style={{
                  WebkitMaskImage:
                    "linear-gradient(to left, rgba(255, 255, 255, 0) 0%, #000 10%, #000 90%, rgba(255, 255, 255, 0) 100%)",
                }}
              >
                {/* case_preitemslinear */}
                <div
                  className={classNames("mt-[125px] transform-gpu", {
                    "ml-[-7650px] sm:ml-[-7550px] md:ml-[-7342px]": animating,
                    "-ml-[55px]": !animating,
                  })}
                  style={{
                    filter: "brightness(0.75) blur(2px)",
                    transition: "all 6s cubic-bezier(0, 0.11, 0.33, 1) 0s",
                  }}
                >
                  {/* #items .case_items */}
                  {items.map((item, index) => (
                    <CaseItem key={index} item={item} inline={inline} secondary={true} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { CaseSpinner };
