import { useQuery } from "@tanstack/react-query";
import { usePointsApi } from "../../../../../api/ApiProvider/hooks";
import { PointsCollection } from "../../../../../api/PointsApiClient/types/pointsCollections";
import { PointsProduct } from "../../../../../api/PointsApiClient/schemas";

const useFetchCollections = ({ product }: { product: PointsProduct | undefined }) => {
  const pointsApi = usePointsApi();

  return useQuery({
    queryKey: ["pointsCollections"],
    queryFn: pointsApi.getCollections,
    staleTime: 120_000,
    select: (data): PointsCollection | undefined =>
      data?.collections?.find((c) => c.collectionId === product?.collectionId),
  });
};

export { useFetchCollections };
