import classnames from "classnames";
import { useCSGuessrGame } from "../../../../../../hooks/useCSGuessrGame";
import { RoundsOverview } from "../RoundsOverview";
import { Transition } from "@headlessui/react";
import { useEffect, useState } from "react";
import { STATE_TRANSITION_DURATION } from "../../constants";
import { GameControlsArea } from "../GameControlsArea";
import { NextRoundButton } from "../NextRoundButton";
import { LocationImageModal } from "./components/LocationImageModal";
import { Button } from "@blastorg/portal-pattern-library";
import { MapControlsArea } from "../MapControlsArea";
import expandSvg from "./assets/expand.svg";

const MobileView = ({ isCorrectMapSelected }: { isCorrectMapSelected: boolean }) => {
  const { state, selectedPosition, currentRound, isLoading, submitUserAnswer, openHowToPlay } = useCSGuessrGame();
  const [showingGuessingState, setShowingGuessingState] = useState<boolean>(false);
  const [showingResultsState, setShowingResultsState] = useState<boolean>(false);
  const [isLocationImageModalOpen, setIsLocationImageOpen] = useState<boolean>(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    if (state === "SELECTING_MAP") {
      setShowingResultsState(false);
      setShowingGuessingState(false);
    }

    if (state === "SELECTING_POSITION") {
      setShowingResultsState(false);

      timeout = setTimeout(() => setShowingGuessingState(true), STATE_TRANSITION_DURATION);
    }

    if (state === "SHOWING_ANSWER") {
      setShowingResultsState(true);
      setShowingGuessingState(false);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [state]);

  if (!currentRound) return null;

  return (
    <>
      <div
        className="mb-4 transition-all ease-in-out"
        style={{
          transitionDuration: `${STATE_TRANSITION_DURATION}ms`,
        }}
      >
        <div className={classnames("flex h-32 w-full gap-4 overflow-hidden rounded-small transition-all ease-in-out")}>
          <div
            className="relative flex h-full shrink-0 overflow-hidden rounded-small"
            onClick={() => setIsLocationImageOpen(true)}
          >
            <img
              src={currentRound.location.image}
              className={classnames("h-full object-contain", "transition-all ease-in-out")}
              alt="location"
            />
            <img src={expandSvg} alt="" className="pointer-events-none absolute right-1 top-1 size-6" />
          </div>
          <div className="size-full flex-1 overflow-hidden">
            <GameControlsArea />
          </div>
        </div>
      </div>
      <MapControlsArea isCorrectMapSelected={isCorrectMapSelected} />
      <div className="relative flex h-16 w-full items-center justify-center ">
        <Transition
          show={showingGuessingState}
          enter="transition-all ease-in-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-all ease-in-out"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          style={{
            transitionDuration: `${STATE_TRANSITION_DURATION}ms`,
          }}
          className="absolute inset-0 flex items-center justify-center"
        >
          <Button variant="default" onClick={() => submitUserAnswer()} isDisabled={!selectedPosition || isLoading}>
            Confirm Guess
          </Button>
        </Transition>
        <Transition
          show={showingResultsState}
          enter="transition-all delay-500 ease-in-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-all ease-in-out"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          style={{
            transitionDuration: `${STATE_TRANSITION_DURATION}ms`,
          }}
          className="absolute inset-0 flex items-center justify-center"
        >
          <NextRoundButton />
        </Transition>
      </div>
      <RoundsOverview className="mx-auto" />
      <Button variant="tertiary" onClick={openHowToPlay} className="mx-auto mt-4">
        How To Play
      </Button>
      <LocationImageModal
        image={currentRound.location.image}
        isOpen={isLocationImageModalOpen}
        onClose={() => setIsLocationImageOpen(false)}
      />
    </>
  );
};

export { MobileView };
