import { type ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { useGetFlag } from "@data/flagsmith/getFlags/useGetFlag";
import { useAuth } from "../../auth/AuthProvider/hooks/useAuth";
import { LoadingPage } from "../../routes/LoadingPage";

const ProtectedRoute = ({ children, requiredFlag }: { children: ReactElement; requiredFlag?: string }) => {
  const { isAuthenticated } = useAuth();
  const { enabled: isFlagEnabled, loading: isFlagLoading } = useGetFlag(requiredFlag ?? "");

  if (!!requiredFlag && isFlagLoading) {
    return <LoadingPage />;
  }

  if (!isAuthenticated || (!!requiredFlag && !isFlagEnabled)) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export { ProtectedRoute };
