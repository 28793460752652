import { client } from "../../../apiClients/sanity";
import { FantasyEventSchema } from "../shared/types/fantasy";

export async function getFantasyEvent(userEmail?: string) {
  const sanityQuery = `
        *[_type == "fantasyEvent" && (!defined(whitelistedEmails) || "${
          userEmail ?? "NO_USER_EMAIL"
        }" in whitelistedEmails)] | order(endTime desc)[0] {
          ...,
          season->{
            ...,
          },
        }
      `;

  const data = await client.fetch(sanityQuery).catch((error) => {
    throw new Error("failed to fetch CMS data", { cause: error });
  });

  if (!data) {
    throw new Error("No data returned from CMS");
  }
  return FantasyEventSchema.parse(data);
}
