import { Modal } from "@blastorg/portal-pattern-library";
import collapseSvg from "./assets/collapse.svg";

interface LocationImageModalProps {
  isOpen: boolean;
  onClose: () => void;
  image: string;
}

const LocationImageModal = ({ isOpen, onClose, image }: LocationImageModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="w-full overflow-hidden rounded-small" onClick={onClose}>
        <img src={image} alt="location" className="block w-full object-contain" />
        <img src={collapseSvg} alt="" className="pointer-events-none absolute right-8 top-2 z-10 size-7" />
      </div>
    </Modal>
  );
};

export { LocationImageModal };
