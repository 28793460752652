import styled, { css } from "styled-components";
import { colors } from "../../../../../designSystemLegacy/colors";
import { toRem } from "@blastorg/portal-pattern-library";
import { zIndex } from "../../../../../styles/zIndex";
import { maxWidthContentFrame, navigationHeightMobilePx } from "../../../../../styles/constants";
import logo from "../../assets/logo.gif";
import logoAnimated from "../../assets/logo-animated.gif";

import { NavbarLogoItem } from "../shared/NavbarLogoItem";

export const StyledNavBarLogoItem = styled(NavbarLogoItem)`
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Nav = styled.nav`
  user-select: none;
  background-color: ${colors.blastCanvas};
  width: 100vw;
  height: ${toRem(navigationHeightMobilePx)};
  position: fixed;
  top: 0;
  z-index: 10;
`;

export const NavContent = styled.div`
  display: flex;
  height: 100%;
  max-width: ${maxWidthContentFrame}px;
  margin: 0 auto;
  padding: 0 1.5rem;
  align-items: center;
  justify-content: space-between;
  z-index: ${zIndex.navbar};
  position: relative;
`;

export const BurgerIconWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  padding-right: ${toRem(12)};
`;

export const Logo = styled.div<{
  isActive?: boolean;
  isTablet?: boolean;
}>`
  width: ${toRem(28)};
  height: ${({ isTablet }) => (isTablet ? toRem(28) : "100%")};
  background: url("${logo}") no-repeat center;
  background-size: contain;
  border-bottom: 2px solid transparent;
  border-top: 2px solid transparent;
  ${({ isTablet }) =>
    !isTablet &&
    css`
      margin-right: ${toRem(24)};
    `}

  ${({ isActive }) => isActive && `border-bottom: 2px solid ${colors.blastYellow};`}

  @media (hover: hover) {
    &:hover {
      background-image: url("${logoAnimated}");
    }
  }
`;
