import { useEffect } from "react";

const venatusAdManagerScriptUrl = "https://hb.vntsm.com/v3/live/ad-manager.min.js";

export const useVenatusAdManagerScript = () => {
  useEffect(() => {
    // Check if the script is already loaded
    const existingScript = document.querySelector(`script[src="${venatusAdManagerScriptUrl}"]`);

    if (existingScript) {
      return;
    }

    // const script = document.createElement("script");
    // script.src = venatusAdManagerScriptUrl;
    // script.type = "text/javascript";
    // script.dataset.siteId = "65cdecfa723bb22a15c7a297";
    // script.dataset.mode = "scan";
    // script.async = true;

    // document.head.appendChild(script);

    // return () => {
    //   document.head.removeChild(script);

    //   // remove all vntsm.com scripts from head
    //   const scripts = document.head.querySelectorAll('script[src*="vntsm.com"]');
    //   scripts.forEach((script) => {
    //     document.head.removeChild(script);
    //   });
    // };
  }, []);
};
