import classNames from "classnames";

export const CaptainIcon = ({ className }: { className?: string }) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames("size-6", className)}
  >
    <path
      id="Union"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9487 10.5313C17.9487 14.9212 14.39 18.48 10 18.48C5.61004 18.48 2.05128 14.9212 2.05128 10.5313C2.05128 6.14129 5.61004 2.58253 10 2.58253C14.39 2.58253 17.9487 6.14129 17.9487 10.5313ZM20 10.5313C20 16.0541 15.5228 20.5313 10 20.5313C4.47715 20.5313 0 16.0541 0 10.5313C0 5.0084 4.47715 0.53125 10 0.53125C15.5228 0.53125 20 5.0084 20 10.5313ZM11.9142 12.808C12.1669 12.6183 12.4003 12.3906 12.6141 12.1439L13.9751 13.3772C13.6834 13.7378 13.3529 14.0413 12.9641 14.326C12.2836 14.8003 11.3115 15.2747 9.98943 15.2747C7.26754 15.2747 5.12891 13.1875 5.12891 10.5311C5.12891 7.87468 7.26754 5.7875 9.98943 5.7875C11.3115 5.7875 12.2836 6.24288 12.9252 6.69827C13.2946 6.96391 13.6251 7.2675 13.8778 7.59006L12.5169 8.8234C12.3419 8.59571 12.1281 8.38699 11.8753 8.21622C11.4281 7.91263 10.8254 7.59006 9.98943 7.59006C8.33685 7.59006 7.07312 8.80442 7.07312 10.5311C7.07312 12.2578 8.33685 13.4721 9.98943 13.4721C10.8254 13.4721 11.467 13.1685 11.9142 12.808Z"
      fill="inherit"
    />
  </svg>
);
