import { useCSGuessrGame } from "../../hooks/useCSGuessrGame";
import { GameEndedView } from "./components/GameEndedView";
import { GuessLocationView } from "./components/GuessLocationView";
import { CSGuessrHeader } from "./components/Header";
import { CSGuessrPageSlot } from "./components/CSGuessrPageSlot";
import { HowToPlayModal } from "./components/HowToPlayModal";
import { Loader } from "../../../../shared/components/Loader";
import { StickySideRailAds } from "@shared/components/StickySideRailAds";
import { useBreakpoints } from "@blastorg/portal-pattern-library";

export function CSGuessrGame() {
  const { state, isHowToPlayOpen, closeHowToPlay, isLoading } = useCSGuessrGame();
  const { isLargeDesktop } = useBreakpoints();

  return (
    <>
      {isLargeDesktop && <StickySideRailAds />}
      <div className="m-nav">
        <CSGuessrPageSlot className="relative">
          <CSGuessrHeader />
          {isLoading ? (
            <Loader className="mt-[25%]" />
          ) : state === "GAME_ENDED" ? (
            <GameEndedView />
          ) : (
            <GuessLocationView />
          )}
          <HowToPlayModal isOpen={isHowToPlayOpen} onClose={closeHowToPlay} />
        </CSGuessrPageSlot>
      </div>
    </>
  );
}
