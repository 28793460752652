import styled, { css } from "styled-components";
import { toRem, useBreakpoints } from "@blastorg/portal-pattern-library";
import { Heading } from "../../designSystemLegacy/typography/Heading";
import { Text } from "../../designSystemLegacy/typography/Text";
import { navigationHeightMobilePx, navigationHeightPx } from "../../styles/constants";

const flex = css`
  margin: 0 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CenterDesktop = styled.div`
  ${flex}
  margin-top: ${toRem(navigationHeightPx)};
  height: calc(100vh - ${toRem(navigationHeightPx)});
`;
const CenterMobile = styled.div`
  ${flex}
  margin-top: ${toRem(navigationHeightMobilePx)};
  height: calc(100vh - ${toRem(navigationHeightMobilePx)});
`;

const Wrapper = ({ children }: { children: React.ReactNode }) => {
  const { isMobile } = useBreakpoints();
  return isMobile ? <CenterMobile>{children}</CenterMobile> : <CenterDesktop>{children}</CenterDesktop>;
};

const ErrorPage = () => {
  const { isMobile } = useBreakpoints();

  return (
    <Wrapper>
      <section>
        <Heading asTag="h1" typography={isMobile ? "mobileH5" : "desktopH5"}>
          Oops, something went wrong
        </Heading>
        <Text asTag="p" typography={isMobile ? "mobileB3Body" : "desktopB3Body"}>
          Try refreshing your browser or try again later.
        </Text>
      </section>
    </Wrapper>
  );
};

export { ErrorPage };
