import { useCSGuessrGame } from "../../../../../../hooks/useCSGuessrGame";
import { MapPositionSelector } from "../MapPositionSelector";
import { MapSelection } from "../MapSelection";
import { useEffect, useState } from "react";

const MapControlsArea = ({ isCorrectMapSelected }: { isCorrectMapSelected: boolean }) => {
  const { state, maps, selectMap, selectedMap, selectedPosition, setSelectedPosition, currentRound, isLoading } =
    useCSGuessrGame();

  const [showingMapSelection, setShowingMapSelection] = useState<boolean>(false);
  const [showingPointSelection, setShowingPointSelection] = useState<boolean>(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    if (state === "SELECTING_MAP") {
      setShowingPointSelection(false);
      setShowingMapSelection(true);
    }

    if ((state === "SELECTING_POSITION" || state === "SHOWING_ANSWER") && !!selectedMap) {
      setShowingMapSelection(false);
      setShowingPointSelection(true);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [state, selectedMap]);

  if (!currentRound) return null;

  return (
    <div className="relative aspect-square h-fit w-full overflow-hidden">
      <MapSelection show={showingMapSelection} maps={maps} onSelect={selectMap} />
      <MapPositionSelector
        show={showingPointSelection}
        map={selectedMap}
        position={selectedPosition}
        onSetPosition={setSelectedPosition}
        correctPosition={isCorrectMapSelected ? currentRound.userAnswer?.location.position : undefined}
        disabled={isLoading || !!currentRound.userAnswer}
      />
    </div>
  );
};

export { MapControlsArea };
