import { useRef, type ElementRef } from "react";
import { useResizeObserver } from "usehooks-ts";
import { Float } from "@headlessui-float/react";
import { Listbox } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { Fragment } from "react";

interface Option<T = string> {
  text: string;
  value: T;
}

interface SelectorFieldProps<T extends Option> {
  title: string;
  options: T[];
  value: string;
  setValue: (value: T["value"]) => void;
}

function SelectorField<T extends Option>({ title, options, value, setValue }: SelectorFieldProps<T>) {
  const squareRef = useRef<ElementRef<"div">>(null);
  const { width = 0 } = useResizeObserver({
    ref: squareRef,
    box: "border-box",
  });

  return (
    <Listbox
      as="div"
      className="relative inline-block w-full text-left"
      ref={squareRef}
      onChange={(value) => {
        const option = options.find((option) => option.text === value);
        if (option) {
          setValue(option.value);
        }
      }}
      value={value}
    >
      <span className="mb-1.5 inline-flex w-full place-content-center font-style-label-3">{title}</span>

      <Float placement="bottom-end" adaptiveWidth portal floatingAs={Fragment}>
        <Listbox.Button className="flex w-full justify-between rounded-small border border-neutral-50 bg-transparent px-3 py-2.5 outline-none ring-0 focus:border focus:border-purple">
          <span className="max-w-[85%] overflow-hidden truncate whitespace-nowrap text-white font-style-b2-body-copy">
            {value}
          </span>
          <ChevronDownIcon className=" -mr-1 size-5 text-white" aria-hidden="true" />
        </Listbox.Button>

        <Listbox.Options
          style={{
            width: width,
          }}
          className="m-0 max-h-60 origin-top-right overflow-y-auto rounded-small border-neutral-50/20 bg-canvas-90 p-0 shadow-lg ring-0 focus:outline-none"
        >
          {options.map((option) => (
            <Listbox.Option key={option.value} value={option.text}>
              {({ active, selected }) => (
                <li
                  className={classNames(
                    "flex place-content-start items-center whitespace-nowrap py-1 pl-4 text-start text-white hover:cursor-pointer hover:bg-canvas-95",
                    {
                      "bg-canvas-95": active || selected,
                    },
                  )}
                >
                  {selected ? (
                    <CheckIcon className="mr-2 size-4 shrink-0 text-white" />
                  ) : (
                    <div className="mr-2 size-4" />
                  )}
                  <span className="max-w-[85%] overflow-hidden truncate">{option.text}</span>
                </li>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Float>
    </Listbox>
  );
}

export { SelectorField };
