import { z } from "zod";
import { blastApi } from "../../../apiClients/blastApi";

export const DetailedPicksMatchSchema = z.object({
  id: z.string(),
  rank: z.number(),
  won: z.boolean(),
  allRanks: z.array(
    z.object({
      playerId: z.string().uuid(),
      rank: z.number(),
      rankPoints: z.number(),
    }),
  ),
  scoring: z.object({
    rankPoints: z.number(),
    winBonus: z.number(),
    multiplier: z.number(),
    totalPoints: z.number(),
  }),
});

export const DetailedPickSchema = z.object({
  playerId: z.string().uuid(),
  isCaptain: z.boolean(),
  matches: z.array(DetailedPicksMatchSchema),
  totalPoints: z.number(),
  eliminated: z.boolean(),
});

export const MyPicksSchema = z.object({
  picks: z.array(DetailedPickSchema),
  totalPoints: z.number(),
});

export const getUserPicksDetailed = async ({
  seasonId,
  eventId,
  calculationSchemaId,
}: {
  seasonId: string;
  eventId: string;
  calculationSchemaId: string;
}) => {
  const { data } = await blastApi.get(`/v1/fantasy/seasons/${seasonId}/events/${eventId}/picks/detailed`, {
    params: {
      calculationSchemaId,
    },
  });

  return MyPicksSchema.parse(data);
};
