import { PencilIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

interface InputFieldProps {
  title: string;
  value: string;
  onEdit?: () => void;
  shouldShowEditButton?: boolean;
}

const DisabledInputField = ({ title, value, shouldShowEditButton, onEdit }: InputFieldProps) => {
  return (
    <div className="flex flex-col overflow-hidden">
      <span className="mb-1.5 inline-flex w-full place-content-center font-style-label-3">{title}</span>
      <div className="flex items-center justify-center rounded-small border border-neutral-50/20 bg-canvas-90 py-2.5">
        <span
          className={classNames("max-w-[80%] overflow-hidden truncate text-neutral-50 font-style-b2-body-copy", {
            "pl-5": shouldShowEditButton,
            "cursor-not-allowed": !shouldShowEditButton,
          })}
        >
          {value}
        </span>
        {shouldShowEditButton && (
          <button
            onClick={onEdit}
            className="group ml-4 mr-2 cursor-pointer rounded-small border border-yellow bg-transparent hover:bg-yellow"
          >
            <PencilIcon
              className="size-2 text-yellow group-hover:text-canvas-100"
              title={`Edit ${value}`}
              onClick={onEdit}
            />
          </button>
        )}
      </div>
    </div>
  );
};

export { DisabledInputField };
