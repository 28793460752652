import classNames from "classnames";

const GraphIcon = ({ className }: { className?: string }) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames("size-6", className)}
  >
    <path
      id="Union"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.4 2.53125H17.6C17.8209 2.53125 18 2.71034 18 2.93125V18.1313C18 18.3522 17.8209 18.5312 17.6 18.5312H2.4C2.17909 18.5312 2 18.3522 2 18.1313V2.93125C2 2.71034 2.17909 2.53125 2.4 2.53125ZM0 2.93125C0 1.60577 1.07452 0.53125 2.4 0.53125H17.6C18.9255 0.53125 20 1.60577 20 2.93125V18.1313C20 19.4567 18.9255 20.5312 17.6 20.5312H2.4C1.07452 20.5312 0 19.4567 0 18.1313V2.93125ZM15.9429 6.06537C16.1273 5.54477 15.8547 4.97327 15.3341 4.7889C14.8135 4.60452 14.242 4.87708 14.0576 5.39768L11.3127 13.1481L8.49035 9.04287C7.82866 8.08041 6.3488 8.30361 6.00042 9.41843L4.24579 15.0332C4.08105 15.5604 4.37485 16.1213 4.90199 16.286C5.42914 16.4507 5.99001 16.1569 6.15475 15.6298L7.54133 11.1927L10.3316 15.2513C10.9796 16.1938 12.4231 16.0037 12.8049 14.9255L15.9429 6.06537Z"
      fill="#FFFE3E"
    />
  </svg>
);

export { GraphIcon };
