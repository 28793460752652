import styled, { css } from "styled-components";
import { colors, Color } from "../../colors";
import { toRem } from "@blastorg/portal-pattern-library";

const headline = css`
  font-family: "RadionA";
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: -0.02em;
  margin: 0;
`;

// Desktop Variants
export const desktopH1 = css`
  // Figma: Desktop/D - H1 Headline;
  ${headline}
  font-size: ${toRem(120)};
  line-height: ${110 / 120};
`;

export const desktopH2 = css`
  // Figma: Desktop/D - H2 Headline;
  ${headline}
  font-size: ${toRem(90)};
  line-height: ${84 / 90};
`;

export const desktopH3 = css`
  // Figma: Desktop/D - H3 Headline;
  ${headline}
  font-size: ${toRem(72)};
  line-height: ${66 / 72};
`;

export const desktopH4 = css`
  // Figma: Desktop/D - H4 Headline;
  ${headline}
  font-size: ${toRem(48)};
  line-height: ${44 / 48};
`;

export const desktopH5 = css`
  // Figma: Desktop/D - H5 Headline;
  ${headline}
  font-size: ${toRem(32)};
  line-height: ${30 / 32};
`;

export const desktopH6 = css`
  // Figma: Desktop/D - H6 Headline;
  ${headline}
  font-size: ${toRem(24)};
  line-height: ${28 / 24};
`;

// Mobile Variants
export const mobileH1 = css`
  // Figma: Mobile/M - H1 Headline
  ${headline}
  font-size: ${toRem(60)};
  line-height: ${54 / 60};
`;

export const mobileH2 = css`
  // Figma: Mobile/M - H2 Headline
  ${headline}
  font-size: ${toRem(50)};
  line-height: ${46 / 50};
`;

export const mobileH3 = css`
  // Figma: Mobile/M - H3 Headline
  ${headline}
  font-size: ${toRem(44)};
  line-height: ${40 / 44};
`;

export const mobileH4 = css`
  // Figma: Mobile/M - H4 Headline
  ${headline}
  font-size: ${toRem(32)};
  line-height: ${30 / 32};
`;

export const mobileH5 = css`
  // Figma: Mobile/M - H5 Headline;
  ${headline}
  font-size: ${toRem(22)};
  line-height: ${24 / 22};
`;

const headingsStyles = {
  desktopH1,
  desktopH2,
  desktopH3,
  desktopH4,
  desktopH5,
  desktopH6,
  mobileH1,
  mobileH2,
  mobileH3,
  mobileH4,
  mobileH5,
};

export type HeadingTypography = keyof typeof headingsStyles;

export const StyledHeading = styled.span<{
  typography: HeadingTypography;
  $color?: Color;
}>`
  ${(props) => headingsStyles[props.typography]}
  color: ${(props) => (props.$color ? colors[props.$color] : "inherit")};
`;
