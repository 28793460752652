import { PageSlot } from "@blastorg/portal-pattern-library";
import classNames from "classnames";
import { useMemo } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { generateImageUrl } from "../../api/data/assets/helpers/generateImageUrl";
import { generateAssetsId } from "../../api/data/assets/helpers/generateAssetsId";
import { useUserProfile } from "../../auth/useUserProfile";
import { useGetAvatars } from "../../api/data/assets/getAvatars/useGetAvatars";
import { useAvatarModal } from "../../providers/AvatarModal/useAvatarModal";
import { ProfileHeader } from "./components/ProfileView/components/ProfileHeader";

const tabs = [
  {
    name: "Profile",
    url: "/profile",
  },
  {
    name: "Redeem",
    url: "/profile/redeem",
  },
  {
    name: "My Rewards",
    url: "/profile/rewards",
  },
];

const ProfilePage = () => {
  const { data: avatars } = useGetAvatars();
  const userProfile = useUserProfile();

  const userAvatar = useMemo(() => {
    if (!userProfile || !avatars) {
      return null;
    }

    const avatar = avatars.find((avatar) => avatar.id === userProfile.avatarId);

    if (!avatar) {
      return null;
    }

    return avatar;
  }, [userProfile, avatars]);

  const pathname = useLocation().pathname;

  const changeAvatarModal = useAvatarModal();

  if (!userProfile) {
    return null;
  }

  return (
    <div className="m-nav">
      <ProfileHeader
        avatar={userAvatar}
        username={userProfile.username}
        avatarImageUrl={generateImageUrl("avatars", generateAssetsId(userProfile.avatarId, "3d"), {
          width: "450",
          format: "auto",
        })}
        onClickChangeAvatar={() => changeAvatarModal.setIsOpen(true)}
      />
      <PageSlot>
        <div className="flex w-full justify-center gap-3 overflow-x-auto pb-7 pt-4 md:w-fit">
          {tabs.map((tab) => (
            <Link
              key={tab.name}
              to={tab.url}
              aria-disabled={pathname === tab.url}
              className={classNames("size-fit rounded-small px-4 py-2 no-underline", {
                "cursor-default bg-neutral-10 text-canvas-100": pathname === tab.url,
                "bg-canvas-90 text-neutral-50 hover:bg-canvas-80": pathname !== tab.url,
              })}
            >
              <span className="font-style-label-3">{tab.name}</span>
            </Link>
          ))}
        </div>
        <Outlet />
      </PageSlot>
    </div>
  );
};
export { ProfilePage };
