import { Series } from "../../../../api/data/tournament/shared/types/Series";

const getResultBasedOnScore = (match: Series, definingScore: number) => {
  if (match.teamAScore === match.teamBScore) return null;

  if (match.teamAScore === definingScore) {
    return {
      winner: match.teamA,
      loser: match.teamB,
    };
  } else if (match.teamBScore === definingScore) {
    return {
      winner: match.teamB,
      loser: match.teamA,
    };
  } else {
    return null;
  }
};

export const getMatchResultTeams = (match: Series) => {
  if (match.teamAScore === match.teamBScore) return null;
  switch (match.type) {
    case "BO1":
      return getResultBasedOnScore(match, 1);
    case "BO3":
      return getResultBasedOnScore(match, 2);
    case "BO5":
      return getResultBasedOnScore(match, 3);
    default:
      throw new Error("Invalid match type");
  }
};
