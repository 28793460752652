import { Button, Link, Modal } from "@blastorg/portal-pattern-library";
import { Transition } from "@headlessui/react";
import classNames from "classnames";
import { useCallback, useMemo, useRef, useState } from "react";
import { useUpdateEffect } from "@shared/hooks/useUpdateEffect";
import { usePurchaseProduct } from "../../../../../../api/PointsApiClient/hooks/usePurchaseProduct";
import { PointsProduct } from "../../../../../../api/PointsApiClient/schemas";
import { useGetAvatars } from "../../../../../../api/data/assets/getAvatars/useGetAvatars";
import { useGetEmojis } from "../../../../../../api/data/assets/getEmojis/useGetEmojis";
import { generateAssetsId } from "../../../../../../api/data/assets/helpers/generateAssetsId";
import { generateImageUrl } from "../../../../../../api/data/assets/helpers/generateImageUrl";
import { useUserProfile } from "../../../../../../auth/useUserProfile";
import { CaseRoulette } from "../../../../../../shared/components/CaseRoulette";
import { AvatarGrid } from "../../../../../../shared/components/CaseRoulette/components/AvatarGrid";
import { RarityChip } from "../../../../../../shared/components/CaseRoulette/components/RarityChip";
import { Icon } from "../../../../../../shared/components/Icon";
import { baseAssetsURL } from "../../../../../../config";
import { useAlert } from "../../../../../../providers/AlertProvider/hooks/useAlert";
import { convertToCurrencyFormat } from "../../../../../../shared/helpers/convertToCurrencyFormat";
import { Avatar } from "../../../../../../types/Avatar";
import { Emoji } from "../../../../../../types/Emoji";
import { useUpdateProfile } from "../../../../hooks/useUpdateProfile";
import { useFetchCollections } from "../../hooks/useFetchCollections";

type RarityMap = {
  diamond: string[];
  gold: string[];
  silver: string[];
};

type ItemWithRarity = {
  id: string;
  name: string;
  rarity: string;
  imageSrc: string;
};
interface ProductPurchaseModalProps {
  isOpen: boolean;
  onClose: () => void;
  product?: PointsProduct;
}

export function ProductPurchaseModal({ isOpen, onClose, product }: ProductPurchaseModalProps) {
  const caseVideoRef = useRef<HTMLVideoElement | null>(null);
  const [showAnimation, setShowAnimation] = useState(false);
  const timeoutIds = useRef<NodeJS.Timeout[]>([]);

  // const { playOpening, playClicks, playFinal, unload, initializeStates } = useSoundQueue();
  const [animationCompleted, setAnimationCompleted] = useState(false);
  const { data: collection } = useFetchCollections({
    product,
  });
  const { data: avatars } = useGetAvatars();
  const { data: emojis } = useGetEmojis();
  const user = useUserProfile();
  const {
    mutate: updateAvatar,
    isPending: isLoadingAvatar,
    isSuccess: isSuccessAvatar,
    reset: resetAvatar,
  } = useUpdateProfile({
    userId: user?.id,
  });

  const alert = useAlert();

  const { data, mutate, isPending, isSuccess, reset } = usePurchaseProduct({
    onSuccess: () => {
      alert.showSuccessAlert("You have successfully redeemed this product!");
    },
  });

  const clearAllTimeouts = () => {
    timeoutIds.current.forEach(clearTimeout);
    timeoutIds.current = [];
  };

  useUpdateEffect(() => {
    if (!isOpen) {
      resetAvatar();
      reset();
      setShowAnimation(false);
      setAnimationCompleted(false);
      clearAllTimeouts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handlePurchaseProduct = async () => {
    if (!product) return;
    mutate(
      {
        id: product.id,
        body: undefined,
      },
      {
        onSuccess: () => {
          if (product.action === "REDEEM_CASE") {
            // playOpening();
            caseVideoRef.current?.play();
            timeoutIds.current.push(
              setTimeout(() => {
                // playClicks();
                setShowAnimation(true);
              }, 2000),
            );
            timeoutIds.current.push(
              setTimeout(() => {
                // const rarity = avatars?.find((a) => a.id === data.winner)?.rarity ?? "common";
                // playFinal(rarity);
                setAnimationCompleted(true);
              }, 2000 + 6000),
            );
          }
        },
      },
    );
  };

  const isDisabled = useMemo(() => {
    if (!product) return true;

    if (product.redemptionsLeft === 0 && product.allowedRedemptions) return true;

    return false;
  }, [product]);

  // Utility function to determine the rarity based on the id
  const getRarity = (id: string, rarityMap: RarityMap): string => {
    if (rarityMap.diamond.includes(id)) return "diamond";
    if (rarityMap.gold.includes(id)) return "gold";
    if (rarityMap.silver.includes(id)) return "common";
    return "common";
  };

  // Function to map IDs to items list
  const mapToItemsList = useCallback(
    (ids: string[], type: "avatars" | "emojis", items: Avatar[] | Emoji[], rarityMap: RarityMap): ItemWithRarity[] => {
      return ids.map((id): ItemWithRarity => {
        const item = items.find((item: Avatar | Emoji) => item.id === id) as Avatar | Emoji | undefined;

        const rarity = getRarity(id, rarityMap);

        const imageSrc =
          type === "avatars"
            ? generateImageUrl("avatars", generateAssetsId(id, "3d"), { format: "auto", width: "150" })
            : generateImageUrl("emojis", id, { format: "auto", width: "150" });

        return {
          id,
          name: item?.name || (type === "avatars" ? "Avatar" : "Emoji"),
          rarity,
          imageSrc,
        };
      });
    },
    [],
  );

  const overviewList = useMemo(() => {
    if (!collection) return [];
    const rarityMap = {
      diamond: collection.diamondRarity,
      gold: collection.goldRarity,
      silver: collection.silverRarity,
    };
    const allRarities = [...rarityMap.diamond, ...rarityMap.gold, ...rarityMap.silver];
    const items = collection.type === "emojis" ? emojis : avatars;

    if (!items) return [];

    return mapToItemsList(allRarities, collection.type, items, rarityMap);
  }, [collection, mapToItemsList, avatars, emojis]);

  const winner = useMemo((): ItemWithRarity | undefined => {
    if (!collection || !data?.winner) return undefined;
    const rarityMap: RarityMap = {
      diamond: collection.diamondRarity,
      gold: collection.goldRarity,
      silver: collection.silverRarity,
    };
    const items = collection.type === "emojis" ? emojis : avatars;

    if (!items || items.length === 0) return undefined;
    const mappedItems = mapToItemsList([data.winner], collection.type, items, rarityMap);
    return mappedItems.length > 0 ? mappedItems[0] : undefined;
  }, [data, mapToItemsList, avatars, emojis, collection]);

  const rouletteList = useMemo(() => {
    if (!collection || !data?.resultList) return [];
    const rarityMap = {
      diamond: collection.diamondRarity,
      gold: collection.goldRarity,
      silver: collection.silverRarity,
    };
    const items = collection.type === "emojis" ? emojis : avatars;

    if (!items) return [];
    return mapToItemsList(data.resultList, collection.type, items, rarityMap);
  }, [collection, data, mapToItemsList, avatars, emojis]);

  if (!product) return null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        // unload();
      }}
      className="bg-canvas-95"
    >
      <div className="fixed right-6 top-6 z-50 size-5 cursor-pointer rounded-full bg-white">
        <Icon icon="close" className="scale-75 text-[#000000]" onClick={() => onClose()} />
      </div>
      <div className="flex flex-col place-content-center items-center gap-4 px-4 py-5 md:max-w-[20rem]">
        <h3 className="max-w-full truncate font-style-desktop-h3">
          {animationCompleted && winner ? `${winner.name}!` : `${product.name}`}
        </h3>
        {winner && <RarityChip winner={winner} animationCompleted={animationCompleted} />}
        <div className="flex w-full flex-col place-content-center items-center gap-2">
          {product.action === "REDEEM_CASE" ? (
            <div className="relative w-full">
              {data && rouletteList && winner && showAnimation && (
                <Transition
                  appear
                  show={Boolean(data && rouletteList && winner)}
                  enter="transition-all duration-200"
                  enterFrom="scale-y-0"
                  enterTo="scale-y-100"
                  className="absolute size-full"
                >
                  <CaseRoulette list={rouletteList} winner={winner} />
                </Transition>
              )}
              <video
                className="h-auto w-56"
                ref={caseVideoRef}
                playsInline
                muted
                poster={generateImageUrl("products", product.id, {
                  width: "300",
                  format: "auto",
                })}
              >
                <source
                  src={`${baseAssetsURL}/video/products/LOOT_CASE_AVATARS_512x512_HEVC.mov`}
                  type="video/quicktime"
                />
                <source src={`${baseAssetsURL}/video/products/LOOT_CASE_AVATARS_512x512.webm`} type="video/webm" />
              </video>
            </div>
          ) : (
            <img
              alt={product.name}
              className="w-32"
              src={generateImageUrl("products", product.id, {
                width: "300",
                format: "auto",
              })}
            />
          )}
          {product.redemptionsLeft ? (
            <span className="text-neutral-50 font-style-b2-body-copy">{`Only ${product.redemptionsLeft} left!`}</span>
          ) : null}
          {product.redemptionsLeft === 0 && product.allowedRedemptions ? (
            <span className="text-neutral-50 font-style-b2-body-copy">Product not available</span>
          ) : null}
        </div>

        {!collection && (
          <div>
            {isSuccess ? (
              <span className="text-neutral-20 font-style-b2-body-copy">
                Redeem was successful! A member of the BLAST.tv support team (support@blast.tv) will contact you via
                your email with further details and your reward.
              </span>
            ) : (
              <span className=" text-neutral-20 font-style-b2-body-copy">
                {product.description}
                {"  "} Are you sure you want to redeem{" "}
                <span className="font-bold italic text-neutral-10"> {product.name}</span>?
              </span>
            )}
          </div>
        )}
        <div className="flex flex-col place-content-center items-center gap-3">
          {animationCompleted && winner && collection?.type !== "emojis" ? (
            <Button
              variant={isSuccessAvatar ? "success" : "default"}
              className={classNames({ "h-[2.75rem] py-[0.5rem]": !isSuccess })}
              onClick={() => updateAvatar({ avatarId: winner.id })}
              isLoading={isLoadingAvatar}
              isDisabled={isSuccessAvatar}
            >
              <span className="flex items-center gap-3">
                <span className="font-style-label-3">{isSuccessAvatar ? "Avatar updated!" : "Set as avatar"}</span>
              </span>
            </Button>
          ) : (
            <Button
              variant={isSuccess ? "success" : "default"}
              className={classNames({ "h-[2.75rem] py-[0.5rem]": !isSuccess })}
              onClick={handlePurchaseProduct}
              isLoading={isPending}
              isDisabled={isDisabled}
            >
              <span className="flex items-center gap-3">
                <span className="font-style-label-3">{isSuccess ? "REDEEMED!" : "CONFIRM"}</span>
                {!isSuccess && (
                  <span
                    className={classNames("flex items-center gap-1 rounded-full bg-canvas-80 px-2 py-1 text-white", {
                      "opacity-50": isDisabled,
                    })}
                  >
                    <Icon className="scale-75 text-yellow" icon="blastCoin" />
                    <span className="font-style-label-4">
                      {product.price && convertToCurrencyFormat(product.price)}
                    </span>
                  </span>
                )}
              </span>
            </Button>
          )}
          {collection && overviewList && <AvatarGrid collection={collection} overviewList={overviewList} />}
          <Link variant="tertiary" href="/points/terms-and-conditions" target="_blank">
            Terms and Conditions
          </Link>
        </div>
      </div>
    </Modal>
  );
}
