import { useQuery } from "@tanstack/react-query";
import { getLeagueDetails } from ".";
import { queryKeys } from "../shared/queryKeys";

export const useGetLeagueDetails = ({ leagueId }: { leagueId: string | undefined }) => {
  return useQuery({
    queryKey: queryKeys.leagueDetails({ leagueId }),
    queryFn: () => {
      return getLeagueDetails({ leagueId });
    },
  });
};
