import { CSGuessrGame } from "./components/CSGuessrGame";
import { CSGuessrGameProvider } from "./providers/CSGuessrGameProvider";
import { Footer } from "../../shared/components/Footer";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetFlag } from "../../api/data/flagsmith/getFlags/useGetFlag";

const CSGuessrPage = () => {
  const { enabled: csGuessrBlastFlagEnabled } = useGetFlag("cs_guessr_blast");
  const navigate = useNavigate();

  useEffect(() => {
    if (csGuessrBlastFlagEnabled) {
      navigate("/cs-guessr-blast");
    }
  }, [csGuessrBlastFlagEnabled, navigate]);

  return (
    <CSGuessrGameProvider>
      <CSGuessrGame />
      <Footer />
    </CSGuessrGameProvider>
  );
};

export { CSGuessrPage };
