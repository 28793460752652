import classnames from "classnames";
import { useCSGuessrGame } from "../../../../../../hooks/useCSGuessrGame";
import { useMemo } from "react";

interface RoundsOverviewProps {
  className?: string;
}

const RoundsOverview = ({ className }: RoundsOverviewProps) => {
  const { rounds, currentRound } = useCSGuessrGame();

  const currentRoundIndex = useMemo(() => {
    if (!currentRound) return undefined;

    const index = rounds.findIndex((round) => round.location.id === currentRound.location.id);
    return index === -1 ? undefined : index;
  }, [currentRound, rounds]);

  const dailyPoints = useMemo(() => {
    // sum of all rounds points
    return rounds.reduce((acc, round) => {
      return acc + (round.userAnswer?.score ?? 0);
    }, 0);
  }, [rounds]);

  if (!currentRound || currentRoundIndex === undefined) return null;

  return (
    <div className={classnames("flex w-fit gap-1", className)}>
      {rounds.map((round, index) => (
        <div
          key={index}
          className={classnames("my-4 flex w-12 flex-col items-center gap-2", "border-r border-neutral-20/20", {
            "[&>span]:opacity-100": index === currentRoundIndex,
            "[&>span]:opacity-20": index !== currentRoundIndex,
          })}
        >
          <span className="text-neutral-20 transition-all duration-200 font-style-label-3">R{index + 1}</span>
          <span className="text-neutral-0 transition-all duration-200 font-style-label-3">
            {round.userAnswer?.score ?? "-"}
          </span>
        </div>
      ))}
      <div className={classnames("my-4 flex flex-col items-center gap-2 px-2 ")}>
        <span className="text-neutral-20 font-style-label-3">TOTAL</span>
        <span className="text-neutral-0 font-style-label-3">{dailyPoints}</span>
      </div>
    </div>
  );
};

export { RoundsOverview };
