import { Button, Modal } from "@blastorg/portal-pattern-library";
import { useCallback } from "react";
import { useUpdateProfile } from "../../../../../hooks/useUpdateProfile";
import { useUserProfile } from "../../../../../../../auth/useUserProfile";
import steamLargeSvg from "../assets/steam_large.svg";
import { captureException } from "@sentry/browser";
import { useGetFlag } from "../../../../../../../api/data/flagsmith/getFlags/useGetFlag";

interface RemoveSteamModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const RemoveSteamModal = ({ isOpen, onClose }: RemoveSteamModalProps) => {
  const userProfile = useUserProfile();
  const { mutate: updateUserProfile, isPending } = useUpdateProfile({
    userId: userProfile?.id,
  });
  const { enabled: csCompeteFlagEnabled } = useGetFlag("frontend_cs_compete");
  const handleRemove = useCallback(async () => {
    updateUserProfile(
      {
        steamId: null,
      },
      {
        onSuccess: () => {
          onClose();
        },
        onError: (error) => {
          captureException(error, {
            tags: {
              component: "RemoveSteamModal",
            },
          });
        },
      },
    );
  }, [updateUserProfile, onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="flex flex-col items-center gap-12 bg-canvas-95 !p-16">
      <div className="max-w-lg font-style-desktop-h2 ">
        Hold up,
        <br />
        wait a minute...
      </div>
      <img src={steamLargeSvg} alt="Steam" />
      <div className="font-style-b1-body-copy">Are you sure you want to remove your Steam account?</div>
      {csCompeteFlagEnabled && (
        <div className="max-w-lg font-style-b2-body-copy">
          {
            "NOTE* Removing your Steam account wil also remove your ability to compete in our Community Tournaments and you will be removed from any active teams, matches and tournaments you are currently participating in."
          }
        </div>
      )}
      <Button variant="tertiary" onClick={handleRemove} isLoading={isPending}>
        Disconnect
      </Button>
    </Modal>
  );
};
