import { UseQueryResult } from "@tanstack/react-query";
import { useMemo } from "react";
import { groupBySingle } from "../../../../../../shared/helpers/groupBySingle";
import { Series } from "../../../../../../api/data/tournament/shared/types/Series";

export const useGroupMatches = (tournamentAllSeries: UseQueryResult<Series[] | undefined>) => {
  return useMemo(() => {
    if (!tournamentAllSeries.data) {
      return {};
    }

    return groupBySingle(tournamentAllSeries.data, "uuid");
  }, [tournamentAllSeries.data]);
};
