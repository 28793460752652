import { CallToAction } from "../../../api/data/sanity/types/CallToAction";
import { handleGaEvent } from "./handleGaEvent";

const handleCallToAction = ({
  handleLogin,
  handleRegister,
  handleNavigate,
  handleClaimAvatar,
  handleClaimEmoji,
  callToAction,
  analyticsArgs,
}: {
  handleRegister: () => void;
  handleLogin: () => void;
  handleNavigate: (path: string) => void;
  handleClaimAvatar: (avatarId: string) => void;
  handleClaimEmoji: (emojiId: string) => void;
  callToAction?: CallToAction;
  analyticsArgs?: {
    category: string;
    label: string;
  };
}) => {
  if (!callToAction) return;
  if (callToAction.type === "none") return;

  switch (callToAction.type) {
    case "external":
      if (analyticsArgs) {
        handleGaEvent({
          category: analyticsArgs.category,
          label: analyticsArgs.label,
          action: `External Link: ${callToAction.url}`,
        });
      }
      window.open(callToAction.url, "_blank");
      break;

    case "reroute":
      if (analyticsArgs) {
        handleGaEvent({
          category: analyticsArgs.category,
          label: analyticsArgs.label,
          action: `Reroute: ${callToAction.route}`,
        });
      }
      handleNavigate(callToAction.route);
      break;

    case "register":
      if (analyticsArgs) {
        handleGaEvent({
          category: analyticsArgs.category,
          label: analyticsArgs.label,
          action: `Register`,
        });
      }
      handleRegister();
      break;

    case "login":
      if (analyticsArgs) {
        handleGaEvent({
          category: analyticsArgs.category,
          label: analyticsArgs.label,
          action: `Login`,
        });
      }
      handleLogin();
      break;

    case "copyRouteToClipboard":
      if (analyticsArgs) {
        handleGaEvent({
          category: analyticsArgs.category,
          label: analyticsArgs.label,
          action: `Copy Route To Clipboard`,
        });
      }
      window.navigator.clipboard.writeText(window.location.href);
      break;

    case "copyTextToClipboard":
      if (analyticsArgs) {
        handleGaEvent({
          category: analyticsArgs.category,
          label: analyticsArgs.label,
          action: `Copy Text To Clipboard`,
        });
      }
      window.navigator.clipboard.writeText(callToAction.text);
      break;

    case "avatar":
      if (analyticsArgs) {
        handleGaEvent({
          category: analyticsArgs.category,
          label: analyticsArgs.label,
          action: `Claim Avatar`,
        });
      }
      handleClaimAvatar(callToAction.avatar);
      break;

    case "emoji":
      if (analyticsArgs) {
        handleGaEvent({
          category: analyticsArgs.category,
          label: analyticsArgs.label,
          action: `Claim Emoji`,
        });
      }
      handleClaimEmoji(callToAction.emoji);
      break;

    default:
      break;
  }
};

export { handleCallToAction };
