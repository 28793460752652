import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postLeaveLeague } from ".";
import { queryKeys } from "../shared/queryKeys";

export const usePostLeaveLeague = ({
  onSuccess,
  onError,
}: {
  payload?: {
    leagueId: string;
  };
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: { leagueId: string }) => postLeaveLeague(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.myLeagues(),
      });
      onSuccess?.();
    },
    onError: (error) => {
      onError?.(error);
    },
  });
};
