import { Button, Modal } from "@blastorg/portal-pattern-library";
import { Transition } from "@headlessui/react";
import cookies from "js-cookie";
import { useState } from "react";
import { useTimeout } from "usehooks-ts";
import { useAuthApi } from "../../../../../../../../api/ApiProvider/hooks";
import { useAuth } from "../../../../../../../../auth/AuthProvider/hooks/useAuth";
import { InputField } from "../../../../../../../../shared/components/InputField";
import { getBLASTApiError } from "../../../../../../shared/helpers/getBLASTApiError";
import { getDomain } from "./getDomain";

interface DeleteProfilePasswordConfirmationModalProps {
  closeModal: () => void;
  isOpen: boolean;
}
const DeleteProfileFlowModal = ({ closeModal, isOpen }: DeleteProfilePasswordConfirmationModalProps) => {
  const [password, setPassword] = useState("");
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [currentState, setCurrentState] = useState<"password" | "deleteProfile">("password");
  const [error, setError] = useState<string | null>(null);
  const [errorDeleteProfileModal, setErrorDeleteProfileModal] = useState<string | null>(null);
  const { checkPassword, deleteUser } = useAuthApi();
  const { userId } = useAuth();

  useTimeout(
    () => {
      setError(null);
      setPassword("");
      setCurrentState("password");
      setErrorDeleteProfileModal(null);
    },
    isOpen ? null : 250,
  );

  const isDisabled = !password;

  const submitPassword = async () => {
    setPasswordLoading(true);

    try {
      const checkedStatus = await checkPassword({
        password,
      });

      if (!checkedStatus) {
        setError("Password is incorrect");
        setPasswordLoading(false);
        return;
      }

      setCurrentState("deleteProfile");
      setPasswordLoading(false);
    } catch (error) {
      setPasswordLoading(false);
      const internalError = getBLASTApiError(error);
      if (internalError) {
        setError(internalError.message);
        return;
      }
      setError("Something went wrong. Please try again.");
    }
  };

  const deleteAccount = async () => {
    if (!userId) {
      setErrorDeleteProfileModal("Something went wrong. Please try again or contact support.");
      return;
    }

    try {
      await deleteUser(userId);
      cookies.remove("access_token", {
        domain: `.${getDomain()}`,
        path: "/",
      });
      cookies.remove("refresh_token", {
        domain: `.${getDomain()}`,
        path: "/",
      });
      cookies.remove("id_token", {
        domain: `.${getDomain()}`,
        path: "/",
      });
      window.location.href = "/";
    } catch (error) {
      const internalError = getBLASTApiError(error);
      if (internalError) {
        setErrorDeleteProfileModal(internalError.message);
        return;
      }
      setErrorDeleteProfileModal("An error occurred trying to delete your profile, please contact support.");
    }
  };
  return (
    <>
      <Modal onClose={closeModal} isOpen={isOpen} className="overflow-hidden rounded bg-canvas-90 p-7">
        <div className="w-full text-center">
          <span className="font-style-mobile-h3">Delete account</span>
        </div>
        <div className="relative h-52 md:w-80">
          <Transition
            show={currentState === "password"}
            leave="transition-all duration-default ease-in-out"
            leaveFrom="translate-x-0 opacity-100"
            leaveTo="-translate-x-10 opacity-0"
            className="absolute flex size-full flex-col items-center justify-between"
          >
            <div className="flex w-full flex-col pt-5 text-center">
              <span className="text-neutral-20 font-style-b3-body-copy">
                In order to verify the ownership of your account, please enter your current password.
              </span>
            </div>
            <div className="flex w-full gap-2">
              <InputField
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                required
                autoCapitalize="off"
              />
            </div>

            <div className="h-fit">
              <div className="flex w-full place-content-center gap-3 ">
                <Button variant="secondary" onClick={closeModal}>
                  Cancel
                </Button>
                <Button onClick={submitPassword} isDisabled={isDisabled} isLoading={passwordLoading}>
                  Proceed
                </Button>
              </div>

              <div className="h-4 pt-1">
                {error && <span className="text-red font-style-b3-body-copy">{error}</span>}
              </div>
            </div>
          </Transition>
          <Transition
            show={currentState === "deleteProfile"}
            enter="transition-all delay-default duration-default ease-in-out"
            enterFrom="h-0 translate-x-10 opacity-0"
            enterTo="translate-x-0 opacity-100"
            className="absolute w-full flex-col items-center"
          >
            <div className="flex h-52 flex-col justify-between pt-11">
              <div className="flex flex-col gap-2">
                <span className=" text-neutral-20 font-style-b2-body-copy">
                  The final step. If you’re absolutely sure you want to leave us, click confirm below.
                </span>
                <span className=" text-red font-style-b3-body-copy">Note: Your account cannot be recovered.</span>
              </div>
              <div className="">
                <div className="flex w-full place-content-center gap-3 ">
                  <Button variant="primary" onClick={deleteAccount}>
                    Delete account
                  </Button>
                  <Button variant="secondary" onClick={closeModal}>
                    Cancel
                  </Button>
                </div>

                <div className="h-4">
                  {errorDeleteProfileModal && (
                    <span className="truncate text-red font-style-b3-body-copy">{errorDeleteProfileModal}</span>
                  )}
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </Modal>
    </>
  );
};

export { DeleteProfileFlowModal };
