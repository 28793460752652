import styled, { css } from "styled-components";
import { colors } from "../../../designSystemLegacy/colors";
import { toRem } from "@blastorg/portal-pattern-library";
import { breakpoints } from "@blastorg/portal-pattern-library";
import { HiResImage } from "../HiResImage";

const fill = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const HeroWrapper = styled.div`
  padding: ${toRem(36)} ${toRem(36)} ${toRem(5)};
  @media (min-width: ${breakpoints.tablet}px) {
    padding: ${toRem(48)} ${toRem(48)} ${toRem(16)};
  }

  position: relative; // allows the background image to be absolute positioned

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
`;

export const BackgroundImage = styled(HiResImage)`
  ${fill}
  z-index: -1;
  object-fit: cover;
  object-position: center top;
`;

export const GradientOverlay = styled.div`
  ${fill}
  z-index: -1;
  background: linear-gradient(${colors.blastCanvas}00 0%, ${colors.blastCanvas}FF 100%);
`;
