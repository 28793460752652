import { Modal } from "@blastorg/portal-pattern-library";
import { Transition } from "@headlessui/react";
import { useState } from "react";
import { useTimeout } from "usehooks-ts";
import { ChangePassword } from "./components/ChangePassword";
import { ConfirmPassword } from "./components/ConfirmPassword";
interface DeleteProfilePasswordConfirmationModalProps {
  closeModal: () => void;
  isOpen: boolean;
}

const ChangePasswordModal = ({ closeModal, isOpen }: DeleteProfilePasswordConfirmationModalProps) => {
  const [currentState, setCurrentState] = useState<"password" | "changePassword">("password");
  const [passwordToConfirm, setPasswordToConfirm] = useState("");

  useTimeout(
    () => {
      setCurrentState("password");
      setPasswordToConfirm("");
    },
    isOpen ? null : 500,
  );

  return (
    <>
      <Modal onClose={closeModal} isOpen={isOpen} className="shadow-none">
        <div className="w-80">
          <Transition
            show={currentState === "password"}
            leave="transition-all duration-default ease-in-out"
            leaveFrom="translate-x-0 opacity-100"
            leaveTo="-translate-x-10 opacity-0"
            className="absolute inset-0 m-auto flex size-fit flex-col items-center justify-between rounded bg-canvas-90"
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
              e.stopPropagation();
            }}
          >
            <ConfirmPassword
              closeModal={closeModal}
              moveNext={(password) => {
                setCurrentState("changePassword");
                setPasswordToConfirm(password);
              }}
            />
          </Transition>
          <Transition
            show={currentState === "changePassword"}
            enter="transition-all delay-default duration-default ease-in-out"
            enterFrom="max-h-[0rem] translate-x-10 opacity-0"
            enterTo="translate-x-0 opacity-100"
            className="absolute inset-0 m-auto flex size-fit flex-col items-center justify-between rounded bg-canvas-90"
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
              e.stopPropagation();
            }}
          >
            <ChangePassword passwordToConfirm={passwordToConfirm} closeModal={closeModal} />
          </Transition>
        </div>
      </Modal>
    </>
  );
};

export { ChangePasswordModal };
