import { z } from "zod";
import { Series } from "../Series";
import { CircuitSchema } from "./circuit";
import { MapSchema } from "./map";
import { StageSchema } from "./stage";
import { mapTeamV2SchemaToTeam, TeamV2Schema } from "./team";
import { TournamentSchema } from "./tournament";

export const MatchV2Schema = z.object({
  id: z.string(),
  name: z.string(),
  scheduledAt: z.coerce.date(),
  type: z.string(),
  index: z.number(),
  circuit: CircuitSchema,
  tournament: TournamentSchema,
  stage: StageSchema,
  teamA: TeamV2Schema,
  teamB: TeamV2Schema,
  teamAScore: z.number(),
  teamBScore: z.number(),
  maps: z.array(MapSchema).nullable(),
});

export type MatchV2 = z.infer<typeof MatchV2Schema>;

export function mapMatchV2ToSeries(match: MatchV2): Series & Required<Pick<Series, "teamA" | "teamB">> {
  return {
    index: match.index,
    name: match.name,
    teamAScore: match.teamAScore,
    teamA: mapTeamV2SchemaToTeam(match.teamA),
    teamB: mapTeamV2SchemaToTeam(match.teamB),
    teamBScore: match.teamBScore,
    timeOfSeries: match.scheduledAt,
    uuid: match.id,
    tournament: {
      circuit: {
        uuid: match.circuit.id,
        name: match.circuit.name,
      },
      format: match.stage.format,
      name: match.stage.name,
      startDate: match.stage.startDate,
      uuid: match.stage.id,
      endDate: match.stage.endDate,
      parent: {
        circuit: {
          uuid: match.circuit.id,
          name: match.circuit.name,
        },
        format: "",
        name: match.tournament.name,
        startDate: match.tournament.startDate,
        uuid: match.tournament.id,
        endDate: match.tournament.endDate,
      },
    },
    type: match.type,
    maps:
      match.maps?.map((m) => ({
        uuid: m.id,
        name: m.name,
        actualStartTime: m.startedAt ?? undefined,
        scheduledStartTime: m.scheduledAt,
        matchEndedTime: m.endedAt ?? undefined,
        teamAScore: m.teamAScore,
        teamBScore: m.teamBScore,
      })) ?? undefined,
  };
}
