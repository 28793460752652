import { useMemo } from "react";
import { generateImageUrl } from "../../../../../api/data/assets/helpers/generateImageUrl";
import { useGetPurchases } from "../../../../../api/PointsApiClient/hooks/useGetPurchases";

export function HighEndRewards() {
  const { data: purchaseResponse, isLoading } = useGetPurchases();

  const filteredPurchases = useMemo(() => {
    if (!purchaseResponse?.purchases) {
      return [];
    }

    return purchaseResponse.purchases.filter((purchase) => purchase.productAction === "NONE");
  }, [purchaseResponse]);

  const randomLoading = useMemo(() => {
    // Generate random number between 1 and 5
    return Math.floor(Math.random() * 5) + 1;
  }, []);

  return (
    <div className="flex h-fit w-full flex-col gap-3 rounded-small bg-canvas-95 px-5 py-4">
      <div className="">
        <span className="font-style-label-3">Points Rewards</span>
      </div>
      <div className="h-px w-full bg-neutral-50/50" />
      <div className="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
        {filteredPurchases.map((product) => (
          <div key={product.id} className="flex flex-col place-content-center items-center pb-2 text-center">
            <img
              src={generateImageUrl("products", product.productId, {
                format: "auto",
                height: "200",
              })}
              className="h-20"
            />
            <span className="text-neutral-20 font-style-b3-body-copy">{product.productName}</span>
          </div>
        ))}
        {isLoading && filteredPurchases.length === 0
          ? Array.from({ length: randomLoading })?.map((_, index) => (
              <div
                key={index}
                className="flex cursor-pointer flex-col place-content-center items-center gap-3 rounded pb-2 text-center hover:bg-canvas-80"
              >
                <div className="h-20 w-16 animate-pulse rounded-small bg-neutral-50/20 py-2" />
                <div className="h-4 w-24 animate-pulse rounded-small bg-neutral-50/20" />
              </div>
            ))
          : null}
      </div>
      {!isLoading && filteredPurchases.length === 0 ? (
        <div className=" w-full items-center py-12 text-center">
          <span className="text-neutral-20 font-style-b3-body-copy">Earn Points and redeem rewards!</span>
        </div>
      ) : null}
    </div>
  );
}
