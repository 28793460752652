import { useEffect, useMemo, useRef, useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { useGestures } from "./hooks/useGestures";
import classNames from "classnames";
import { Map } from "@shared/constants/csMaps/types";
import { Controls } from "./components/Controls";
import pinPurpleSvg from "./assets/pin-purple.svg";
import pinWhiteSvg from "./assets/pin-white.svg";
import { Transition } from "@headlessui/react";
import { STATE_TRANSITION_DURATION } from "../../constants";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "@blastorg/portal-pattern-library";
import { CSGuessrPosition } from "../../../../../../../../api/data/csGuessr/shared/types";

interface MapPositionSelectorProps {
  show: boolean;
  map?: Map;
  correctPosition?: CSGuessrPosition;
  position?: CSGuessrPosition;
  onSetPosition: (position: CSGuessrPosition) => void;
  disabled?: boolean;
}

const MapPin = ({ position, isCorrectPosition }: { position?: CSGuessrPosition; isCorrectPosition?: boolean }) => {
  if (!position) return null;

  return (
    <img
      className={classNames(
        "pointer-events-none absolute z-20",
        "h-5 w-4",
        "-translate-x-1/2 -translate-y-full transition-all duration-200 ease-in-out",
      )}
      src={isCorrectPosition ? pinPurpleSvg : pinWhiteSvg}
      style={{ left: `${position.x}%`, top: `${position.y}%` }}
    />
  );
};

const MapPositionSelector = ({
  show,
  map: propMap,
  correctPosition,
  position,
  onSetPosition,
  disabled,
}: MapPositionSelectorProps) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const [hasUserInteracted, setHasUserInteracted] = useState(false);
  const [map, setMap] = useState<Map | undefined>(undefined);
  const { handleMouseDown, handleMouseUp } = useGestures({
    imageRef,
    onSetPosition,
    disabled,
  });

  useEffect(() => {
    if (!propMap) return;

    setMap(propMap);
  }, [propMap]);

  const angle = useMemo(() => {
    if (!position || !correctPosition) return undefined;

    return Math.atan2(correctPosition.y - position.y, correctPosition.x - position.x);
  }, [correctPosition, position]);

  const distance = useMemo(() => {
    if (!position || !correctPosition) return undefined;

    return Math.sqrt((correctPosition.y - position.y) ** 2 + (correctPosition.x - position.x) ** 2);
  }, [correctPosition, position]);

  const isTablet = useMediaQuery({ maxWidth: breakpoints.tablet });

  const imageSrc = useMemo(() => {
    if (!map) return undefined;

    return isTablet ? `${map.image}?width=500` : map.image;
  }, [map, isTablet]);

  if (!map) return null;

  return (
    <Transition
      show={show}
      enter="transition-all"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-all"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      style={{
        transitionDuration: `${STATE_TRANSITION_DURATION}ms`,
      }}
      className={classNames("absolute left-0 top-0 size-full overflow-hidden rounded-small", {
        "cursor-pointer": !disabled,
        "cursor-default": disabled,
      })}
    >
      <div className="relative size-full">
        <TransformWrapper
          maxScale={2.5}
          disablePadding={true}
          wheel={{
            smoothStep: 0.004,
          }}
          doubleClick={{
            disabled: true,
          }}
          onZoom={() => setHasUserInteracted(true)}
          onPanning={() => setHasUserInteracted(true)}
        >
          <Controls
            onInteraction={() => setHasUserInteracted(true)}
            className="absolute bottom-4 left-4 z-10 max-md:bottom-2 max-md:left-2"
          />
          <TransformComponent
            wrapperClass="relative"
            contentProps={{
              onMouseDown: handleMouseDown,
              onMouseUp: handleMouseUp,
            }}
          >
            {position && (
              <>
                <MapPin position={position} />
                <MapPin position={correctPosition} isCorrectPosition />
                {correctPosition && (
                  <>
                    {distance && angle && (
                      <div
                        className={classNames(
                          "pointer-events-none absolute z-10",
                          "border border-dashed border-white/50",
                        )}
                        style={{
                          transformOrigin: "top left",
                          width: `${distance}%`,
                          transform: `rotate(${angle}rad)`,
                          top: `${position.y}%`,
                          left: `${position.x}%`,
                        }}
                      />
                    )}
                  </>
                )}
              </>
            )}
            <img ref={imageRef} src={imageSrc} alt={map.title} className={classNames("w-full bg-canvas-95")} />
          </TransformComponent>
        </TransformWrapper>
        {!isTablet && (
          <Transition
            show={!hasUserInteracted && !position}
            enter="transition-opacity duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            as="span"
            className="absolute bottom-4 left-1/2 block -translate-x-1/2 whitespace-nowrap text-center text-neutral-0 font-style-label-3"
          >
            Place your location on the map
          </Transition>
        )}
      </div>
    </Transition>
  );
};

export { MapPositionSelector };
