import { CSGuessrGame } from "./components/CSGuessrGame";
import { CSGuessrGameProvider } from "./providers/CSGuessrGameProvider";
import { Footer } from "../../shared/components/Footer";

const CSGuessrLocalPage = () => {
  return (
    <CSGuessrGameProvider>
      <CSGuessrGame />
      <Footer />
    </CSGuessrGameProvider>
  );
};

export { CSGuessrLocalPage };
