import { useQuery } from "@tanstack/react-query";
import { usePointsApi } from "../../ApiProvider/hooks";

interface UseGetProductsParams {
  enabled?: boolean;
}

export const useGetProducts = (params?: UseGetProductsParams) => {
  const pointsApi = usePointsApi();
  return useQuery({
    queryKey: ["pointsProducts"],
    queryFn: pointsApi.getProducts,
    staleTime: 1000 * 60 * 5,
    enabled: params?.enabled,
  });
};
