import { useMediaQuery } from "react-responsive";
import { useCSGuessrGame } from "../../../../../hooks/useCSGuessrGame";
import checkSvg from "../../../assets/check.svg";
import crossSvg from "../../../assets/cross.svg";
import { breakpoints } from "@blastorg/portal-pattern-library";
import { Transition } from "@headlessui/react";
import { CSGuessrLocation, CSGuessrUserAnswer } from "../../../../../../../api/data/csGuessr/shared/types";

interface ResultCardProps {
  roundNumber: number;
  location: CSGuessrLocation;
  userAnswer: CSGuessrUserAnswer;
}

const ResultCard = ({ location, userAnswer, roundNumber }: ResultCardProps) => {
  const isMapPickCorrect = userAnswer.location.mapId === userAnswer.guess.mapId;

  const isTablet = useMediaQuery({ maxWidth: breakpoints.tablet });

  return (
    <Transition
      appear={true}
      show={true}
      enter="transition-all duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      style={{
        transitionDelay: `${roundNumber * 250}ms`,
      }}
      className="flex w-full max-w-[11rem] flex-col items-stretch gap-1 rounded-small text-neutral-20 font-style-label-3"
    >
      {!isTablet && <div className="bg-canvas-90 p-1 text-center">Round {roundNumber}</div>}
      <img
        src={location.image}
        alt={`location-${roundNumber}`}
        className="aspect-square w-full rounded-small object-cover"
      />
      {isTablet && <div className="bg-canvas-90 px-2 py-1 text-left">R{roundNumber}</div>}
      <div className="flex justify-between rounded-small bg-canvas-90 p-3 max-md:bg-canvas-95 max-md:px-2 max-md:py-1">
        <span>{isTablet ? "Map" : "Map Pick"}</span>
        <img src={isMapPickCorrect ? checkSvg : crossSvg} alt="map-pick" className="size-4" />
      </div>
      <div className="flex justify-between rounded-small bg-canvas-90 p-3 max-md:bg-canvas-95 max-md:px-2 max-md:py-1">
        <span>{isTablet ? "D" : "Distance"}</span>
        <span className="text-neutral-0">{isMapPickCorrect ? userAnswer.distance.toFixed(2) : "N/A"}</span>
      </div>
      <div className="flex justify-between rounded-small bg-canvas-90 p-3 max-md:bg-canvas-95 max-md:px-2 max-md:py-1">
        <span>Score</span>
        <span className="text-neutral-0">{userAnswer.score}</span>
      </div>
    </Transition>
  );
};

const ResultCardsContainer = () => {
  const { rounds, dailyScore } = useCSGuessrGame();
  const isTablet = useMediaQuery({ maxWidth: breakpoints.tablet });

  return (
    <div className="mx-auto flex w-fit gap-4 max-md:grid max-md:w-full max-md:grid-cols-3 max-md:gap-1">
      {rounds.map((round, index) => {
        if (!round.userAnswer) return null;

        return (
          <ResultCard key={index} roundNumber={index + 1} location={round.location} userAnswer={round.userAnswer} />
        );
      })}
      {isTablet && (
        <Transition
          appear
          show
          enter="transition-all duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          style={{
            transitionDelay: `${(rounds.length + 2) * 250}ms`,
          }}
          className="flex flex-col items-center justify-center rounded bg-canvas-90"
        >
          <span className="block text-neutral-20 font-style-label-4">Total Score</span>
          <span className="mt-1 block font-style-label-2">{dailyScore} / 500</span>
        </Transition>
      )}
    </div>
  );
};

export { ResultCardsContainer };
