import styled, { css } from "styled-components";
import { toRem, useBreakpoints } from "@blastorg/portal-pattern-library";
import { navigationHeightMobilePx, navigationHeightPx } from "../../styles/constants";
import { Center } from "../../shared/components/Center";
import { Loader } from "../../shared/components/Loader";

const flex = css`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CenterDesktop = styled.div`
  ${flex}
  margin-top: ${toRem(navigationHeightPx)};
  height: calc(100vh - ${toRem(navigationHeightPx)});
`;
const CenterMobile = styled.div`
  ${flex}
  margin-top: ${toRem(navigationHeightMobilePx)};
  height: calc(100vh - ${toRem(navigationHeightMobilePx)});
`;

const Wrapper = ({ children }: { children: React.ReactNode }) => {
  const { isMobile } = useBreakpoints();
  return isMobile ? <CenterMobile>{children}</CenterMobile> : <CenterDesktop>{children}</CenterDesktop>;
};

const LoadingPage = () => {
  return (
    <Wrapper>
      <Center>
        <Loader />
      </Center>
    </Wrapper>
  );
};

export { LoadingPage };
