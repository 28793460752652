import { BlurredBackground, ContentWrapper } from "./styled";
import InformationJson from "./assets/info.json";
import { Button, Typography } from "@blastorg/portal-pattern-library";

interface RandomInfoModalProps {
  onClose: () => void;
}

const RandomInfoModal = ({ onClose }: RandomInfoModalProps) => {
  const randomInfo = InformationJson.Strings[Math.floor(Math.random() * InformationJson.Strings.length)];

  return (
    <BlurredBackground>
      <ContentWrapper>
        <Typography variant="desktopH2" component="h5">
          Did you know...?
        </Typography>
        <Typography variant="b1BodyCopy" component="p">
          {randomInfo}
        </Typography>
        <Button onClick={onClose}>Wow!</Button>
      </ContentWrapper>
    </BlurredBackground>
  );
};

export { RandomInfoModal };
