import { Button, Modal } from "@blastorg/portal-pattern-library";
import { ChartBarSquareIcon, UserIcon } from "@heroicons/react/24/outline";
import { Icon } from "../../../../../../shared/components/Icon";

interface PointsExplainerModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const earnList = [
  {
    name: "Daily visit",
    value: "+10",
  },
  {
    name: "Daily visit x2",
    value: "+15",
  },
  {
    name: "Daily visit x3",
    value: "+20",
  },
  {
    name: "Participate in LIVE poll",
    value: "+50",
  },
  {
    name: "Points drop on live",
    value: "+100",
  },
  {
    name: "Points drop on live x2",
    value: "+120",
  },
  {
    name: "Points drop on live x3",
    value: "+140",
  },
  {
    name: "Playing Pick’ems",
    value: "+300",
  },
  {
    name: "Playing fantasy",
    value: "+400",
  },
  {
    name: "Play counter-strikle",
    value: "+50",
  },
  {
    name: "Win counter-strikle",
    value: "+50",
  },
];

const rewardList = [
  {
    name: "TEAM TAKEOVER",
  },
  {
    name: "HEAD TO HEAD POLL",
  },
  {
    name: "AVATAR/ EMOJI CASES",
  },
  {
    name: "BECOME A CHAT VIP",
  },
  {
    name: "EVEN MORE HIGH VALUE REWARDS COMING SOON!",
  },
];

export function PointsExplainerModal({ isOpen, onClose }: PointsExplainerModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} className="bg-canvas-100">
      <div className="absolute right-2 top-2">
        <Icon icon="closeCircleFilled" className="size-5 text-white" onClick={onClose} />
      </div>
      <div className="flex flex-col place-content-center items-center gap-5">
        <div className="flex place-content-center items-center p-3">
          <span className="flex items-center gap-3 font-style-desktop-h3">
            <Icon icon="blastCoin" className="size-7 text-yellow" />

            <span className="font-style-desktop-h3">BLAST.TV Points</span>
          </span>
        </div>
        <div className="grid w-full grid-cols-1 gap-3 md:grid-cols-2">
          <div className="flex w-full flex-col gap-4 rounded-small bg-canvas-95 p-3 md:w-64">
            <div className="flex flex-col place-content-center items-center gap-3">
              <UserIcon className="size-6 text-yellow" />

              <span className="font-style-label-2">Earn</span>
            </div>
            <div className="flex w-full place-content-center items-center">
              <span className="w-2/3 font-style-b3-body-copy">Here you can find ways to earn BLAST.tv points</span>
            </div>
            <div className=" text-neutral-50">
              {earnList.map((item) => (
                <div key={item.name} className="grid w-full grid-cols-4 text-neutral-50">
                  <span className="col-span-3 pb-2 text-left font-style-label-4">{item.name}</span>
                  <span className="col-span-1 pb-2 text-right font-style-label-4">{item.value}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="flex min-h-[20rem] w-full flex-col gap-4 rounded-small bg-canvas-95 p-3 md:w-64">
            <div className="flex flex-col place-content-center items-center gap-3">
              <ChartBarSquareIcon className="size-6 text-yellow" />

              <span className="font-style-label-2">Redeem</span>
            </div>
            <div className="flex w-full place-content-center items-center">
              <span className="w-2/3 font-style-b3-body-copy">This what you can spend your BLAST.tv points on!</span>
            </div>
            <div className="flex size-full flex-col justify-between text-neutral-50">
              {rewardList.map((item) => (
                <span key={item.name} className="col-span-4 text-left font-style-label-4">
                  {item.name}
                </span>
              ))}
            </div>
          </div>
        </div>
        <Button onClick={onClose}>Ok, got it!</Button>
      </div>
    </Modal>
  );
}
