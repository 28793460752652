import React from "react";

export function TikTokLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2164 0.77832C14.5794 3.61641 16.322 5.30847 19.3517 5.48847V8.68058C17.5959 8.83658 16.058 8.31456 14.2692 7.33053V13.3007C14.2692 20.885 5.17352 23.255 1.51677 17.8189C-0.833055 14.3208 0.605883 8.18256 8.1438 7.93655V11.3027C7.56955 11.3867 6.95569 11.5187 6.39464 11.6927C4.71808 12.2087 3.76759 13.1747 4.03161 14.8788C4.53986 18.1429 11.1273 19.1089 10.5794 12.7307V0.784321H14.2164V0.77832Z"
        fill="currentColor"
      />
    </svg>
  );
}
