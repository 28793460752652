import classNames from "classnames";
import { useEffect, useRef } from "react";
import { AdType, adData } from "./constants";
import { useVenatusAdManagerScript } from "./hooks/useVenatusAdManagerScript";

interface AdContainerProps {
  type: AdType;
  className?: string;
}

export const AdContainer = ({ type, className }: AdContainerProps) => {
  const adRef = useRef<HTMLDivElement>(null);
  useVenatusAdManagerScript();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const windowTop = window.top as any;
    if (!windowTop) return;

    const refCurrent = adRef.current as HTMLDivElement;

    windowTop.__vm_add = windowTop.__vm_add || [];
    windowTop.__vm_add.push(refCurrent);

    return () => {
      if (!windowTop) return;

      if (refCurrent.dataset.id == adData.richMedia.placementId) {
        // if Rich Media placement id, use this
        windowTop.__vm_remove_category = ["richmedia_all"];
      } else {
        // else remove normally
        windowTop.__vm_remove = windowTop.__vm_remove || [];
        windowTop.__vm_remove.push(refCurrent);
      }
    };
  }, []);

  return (
    <div className={classNames(className, adData[type].className)}>
      <div
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className="vm-placement"
        data-id={adData[type].placementId}
        data-display-type={adData[type].dataDisplayType}
        ref={adRef}
      />
    </div>
  );
};
