import { Button, Modal } from "@blastorg/portal-pattern-library";
import { useEffect, useMemo, useState } from "react";
import { useUserProfile } from "../../../../../../../../auth/useUserProfile";
import { z } from "zod";
import classNames from "classnames";
import { useAuthApi } from "../../../../../../../../api/ApiProvider/hooks";
import { InputField } from "../../../../../../../../shared/components/InputField";
import { getBLASTApiError } from "../../../../../../shared/helpers/getBLASTApiError";
import { Transition } from "@headlessui/react";
import { useTimeout } from "usehooks-ts";
import { ConfirmPassword } from "../../../PreferencesTab/components/ChangePasswordModal/components/ConfirmPassword";
export interface ChangeEmailFeedback {
  isError: boolean;
  message: string;
}

interface ChangeEmailModalProps {
  closeModal: () => void;
  isOpen: boolean;
}

const ChangeEmailModal = ({ isOpen, closeModal }: ChangeEmailModalProps) => {
  const userProfile = useUserProfile();
  const [email, setEmail] = useState(userProfile?.email || "");
  const { changeEmail } = useAuthApi();
  const [isLoading, setIsLoading] = useState(false);

  const [feedback, setFeedback] = useState<ChangeEmailFeedback | null>(null);

  const isDisabled = useMemo(() => {
    const emailSchema = z.string().email();
    const emailValidation = emailSchema.safeParse(email);

    if (!emailValidation.success) {
      return true;
    }

    if (email === userProfile?.email) {
      return true;
    }
  }, [email, userProfile?.email]);

  useEffect(() => {
    setFeedback(null);
  }, [email]);

  const submitEmail = async () => {
    const emailSchema = z.string().email();
    const emailValidation = emailSchema.safeParse(email);

    if (!emailValidation.success) {
      setFeedback({
        isError: true,
        message: "Email is not valid",
      });
      return;
    }
    setIsLoading(true);

    try {
      await changeEmail({
        newEmail: email,
      });
      setFeedback({
        isError: false,
        message: "Successfully sent confirmation email to your new email.",
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      const internalError = getBLASTApiError(e);

      if (internalError) {
        setFeedback({
          isError: true,
          message: internalError.message,
        });
        return;
      }

      setFeedback({
        isError: true,
        message: "Something went wrong. Please try again later.",
      });
    }
  };

  const [currentState, setCurrentState] = useState<"password" | "changeEmail">("password");

  useTimeout(
    () => {
      setCurrentState("password");
    },
    isOpen ? null : 500,
  );

  return (
    <>
      <Modal onClose={closeModal} isOpen={isOpen} className="shadow-none">
        <div className="w-80">
          <Transition
            show={currentState === "password"}
            leave="transition-all duration-default ease-in-out"
            leaveFrom="translate-x-0 opacity-100"
            leaveTo="-translate-x-10 opacity-0"
            className="absolute inset-0 m-auto flex size-fit flex-col items-center justify-between rounded bg-canvas-90"
          >
            <ConfirmPassword
              closeModal={closeModal}
              moveNext={() => {
                setCurrentState("changeEmail");
              }}
            />
          </Transition>
          <Transition
            show={currentState === "changeEmail"}
            enter="transition-all delay-default duration-default ease-in-out"
            enterFrom="max-h-0 translate-x-10 opacity-0"
            enterTo="translate-x-0 opacity-100"
            className="absolute inset-0 m-auto flex size-fit flex-col items-center justify-between rounded bg-canvas-90"
          >
            <div className="flex flex-col px-5 pt-6 md:w-96 ">
              <span className="font-style-desktop-h3">Change email</span>
              <div className="flex gap-2 pt-4 text-left">
                <InputField
                  autoFocus
                  type="email"
                  className="flex w-full justify-between rounded-small border border-neutral-50 bg-transparent px-3 py-2 text-white outline-none ring-0 focus:border focus:border-purple"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Button variant="default" isLoading={isLoading} isDisabled={isDisabled} onClick={submitEmail}>
                  Save
                </Button>
              </div>

              <span
                className={classNames("h-7 pt-2 text-left font-style-b2-body-copy", {
                  "text-red": feedback?.isError,
                  "text-green": !feedback?.isError,
                  "opacity-0": !feedback,
                })}
              >
                {feedback?.message}
              </span>
            </div>
          </Transition>
        </div>
      </Modal>
    </>
  );
};

export { ChangeEmailModal };
