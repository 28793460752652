import { z } from "zod";
import { GameIdSchema } from "@providers/GameIdProvider";

export const TeamSocialLinksSchema = z.record(
  z.enum(["twitter", "facebook", "instagram", "youtube", "twitch", "discord", "website"]),
  z.object({
    url: z.string().url(),
  }),
);

export const TeamSchema = z.object({
  uuid: z.string(),
  name: z.string(),
  shorthand: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  location: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  gameId: GameIdSchema.optional(),
  socialLinks: TeamSocialLinksSchema.default({}).optional(),
});

export type Team = z.infer<typeof TeamSchema>;
