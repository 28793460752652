import { z } from "zod";
import { UseQueryResult } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useEffect } from "react";
import { PickedPlayers } from "../types";
import { FantasyEvent } from "../../../../../api/data/sanity/shared/types/fantasy";
import { MyPicksSchema } from "../../../../../api/data/fantasy/getUserPicksDetailed";

export const useMatchApiDataWithPickedPlayersEffect = (
  fantasy: UseQueryResult<FantasyEvent>,
  myPicks: UseQueryResult<z.infer<typeof MyPicksSchema> | null>,
  setPickedPlayers: Dispatch<SetStateAction<PickedPlayers>>,
  setOverrideAutoState: Dispatch<SetStateAction<boolean>>,
) => {
  useEffect(() => {
    if (!fantasy.data?.season?.uid || !fantasy.data?.uid || !myPicks.data) {
      setOverrideAutoState(false);
      return;
    }

    const picks = myPicks.data.picks;
    const pickedPlayers: PickedPlayers = {};

    picks.forEach((pick) => {
      const goldPlayer = fantasy.data?.goldPlayers.find((p) => p.playerId === pick.playerId);
      const silverPlayer = fantasy.data?.silverPlayers.find((p) => p.playerId === pick.playerId);
      const bronzePlayer = fantasy.data?.bronzePlayers.find((p) => p.playerId === pick.playerId);
      const platinumPlayer = fantasy.data?.platinumPlayers.find((p) => p.playerId === pick.playerId);
      const diamondPlayer = fantasy.data?.diamondPlayers.find((p) => p.playerId === pick.playerId);

      if (goldPlayer) {
        pickedPlayers["gold"] = {
          ...goldPlayer,
          isCaptain: pick.isCaptain,
          details: pick,
        };
      }
      if (silverPlayer) {
        pickedPlayers["silver"] = {
          ...silverPlayer,
          isCaptain: pick.isCaptain,
          details: pick,
        };
      }
      if (bronzePlayer) {
        pickedPlayers["bronze"] = {
          ...bronzePlayer,
          isCaptain: pick.isCaptain,
          details: pick,
        };
      }
      if (platinumPlayer) {
        pickedPlayers["platinum"] = {
          ...platinumPlayer,
          isCaptain: pick.isCaptain,
          details: pick,
        };
      }
      if (diamondPlayer) {
        pickedPlayers["diamond"] = {
          ...diamondPlayer,
          isCaptain: pick.isCaptain,
          details: pick,
        };
      }
    });

    setPickedPlayers(pickedPlayers);
    setOverrideAutoState(false);
  }, [fantasy.data, myPicks.data, setPickedPlayers, setOverrideAutoState]);
};
