import { Button, Typography } from "@blastorg/portal-pattern-library";
import { ErrorResponse, isAxiosError } from "../../../../routes/FantasyJoinPage/types";
import { usePostLeaveLeague } from "../../../../../../api/data/fantasy/postLeaveLeague/usePostLeaveLeague";
import { useNavigate } from "react-router-dom";

interface LeaveLeagueProps {
  setOpen: (open: boolean) => void;
  leagueId: string;
}

export function LeaveLeagueModal({ setOpen, leagueId }: LeaveLeagueProps) {
  const navigate = useNavigate();

  const parseError = (error: unknown) => {
    if (isAxiosError<ErrorResponse>(error)) {
      return error.response?.data?.message;
    } else {
      return "An unknown error occurred.";
    }
  };

  const { mutate, isError, isPending, error } = usePostLeaveLeague({
    onSuccess: () => {
      setOpen(false);
      navigate("/fantasy");
    },
  });

  const leaveLeague = async () => {
    mutate({
      leagueId,
    });
  };
  return (
    <div className="flex flex-col gap-6">
      <div className=" mt-3 sm:mt-5">
        <Typography variant="desktopH3" className="text-center">
          Are you sure?
        </Typography>
      </div>
      <Typography variant="b2BodyCopy" color="neutral10" className="text-center">
        You can always re-join the league if you wish!
      </Typography>
      <div className="flex place-content-center items-center gap-5">
        <Button variant="tertiary" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button isLoading={isPending} onClick={() => leaveLeague()} variant="secondary">
          Exit league
        </Button>
      </div>
      {isError && (
        <div className="flex w-full place-content-center">
          <Typography variant="b2BodyCopy" color="red">
            {parseError(error)}
          </Typography>
        </div>
      )}
    </div>
  );
}
