import { z } from "zod";
import { blastApi } from "../../../apiClients/blastApi";

export const ClipSchema = z.object({
  matchId: z.string(),
  eventId: z.string(),
  eventType: z.string(),
  src: z.string(),
  harvestJobStatus: z.string(),
  isDownloadable: z.boolean().default(false),
});

export type Clip = z.infer<typeof ClipSchema>;

export const getVideoClip = async ({ eventId }: { eventId: string }) => {
  const { data } = await blastApi.get<unknown>(`/v1/videos/clips/${eventId}`);
  return ClipSchema.parse(data);
};
