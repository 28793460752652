import { z } from "zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UserPickSchema, putUserPicks } from ".";
import { queryKeys } from "../shared/queryKeys";

interface PutUserPicksPayload {
  seasonId: string;
  eventId: string;
  userPicks: z.infer<typeof UserPickSchema>[];
}

export const usePutUserPicks = ({
  payload,
  onSuccess,
  onError,
}: {
  payload?: PutUserPicksPayload;
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: putUserPicks,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.myPicksDetailed({
          seasonId: payload?.seasonId,
          eventId: payload?.eventId,
        }),
      });
      onSuccess?.();
    },
    onError: (error) => {
      onError?.(error);
    },
  });
};
