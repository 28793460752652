export const queryKeys = {
  all: ["tournaments"] as const,
  byTournamentTitleAndShortSeriesId: ({ shortSeriesId }: { shortSeriesId?: string }) =>
    [...queryKeys.all, shortSeriesId] as const,
  tournamentById: (id?: string) => [...queryKeys.all, id] as const,
  tournamentsByCircuitId: (id: string, game?: string) => [...queryKeys.all, "circuit", id, "game", game] as const,
  rocketLeagueTournamentById: (id?: string) => [...queryKeys.all, id] as const,
  seriesByTournamentId: (tournamentId?: string) => [...queryKeys.tournamentById(tournamentId), "series"] as const,
  bracketsByTournamentId: (tournamentId?: string) => [...queryKeys.tournamentById(tournamentId), "brackets"] as const,
  series: () => [...queryKeys.all, "series"] as const,
  seriesById: (id?: string) => [...queryKeys.series(), id] as const,
  teams: () => [...queryKeys.all, "teams"] as const,
  teamById: (id?: string) => [...queryKeys.teams(), id] as const,
  teamPlayersById: (id?: string) => [...queryKeys.teams(), id] as const,
  teamBySlug: (slug?: string) => [...queryKeys.teams(), slug] as const,
  teamTransferHistory: (params: { teamId?: string; fromDate: string }) =>
    [...queryKeys.teams(), "transferHistory", params] as const,
  teamAwards: (params: { teamId: string }) => [...queryKeys.teams(), "awards", params] as const,
  playersByTeamId: (teamId?: string) => [...queryKeys.teamById(teamId), "players"] as const,
  vetoBySeriesId: (seriesId?: string) => [...queryKeys.seriesById(seriesId), "veto"] as const,
  allTournaments: () => [...queryKeys.all] as const,
  matchHistoryByTeamId: (teamId?: string, from?: Date, to?: Date) =>
    [...queryKeys.teamById(teamId), "match-history", from?.toISOString(), to?.toISOString()] as const,
  bySeriesId: ({ seriesId }: { seriesId?: string }) => [...queryKeys.all, seriesId] as const,
  getPlayerByShortId: ({ playerId }: { playerId?: string }) => [...queryKeys.all, playerId] as const,
  getPlayerConfig: (playerId?: string) => [...queryKeys.all, playerId, "config"] as const,
  getPlayerVideoDefaults: (playerId?: string) => [...queryKeys.all, playerId, "videodefaults"] as const,
  getPlayerVideoConfig: (playerId?: string) => [...queryKeys.all, playerId, "videoconfig"] as const,
  getPlayerHistory: ({ playerId }: { playerId?: string }) => [...queryKeys.all, playerId] as const,
  getPlayerAwards: ({ playerId }: { playerId?: string }) => [...queryKeys.all, playerId, "awards"] as const,
  rlcsLeaderboard: (regionalTournamentId: string, stageId: string, date?: string) =>
    [...queryKeys.all, "rl", "leaderboards", regionalTournamentId, stageId, date] as const,
  rlcsPointsByTournament: (regionalTournamentId: string, teamId: string) =>
    [...queryKeys.all, "rl", "leaderboards", regionalTournamentId, "points", teamId] as const,
};
