import classNames from "classnames";

const TrophyIcon = ({ className }: { className?: string }) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames("size-6", className)}
  >
    <path
      id="Union"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2.53125V6.93125C6 9.14039 7.79086 10.9312 10 10.9312C12.2091 10.9312 14 9.14039 14 6.93125V2.53125H6ZM4 3.53125H2V4.53125C2 5.76118 2.85401 6.79165 4.0014 7.062C4.00047 7.01853 4 6.97494 4 6.93125V3.53125ZM4.00711 1.53125H1.4C0.626801 1.53125 0 2.15805 0 2.93125V4.53125C0 7.0097 1.9601 9.03035 4.41472 9.12758C5.1736 11.0559 6.90225 12.4964 9 12.8483L9 18.5312H4.4C3.84772 18.5312 3.4 18.979 3.4 19.5312C3.4 20.0835 3.84772 20.5312 4.4 20.5312L15.6 20.5313C16.1523 20.5313 16.6 20.0835 16.6 19.5313C16.6 18.979 16.1523 18.5313 15.6 18.5313H11L11 12.8483C13.0978 12.4964 14.8264 11.0559 15.5853 9.12758C18.0399 9.03034 20 7.0097 20 4.53125V2.93125C20 2.15805 19.3732 1.53125 18.6 1.53125H15.9929C15.9297 0.968593 15.4523 0.53125 14.8727 0.53125H5.12727C4.54773 0.53125 4.07033 0.968593 4.00711 1.53125ZM18 4.53125C18 5.76118 17.146 6.79164 15.9986 7.062C15.9995 7.01853 16 6.97495 16 6.93125V3.53125H18V4.53125Z"
      fill="#FFFE3E"
    />
  </svg>
);

export { TrophyIcon };
