import { captureException } from "@sentry/browser";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useUsersApi } from "../../api/ApiProvider/hooks";
import { useAuthModal } from "../../auth/AuthModalProvider";
import { AuthContext } from "../../auth/AuthProvider";
import { CallToAction } from "../../api/data/sanity/types/CallToAction";
import { handleCallToAction } from "../helpers/handleCallToAction";

const useCallToAction = () => {
  const authContext = useContext(AuthContext);
  const authModal = useAuthModal();

  const usersApi = useUsersApi();
  const navigate = useNavigate();

  return ({
    callToAction,
    analyticsArgs,
    onSuccess,
  }: {
    callToAction?: CallToAction;
    analyticsArgs?: {
      category: string;
      label: string;
    };
    onSuccess?: () => void;
  }) =>
    handleCallToAction({
      callToAction,
      analyticsArgs,
      handleLogin: () => {
        if (!authContext?.isAuthenticated) {
          authModal?.openModal("login");
        }
      },
      handleRegister: () => {
        if (!authContext?.isAuthenticated) {
          authModal?.openModal("register");
        }
      },
      handleNavigate: navigate,
      handleClaimAvatar: async (avatarId) => {
        if (!authContext?.isAuthenticated) {
          authModal?.openModal("login");
          onSuccess?.();
          return;
        }

        const userId = authContext?.userId;
        if (!userId) {
          captureException(`Couldn't find user id in auth context`);
          throw new Error("Couldn't find user id in auth context");
        }

        try {
          await usersApi.claimAvatar({ userId, avatarId });
          onSuccess?.();
        } catch (error) {
          captureException(`Failed to claim avatar with id: ${avatarId}. Error: ${error}`);
          throw new Error(`Failed to claim avatar with id: ${avatarId}. Error: ${error}`);
        }
      },
      handleClaimEmoji: async (emojiId) => {
        if (!authContext?.isAuthenticated) {
          authModal?.openModal("login");
          onSuccess?.();
          return;
        }

        const userId = authContext?.userId;
        if (!userId) {
          captureException(`Couldn't find user id in auth context`);
          throw new Error("Couldn't find user id in auth context");
        }

        try {
          await usersApi.claimEmoji({ userId, emojiId });
          onSuccess?.();
        } catch (error) {
          captureException(`Failed to claim emoji with id: ${emojiId}. Error: ${error}`);
          throw new Error(`Failed to claim emoji with id: ${emojiId}. Error: ${error}`);
        }
      },
    });
};

export { useCallToAction };
