import { generateTeamLogoSrc } from "@data/tournament/shared/helpers/generateTeamLogoSrc";
import { useUserProfile } from "../../../../../../../auth/useUserProfile";
import { useFetchTeams } from "@data/tournament/getTeams/useFetchTeams";
import { GameId } from "@providers/GameIdProvider";

export const FavoriteTeamsForGame = ({ game }: { game: GameId }) => {
  const { data, isLoading, isError } = useFetchTeams(game);
  const userProfile = useUserProfile();

  const favouriteGameTeams = (data || []).filter((team) => userProfile?.favoriteTeams?.includes(team.uuid));

  if (isError) {
    return (
      <span className="w-full text-center text-white/80 font-style-b2-body-copy">Oops, teams did not load {":("}</span>
    );
  }
  if (!isLoading && favouriteGameTeams?.length === 0) {
    return (
      <span className="w-full text-center text-white/80 font-style-b2-body-copy">
        No favorite teams selected {":("}
      </span>
    );
  }

  return favouriteGameTeams.map(({ uuid }, index) => (
    <img
      key={index}
      className="size-16"
      src={generateTeamLogoSrc({ id: uuid, height: 64, width: 64 })}
      alt="Team Logo"
    />
  ));
};
