import { Gender } from "../../../api/UsersApiClient/types";

export function getGenderDisplayName(gender: Gender): string {
  switch (gender) {
    case "MALE":
      return "Male";
    case "FEMALE":
      return "Female";
    case "NON_BINARY":
      return "Non-binary";
    case "PREFER_NOT_TO_SAY":
      return "Prefer not to say";
    default: {
      const exhaustCheck: never = gender;
      throw new Error(`Unexpected gender: ${exhaustCheck}`);
    }
  }
}
