import { Avatar } from "../../../../../../types/Avatar";
import { ProfileAvatar } from "./components/ProfileAvatar";
import { BackgroundImage } from "./media/BackgroundImage";

interface ProfileHeaderProps {
  avatar?: Avatar | null;
  username: string;
  avatarImageUrl: string;
  onClickChangeAvatar: () => void;
}

const ProfileHeader = (props: ProfileHeaderProps) => {
  return (
    <div className="relative h-80 w-full overflow-hidden lg:h-96">
      <BackgroundImage
        className="size-full"
        style={{
          color: props.avatar?.color || "#000000",
        }}
      />
      <ProfileAvatar {...props} />
      <div className="absolute bottom-0 h-52 w-full bg-gradient-to-t from-canvas-100 to-transparent" />
    </div>
  );
};

export { ProfileHeader };
