import { z } from "zod";
import { blastApi } from "../../../apiClients/blastApi";

export const UserPickSchema = z.object({
  category: z.enum(["diamond", "platinum", "gold", "silver", "bronze"]),
  playerId: z.string().uuid(),
  playerName: z.string(),
  isCaptain: z.boolean(),
});

export const putUserPicks = async ({
  seasonId,
  eventId,
  userPicks,
}: {
  seasonId: string;
  eventId: string;
  userPicks: z.infer<typeof UserPickSchema>[];
}) => {
  const { data } = await blastApi.put(`/v1/fantasy/seasons/${seasonId}/events/${eventId}/picks`, userPicks);
  return UserPickSchema.array().parse(data);
};
