import { breakpoints } from "@blastorg/portal-pattern-library";
import classNames from "classnames";
import { useCSGuessrGame } from "../../../../../../../../hooks/useCSGuessrGame";
import { useEffect, useMemo, useState } from "react";
import checkSvg from "../../../../../../assets/check.svg";
import crossSvg from "../../../../../../assets/cross.svg";
import { Transition } from "@headlessui/react";
import { STATE_TRANSITION_DURATION } from "../../../../constants";
import { useMediaQuery } from "react-responsive";
import { NextRoundButton } from "../../../NextRoundButton";
import { CSGuessrUserAnswer } from "../../../../../../../../../../api/data/csGuessr/shared/types";

const ResultsState = ({ show }: { show: boolean }) => {
  const { currentRound, maps } = useCSGuessrGame();

  const [answer, setAnswer] = useState<CSGuessrUserAnswer | undefined>(undefined);

  useEffect(() => {
    if (!currentRound?.userAnswer) return;

    setAnswer(currentRound.userAnswer);
  }, [currentRound?.userAnswer]);

  const answerMap = useMemo(() => {
    if (!answer) return undefined;

    return maps.find((map) => map.id === answer?.location.mapId);
  }, [answer, maps]);

  const isCorrectMap = useMemo(() => {
    if (!answer) return false;

    return answer.guess.mapId === answer.location.mapId;
  }, [answer]);

  const isTablet = useMediaQuery({ maxWidth: breakpoints.tablet });

  return (
    <Transition
      show={show}
      leave="absolute left-0 top-0 transition-all"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className="absolute left-0 top-0 mt-4 flex w-full flex-col max-md:my-0"
    >
      <Transition.Child
        enter="transition-all delay-[400ms]"
        enterFrom="-translate-y-4 opacity-0"
        enterTo="translate-y-0 opacity-100"
        className="flex justify-between rounded-small bg-canvas-95 p-5 max-md:p-3"
        style={{
          transitionDuration: `${STATE_TRANSITION_DURATION}ms`,
        }}
      >
        <span className="block text-neutral-20 font-style-label-3">Map</span>
        <span
          className={classNames("block font-style-label-3", {
            "text-green": isCorrectMap,
            "text-red": !isCorrectMap,
          })}
        >
          {isCorrectMap ? (
            <img className="mr-2 inline-block align-bottom" src={checkSvg} alt="Correct map" />
          ) : (
            <img className="mr-2 inline-block align-bottom" src={crossSvg} alt="Incorrect map" />
          )}
          {isCorrectMap ? answerMap?.title : `IT'S ${answerMap?.title}`}
        </span>
      </Transition.Child>
      <Transition.Child
        enter="transition-all delay-[600ms]"
        enterFrom="-translate-y-4 opacity-0"
        enterTo="translate-y-0 opacity-100"
        className="mt-1 flex justify-between rounded-small bg-canvas-95 p-5 max-md:p-3"
        style={{
          transitionDuration: `${STATE_TRANSITION_DURATION}ms`,
        }}
      >
        <span className="block text-neutral-20 font-style-label-3">Distance</span>
        <span className="block text-neutral-0 font-style-label-3">
          {isCorrectMap ? answer?.distance.toFixed(2) : isTablet ? "N/A" : "On another planet"}
        </span>
      </Transition.Child>
      <Transition.Child
        enter="transition-all delay-[800ms]"
        enterFrom="-translate-y-4 opacity-0"
        enterTo="translate-y-0 opacity-100"
        className="mt-1 flex justify-between rounded-small bg-canvas-95 p-5 max-md:p-3"
        style={{
          transitionDuration: `${STATE_TRANSITION_DURATION}ms`,
        }}
      >
        <span className="block text-neutral-20 font-style-label-3">Score</span>
        <span className="block text-neutral-0 font-style-label-3">{answer?.score}</span>
      </Transition.Child>
      {!isTablet && (
        <Transition.Child
          enter="transition-all delay-[1200ms] duration-500 ease-in-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          className="mt-5"
          style={{
            transitionDuration: `${STATE_TRANSITION_DURATION * 2}ms`,
          }}
        >
          <NextRoundButton />
        </Transition.Child>
      )}
    </Transition>
  );
};

export { ResultsState };
