import { Outlet } from "react-router-dom";
import { FantasyProvider } from "../../../../context/useFantasy";

export function LeaderboardLayout() {
  return (
    <FantasyProvider>
      <div className="mx-auto my-0">
        <div className="m-nav">
          <Outlet />
        </div>
      </div>
    </FantasyProvider>
  );
}
