import { useCallback, useContext, useRef, useState } from "react";
import { GameNavItem } from "./components/GameNavItem";
import { GameIdContext } from "@providers/GameIdProvider";

export const GameNav = ({ onExpanded, onCollapsed }: { onExpanded: () => void; onCollapsed: () => void }) => {
  const [expanded, setExpanded] = useState(false);
  const { gameId, enabledGames: enabledGameIds } = useContext(GameIdContext);

  const timeoutId = useRef<NodeJS.Timeout | null>(null);

  const expand = useCallback(() => {
    onExpanded();
    if (timeoutId.current) clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => setExpanded(true), 500);
  }, [onExpanded]);

  const collapse = useCallback(() => {
    onCollapsed();
    if (timeoutId.current) clearTimeout(timeoutId.current);
    setExpanded(false);
  }, [onCollapsed]);

  if (gameId === null) {
    return (
      <div className="flex gap-3">
        {enabledGameIds.map((gameId) => (
          <GameNavItem gameId={gameId} key={gameId} />
        ))}
      </div>
    );
  }

  return (
    <div className="flex gap-3" onMouseEnter={expand} onMouseLeave={collapse} onClick={collapse}>
      <GameNavItem gameId={gameId} selected />
      {expanded && (
        <>
          {enabledGameIds
            .filter((id) => id !== gameId)
            .map((gameId) => (
              <GameNavItem gameId={gameId} key={gameId} />
            ))}
        </>
      )}
    </div>
  );
};
