import classNames from "classnames";
import { useControls } from "react-zoom-pan-pinch";
import plusSvg from "./assets/plus.svg";
import minusSvg from "./assets/minus.svg";
import { useCallback } from "react";

interface ControlsProps {
  className?: string;
  onInteraction?: () => void;
}

const ZoomButton = ({ onClick, children }: { onClick: () => void; children: React.ReactNode }) => (
  <button
    className={classNames(
      "size-7 cursor-pointer rounded-full border-none bg-transparent p-0 outline-none",
      "disabled:cursor-default disabled:opacity-50",
      "opacity-90 hover:opacity-100",
      "transition-all duration-200 ease-in-out",
    )}
    onClick={onClick}
  >
    {children}
  </button>
);

const Controls = ({ className, onInteraction }: ControlsProps) => {
  const { zoomIn, zoomOut } = useControls();

  const handleZoomIn = useCallback(() => {
    zoomIn(0.25);
    onInteraction?.();
  }, [zoomIn, onInteraction]);

  const handleZoomOut = useCallback(() => {
    zoomOut(0.25);
    onInteraction?.();
  }, [zoomOut, onInteraction]);

  return (
    <div className={classNames(className, "flex flex-col gap-2")}>
      <ZoomButton onClick={handleZoomIn}>
        <img className="w-full object-contain" src={plusSvg} alt="Zoom in" />
      </ZoomButton>
      <ZoomButton onClick={handleZoomOut}>
        <img className="w-full object-contain" src={minusSvg} alt="Zoom out" />
      </ZoomButton>
    </div>
  );
};

export { Controls };
