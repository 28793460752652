import { Image } from "../../../api/data/sanity/types/Image";
import { breakpoints } from "@blastorg/portal-pattern-library";
import { useBreakpoints } from "@blastorg/portal-pattern-library";
import { GradientOverlay, HeroWrapper, BackgroundImage } from "./styled";

function useHeroImageWidth() {
  const { isTablet, isDesktop, isLargeDesktop } = useBreakpoints();

  let width = breakpoints.mobile;
  if (isLargeDesktop) {
    width = breakpoints.largeDesktop;
  } else if (isDesktop) {
    width = breakpoints.desktop;
  } else if (isTablet) {
    width = breakpoints.tablet;
  }

  return width;
}

function Background({ image }: { image: Image }) {
  return (
    <>
      <BackgroundImage image={image} width={useHeroImageWidth()} />
      <GradientOverlay />
    </>
  );
}

function Hero({ children }: { children?: React.ReactNode }) {
  return <HeroWrapper>{children}</HeroWrapper>;
}

Hero.Background = Background;
export { Hero };
