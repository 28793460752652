import { ReactNode, createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useGetUserAnswers } from "../../../../api/data/csGuessr/getUserAnswers/useGetUserAnswers";
import { Map } from "@shared/constants/csMaps/types";
import { cs2Maps } from "@shared/constants/csMaps";
import { usePostUserAnswer } from "../../../../api/data/csGuessr/postUserAnswer/postUserAnswer";
import { Round, State } from "./types";
import { useAuth } from "../../../../auth/AuthProvider/hooks/useAuth";
import { useCallToAction } from "../../../../shared/hooks/useCallToAction";
import {
  CSGuessrLocation,
  CSGuessrPosition,
  CSGuessrUserAnswer,
  PostUserAnswerParams,
} from "../../../../api/data/csGuessr/shared/types";
import { useLocation } from "react-router-dom";

export interface CSGuessrGameValue {
  isLoading: boolean;
  isSubmittingAnswer: boolean;
  locations: CSGuessrLocation[];
  rounds: { location: CSGuessrLocation; userAnswer?: CSGuessrUserAnswer }[];
  currentRoundIndex?: number;
  currentRound?: Round;
  submitUserAnswer: () => void;
  state: State;
  maps: Map[];
  selectedMap?: Map;
  selectMap: (map: Map | undefined) => void;
  goToNextRound: () => void;
  selectedPosition?: CSGuessrPosition;
  setSelectedPosition: (position: CSGuessrPosition | undefined) => void;
  isHowToPlayOpen: boolean;
  openHowToPlay: () => void;
  closeHowToPlay: () => void;
  dailyScore: number;
}

const mockedLocations: CSGuessrLocation[] = [
  {
    id: "e9ece50e-ed6b-43ff-aed9-4df6e790f20a",
    image: "https://assets.blast-dev.tv/images/cs-guessr/locations/e9ece50e-ed6b-43ff-aed9-4df6e790f20a",
  },
  {
    id: "ff79a04c-bd1e-43b1-820e-69562a5b1001",
    image: "https://assets.blast-dev.tv/images/cs-guessr/locations/ff79a04c-bd1e-43b1-820e-69562a5b1001",
  },
  {
    id: "07bc08d3-b570-4ec5-9e8c-dc8759b1972b",
    image: "https://assets.blast-dev.tv/images/cs-guessr/locations/07bc08d3-b570-4ec5-9e8c-dc8759b1972b",
  },
  {
    id: "fb5b388f-3570-4ab9-938c-ab9b4d8913a5",
    image: "https://assets.blast-dev.tv/images/cs-guessr/locations/fb5b388f-3570-4ab9-938c-ab9b4d8913a5",
  },
  {
    id: "cecf222b-3bf2-4330-8374-bca07902af4f",
    image: "https://assets.blast-dev.tv/images/cs-guessr/locations/cecf222b-3bf2-4330-8374-bca07902af4f",
  },
];

const mockedHardLocations: CSGuessrLocation[] = [
  {
    id: "33ab5ab4-94fe-4afa-a761-dc1233f5613c",
    image: "https://assets.blast-dev.tv/images/cs-guessr/locations/33ab5ab4-94fe-4afa-a761-dc1233f5613c",
  },
  {
    id: "22676ead-8593-4be7-bba1-2427350bb046",
    image: "https://assets.blast-dev.tv/images/cs-guessr/locations/22676ead-8593-4be7-bba1-2427350bb046",
  },
  {
    id: "3268db8d-ab1a-4329-ad8b-dfb65e7982a8",
    image: "https://assets.blast-dev.tv/images/cs-guessr/locations/3268db8d-ab1a-4329-ad8b-dfb65e7982a8",
  },
  {
    id: "44ac8b65-7d77-4c63-bcfe-2594ac12187e",
    image: "https://assets.blast-dev.tv/images/cs-guessr/locations/44ac8b65-7d77-4c63-bcfe-2594ac12187e",
  },
  {
    id: "286eaf22-f109-43b5-9c8c-7891b2b0851f",
    image: "https://assets.blast-dev.tv/images/cs-guessr/locations/286eaf22-f109-43b5-9c8c-7891b2b0851f",
  },
];

const mockedSuperHardLocations: CSGuessrLocation[] = [
  {
    id: "3dabb853-9377-4c11-b2bf-9d15209dfeac",
    image: "https://assets.blast-dev.tv/images/cs-guessr/locations/3dabb853-9377-4c11-b2bf-9d15209dfeac",
  },
  {
    id: "a336f5f0-b947-4ca4-88bd-dbe71ea37f08",
    image: "https://assets.blast-dev.tv/images/cs-guessr/locations/a336f5f0-b947-4ca4-88bd-dbe71ea37f08",
  },
  {
    id: "0aec7ae6-cf50-496f-b4e5-fc806932e163",
    image: "https://assets.blast-dev.tv/images/cs-guessr/locations/0aec7ae6-cf50-496f-b4e5-fc806932e163",
  },
  {
    id: "ce554362-4577-425a-99ca-189c3978de2c",
    image: "https://assets.blast-dev.tv/images/cs-guessr/locations/ce554362-4577-425a-99ca-189c3978de2c",
  },
  {
    id: "b708636c-2cba-43c4-9d43-66a3b277ce6f",
    image: "https://assets.blast-dev.tv/images/cs-guessr/locations/b708636c-2cba-43c4-9d43-66a3b277ce6f",
  },
];

export const CSGuessrGameContext = createContext<CSGuessrGameValue | undefined>(undefined);

const useCSGuessrGameValue = (): CSGuessrGameValue => {
  const auth = useAuth();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const level = queryParams.get("level");
  const locations =
    level === "hard" ? mockedHardLocations : level === "hell" ? mockedSuperHardLocations : mockedLocations;

  const {
    data: userAnswers,
    isLoading: isFetchingUserAnswers,
    refetch: refetchUserAnswers,
  } = useGetUserAnswers({ enabled: auth.isAuthenticated });
  const [rounds, setRounds] = useState<Round[]>([]);
  const [state, setState] = useState<State>("GAME_ENDED");
  const [currentRoundIndex, setCurrentRoundIndex] = useState<number | undefined>(undefined);
  const [selectedMap, setSelectedMap] = useState<Map | undefined>(undefined);
  const [selectedPosition, setSelectedPosition] = useState<CSGuessrPosition | undefined>(undefined);
  const handleCallToAction = useCallToAction();
  const [isHowToPlayOpen, setIsHowToPlayOpen] = useState(false);
  const { mutate: mutateSubmitUserAnswer, isPending: isSubmittingUserAnswer } = usePostUserAnswer({
    onSuccess: (answer) => {
      setRounds((prev) => {
        const newRounds = [...prev];
        for (const round of newRounds) {
          if (round.location.id === answer.location.id) {
            round.userAnswer = answer;
          }
        }

        return newRounds;
      });
    },
  });

  const allRoundsAnswered = useMemo(() => rounds.every((round) => round.userAnswer !== undefined), [rounds]);

  const currentRound = useMemo(() => {
    if (!rounds.length || currentRoundIndex === undefined) return undefined;

    return rounds[currentRoundIndex];
  }, [rounds, currentRoundIndex]);

  const submitUserAnswer = useCallback(() => {
    if (!selectedMap || !selectedPosition || !currentRound) return;

    const params: PostUserAnswerParams = {
      locationId: currentRound.location.id,
      guessMapId: selectedMap.id,
      guessPosition: selectedPosition,
    };

    mutateSubmitUserAnswer(params);
  }, [currentRound, mutateSubmitUserAnswer, selectedMap, selectedPosition]);

  useEffect(() => {
    if (!locations) return;

    const newUserAnswers = auth.isAuthenticated ? userAnswers : [];
    const rounds = locations.map((location) => ({
      location,
      userAnswer: newUserAnswers?.find((answer) => answer.location.id === location.id),
    }));

    setRounds(rounds);

    const currentRoundIndex = rounds.findIndex((round) => round.userAnswer === undefined);
    setCurrentRoundIndex(currentRoundIndex);
  }, [locations, userAnswers, auth.isAuthenticated]);

  useEffect(() => {
    if (allRoundsAnswered && currentRoundIndex !== rounds.length - 1) {
      setState("GAME_ENDED");
      return;
    }

    if (!selectedMap) {
      setState("SELECTING_MAP");
    } else if (currentRound?.userAnswer) {
      setState("SHOWING_ANSWER");
    } else {
      setState("SELECTING_POSITION");
    }
  }, [rounds, currentRound?.userAnswer, selectedMap, allRoundsAnswered, currentRoundIndex]);

  const goToNextRound = useCallback(() => {
    if (allRoundsAnswered) {
      setState("GAME_ENDED");
    } else {
      setSelectedMap(undefined);
      setSelectedPosition(undefined);
      setCurrentRoundIndex((prev) => (prev !== undefined ? prev + 1 : undefined));
      setState("SELECTING_MAP");
    }
  }, [setSelectedMap, setSelectedPosition, setCurrentRoundIndex, setState, allRoundsAnswered]);

  useEffect(() => {
    if (auth.isAuthenticated) {
      refetchUserAnswers();
    } else {
      setSelectedMap(undefined);
      setSelectedPosition(undefined);
      setState("SELECTING_MAP");
    }
  }, [auth.isAuthenticated, refetchUserAnswers, setSelectedMap, setSelectedPosition, setState]);

  const selectMap = useCallback(
    (map: Map | undefined) => {
      if (!auth.isAuthenticated) {
        return handleCallToAction({
          callToAction: {
            type: "register",
          },
        });
      } else {
        setSelectedMap(map);
      }
    },
    [setSelectedMap, auth.isAuthenticated, handleCallToAction],
  );

  const dailyScore = useMemo(() => {
    return rounds.reduce((acc, round) => {
      return acc + (round.userAnswer?.score ?? 0);
    }, 0);
  }, [rounds]);

  return {
    isLoading: auth.isAuthenticated && isFetchingUserAnswers,
    isSubmittingAnswer: isSubmittingUserAnswer,
    locations: locations ?? [],
    rounds,
    currentRoundIndex,
    currentRound,
    submitUserAnswer,
    selectedPosition,
    setSelectedPosition,
    goToNextRound,
    state,
    maps: cs2Maps,
    selectMap,
    selectedMap,
    dailyScore,
    isHowToPlayOpen,
    openHowToPlay: () => setIsHowToPlayOpen(true),
    closeHowToPlay: () => setIsHowToPlayOpen(false),
  };
};

const CSGuessrGameProvider = ({ children }: { children: ReactNode }) => {
  const csGuessrGameProviderValue = useCSGuessrGameValue();

  return <CSGuessrGameContext.Provider value={csGuessrGameProviderValue}>{children}</CSGuessrGameContext.Provider>;
};

export { CSGuessrGameProvider };
