import { useMemo } from "react";

export const useArePicksLocked = (fantasyDataEndTime?: string) => {
  return useMemo(() => {
    const now = new Date();
    const lockDate = new Date(fantasyDataEndTime || "");

    return now > lockDate;
  }, [fantasyDataEndTime]);
};
