import { SeriesData } from "../../../api/TournamentsDataBucketApiClient/types/SeriesData";

export type SeriesState = "pre" | "live" | "post";

export const getSeriesState = ({ series }: { series: SeriesData | undefined }): SeriesState | undefined => {
  if (!series) return undefined;

  let requiredMapsToWin = 0;
  switch (series.type) {
    case "BO1":
      requiredMapsToWin = 1;
      break;
    case "BO2":
      requiredMapsToWin = 1;
      break;
    case "BO3":
      requiredMapsToWin = 2;
      break;
    case "BO5":
      requiredMapsToWin = 3;
      break;
    default:
      requiredMapsToWin = 1;
      break;
  }
  const teamAScore = series.teamAScore;
  const teamBScore = series.teamBScore;

  if (teamAScore === requiredMapsToWin || teamBScore === requiredMapsToWin) {
    return "post";
  }

  const liveMaps = series.maps?.filter(
    (map) => map.actualStartTime !== undefined && (map.matchEndedTime === null || map.matchEndedTime === undefined),
  );
  if (liveMaps && liveMaps?.length > 0) return "live";

  return "pre";
};
