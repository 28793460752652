import { useQuery } from "@tanstack/react-query";
import { useFantasyDataBucketApi } from "../../../../../api/ApiProvider/hooks";

export const useFetchPlayerPickRates = ({
  seasonId,
  eventId,
  enabled,
}: {
  calculationSchemaId?: string;
  seasonId?: string;
  eventId?: string;
  enabled?: boolean;
}) => {
  const fantasyDataBucket = useFantasyDataBucketApi();
  return useQuery({
    queryKey: ["fantasyPlayerPickRates", seasonId, eventId],
    queryFn: seasonId && eventId ? () => fantasyDataBucket.getPlayerPickRates({ seasonId, eventId }) : () => null,
    enabled,
  });
};
