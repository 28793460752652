import styled from "styled-components";
import { colors } from "../../../../../designSystemLegacy/colors";
import logo from "../../assets/logo.svg";
import logoAnimated from "../../assets/logo-animated.gif";
import { Button, toRem, Typography } from "@blastorg/portal-pattern-library";
import { maxWidthContentFrame } from "../../../../../styles/constants";
import { zIndex } from "../../../../../styles/zIndex";

export const NavContent = styled.div`
  display: flex;
  height: 100%;
  max-width: ${maxWidthContentFrame}px;
  margin: 0 auto;
  padding: 0 1.5rem;
  align-items: center;
  justify-content: space-between;
  z-index: ${zIndex.navbar};
  position: relative;
`;

export const Logo = styled.div<{
  isActive?: boolean;
  isTablet?: boolean;
}>`
  width: ${toRem(30)};
  height: ${({ isTablet }) => (isTablet ? toRem(36) : "100%")};
  background: url("${logo}") no-repeat center;
  background-size: contain;
  border-bottom: 2px solid transparent;
  border-top: 2px solid transparent;

  ${({ isActive }) => isActive && `border-bottom: 2px solid ${colors.blastYellow};`}

  @media (hover: hover) {
    &:hover {
      background-image: url("${logoAnimated}");
    }
  }
`;

export const LoginButton = styled.button`
  letter-spacing: 1rem;
  background-color: transparent;
  color: ${colors.blastCanvas90};
  border: none;
  height: 100%;
  padding: ${toRem(8)} ${toRem(16)};
  cursor: pointer;
  transition: all 0.25s ease;

  &:hover {
    color: ${colors.white};
  }
`;

export const LoginText = styled(Typography)`
  letter-spacing: 0.1rem;
  text-transform: uppercase;
`;

export const RegisterButton = styled(Button)`
  padding: ${toRem(4)} ${toRem(16)};
  min-height: 2.25rem;
`;
