import { useCallback, useContext, useRef, useState } from "react";
import { useAuth } from "../../../../../auth/AuthProvider/hooks/useAuth";
import { IconButton } from "../../../Icon";
import { MobileNavDrawer as LegacyMobileNavDrawer } from "../../../LegacyMobileNavDrawer";
import { Profile } from "../Profile";
import { NavbarItem as NavbarItemType } from "../../../../../api/data/sanity/getSettings/schemas/NavbarSchema";
import { BurgerIconWrapper, Logo, Nav, NavContent, StyledNavBarLogoItem } from "./styled";
import { MobileNavDrawer } from "./components/MobileNavDrawer";
import { GameId, GameIdContext } from "@providers/GameIdProvider";

export const NavBarMobile = ({
  nav,
  isAutomaticLiveEnabled = false,
}: {
  nav?: { [key in GameId]: NavbarItemType[] };
  isAutomaticLiveEnabled?: boolean;
}) => {
  const { isAuthenticated, logout } = useAuth();
  const [isExpanded, setIsExpanded] = useState(false);
  const navbarRef = useRef<HTMLDivElement | null>(null);

  const toggleDrawer = useCallback(() => {
    setIsExpanded((current) => !current);
  }, []);

  const gameContext = useContext(GameIdContext);

  return (
    <Nav>
      <NavContent ref={navbarRef}>
        <BurgerIconWrapper>
          <IconButton
            icon={isExpanded ? "crossCircleFilledWhite" : "burgerMenu"}
            onClick={toggleDrawer}
            label="Toggle navigation"
          />
        </BurgerIconWrapper>
        {!isExpanded && (
          <StyledNavBarLogoItem>
            <Logo isTablet={true} />
          </StyledNavBarLogoItem>
        )}
        <Profile
          isTablet={true}
          isDrawerExpanded={isExpanded}
          isAuthenticated={isAuthenticated}
          onOpenModal={toggleDrawer}
        />
      </NavContent>
      {gameContext.enabledGames.length > 1 && (
        // Dota or Rocket League is enabled - use the new nav with game selection
        <MobileNavDrawer
          isExpanded={isExpanded}
          isSignedIn={isAuthenticated}
          nav={nav}
          onClose={toggleDrawer}
          navbarRef={navbarRef}
          onLogout={() => {
            logout();
            toggleDrawer();
          }}
          isAutomaticLiveEnabled={isAutomaticLiveEnabled}
        />
      )}
      {gameContext.enabledGames.length === 1 && (
        // Only CS is enabled - use the legacy nav
        <LegacyMobileNavDrawer
          isExpanded={isExpanded}
          isSignedIn={isAuthenticated}
          navItems={nav?.cs || []}
          onClose={toggleDrawer}
          navbarRef={navbarRef}
          onLogout={() => {
            logout();
            toggleDrawer();
          }}
          isAutomaticLiveEnabled={isAutomaticLiveEnabled}
        />
      )}
    </Nav>
  );
};
