import { useEffect } from "react";
import { useFetchSyntheticPage } from "./hooks/useFetchGhostPage";
import { useMeta } from "../MetaProvider/useMeta";

interface AddGhostPageProps {
  pathname: string;
}

export function AddGhostPage({ pathname }: AddGhostPageProps) {
  const { data } = useFetchSyntheticPage();
  const { updateMetaTags } = useMeta();

  useEffect(() => {
    // Check if the current pathname is in the list of synthetic pages
    if (!data) return;

    if (data.length) {
      const relevantPage = data.find((page) => page.route === pathname);
      const relevantPageMetaTagsInfo = relevantPage?.metaTagsInfo;
      if (relevantPageMetaTagsInfo && relevantPageMetaTagsInfo.metaTitle) {
        updateMetaTags(relevantPageMetaTagsInfo.metaTitle, false, relevantPageMetaTagsInfo);
      }
    }
  }, [data, pathname, updateMetaTags]);

  return null;
}
