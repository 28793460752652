import headerLogoSvg from "./assets/logo.svg";
import { useCSGuessrGame } from "../../../../hooks/useCSGuessrGame";
import { Button, breakpoints } from "@blastorg/portal-pattern-library";
import { useMediaQuery } from "react-responsive";
import { CountDownTimer } from "./components/CountDownTimer";
import classnames from "classnames";

const CSGuessrHeader = () => {
  const { state, openHowToPlay } = useCSGuessrGame();

  const isBiggerThanTablet = useMediaQuery({ minWidth: breakpoints.tablet });

  return (
    <div className={classnames("flex flex-col items-center justify-center overflow-hidden py-6 max-md:py-4")}>
      <img className="h-20 object-contain max-md:h-12" src={headerLogoSvg} alt="CS-Guessr" />
      {isBiggerThanTablet && state !== "GAME_ENDED" && (
        <Button variant="tertiary" onClick={openHowToPlay} className="mt-4">
          How To Play
        </Button>
      )}
      {state === "GAME_ENDED" && <CountDownTimer className="mt-8 max-md:mt-4" />}
    </div>
  );
};

export { CSGuessrHeader };
