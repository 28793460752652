import { useQuery } from "@tanstack/react-query";
import { getBracketByTournamentId } from ".";
import { queryKeys } from "../queryKeys";
import { GameId } from "@providers/GameIdProvider";

function useFetchTournamentBrackets({
  tournamentId,
  enabled,
  retry,
  gameId = "cs",
}: {
  tournamentId: string;
  enabled?: boolean;
  retry?: boolean;
  gameId?: GameId;
}) {
  return useQuery({
    queryKey: queryKeys.bracketsByTournamentId(tournamentId),
    queryFn: () => getBracketByTournamentId(tournamentId, gameId),
    enabled,
    retry,
    staleTime: 5000,
  });
}

export { useFetchTournamentBrackets };
