import { Button, Modal } from "@blastorg/portal-pattern-library";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import { Gender } from "../../../../../../../../api/UsersApiClient/types";
import { useAuth } from "../../../../../../../../auth/AuthProvider/hooks/useAuth";
import { useUserProfile } from "../../../../../../../../auth/useUserProfile";
import { rudderstack } from "../../../../../../../../rudderstack";
import { countries } from "../../../../../../../../shared/helpers/countries";
import { getGenderDisplayName } from "../../../../../../helpers/getGenderDisplayName";
import { useUpdateProfile } from "../../../../../../hooks/useUpdateProfile";
import { DateField } from "./components/DateField";
import { DisabledInputField } from "./components/DisabledInputField";
import { SelectorField } from "./components/SelectorField";

type EditDetailsModalProps = {
  closeModal: () => void;
  isOpen: boolean;
  onEmailChangeOpen: () => void;
};

const subtractYears = (years: number) => {
  return DateTime.local().minus({ years: years });
};

const genderOptions: {
  text: string;
  value: Gender;
}[] = [
  { text: getGenderDisplayName("MALE"), value: "MALE" },
  { text: getGenderDisplayName("FEMALE"), value: "FEMALE" },
  {
    text: getGenderDisplayName("NON_BINARY"),
    value: "NON_BINARY",
  },
  {
    text: getGenderDisplayName("PREFER_NOT_TO_SAY"),
    value: "PREFER_NOT_TO_SAY",
  },
];

const EditDetailsModal = ({ closeModal, isOpen, onEmailChangeOpen }: EditDetailsModalProps) => {
  const { userId } = useAuth();
  const userProfile = useUserProfile();

  const { mutate: updateUserProfile, isPending } = useUpdateProfile({
    userId: userProfile?.id,
  });

  const [birthDate, setBirthDate] = useState(userProfile?.birthDate ?? new Date());
  const [gender, setGender] = useState(userProfile?.gender ?? "MALE");
  const [country, setCountry] = useState(userProfile?.country ?? "DK");

  const countryOptions = useMemo(() => {
    return countries
      .map((country) => ({
        text: country.name,
        value: country.code,
      }))
      .sort((a, b) => a.text.localeCompare(b.text));
  }, []);

  const countryToDisplay = useMemo(() => {
    const con = countries.find((con) => con.code === country);

    return con?.name ?? "";
  }, [country]);

  const handleEditProfile = () => {
    if (!userId) {
      return;
    }

    updateUserProfile({ gender, country, birthDate });
    rudderstack?.identify(userId, {
      username: userProfile?.username,
      email: userProfile?.email,
      gender: gender,
      country: country,
      birthday: DateTime.fromJSDate(birthDate).toFormat("yyyy-MM-dd"),
      favoriteTeams: userProfile?.favoriteTeams,
    });
    closeModal();
  };

  if (!userProfile) {
    return null;
  }

  return (
    <Modal onClose={closeModal} isOpen={isOpen} className="max-w-[28rem] bg-canvas-90">
      <h3 className="pt-5 font-style-desktop-h3">Edit Details</h3>
      <div className="my-7 grid grid-cols-2 gap-6">
        <DisabledInputField title={"Username"} value={userProfile.username} />

        <DisabledInputField
          title={"Email"}
          value={userProfile.email}
          shouldShowEditButton={true}
          onEdit={onEmailChangeOpen}
        />

        <div className="col-span-2 md:col-span-1">
          <SelectorField title={"Country"} value={countryToDisplay} setValue={setCountry} options={countryOptions} />
        </div>

        <div className="col-span-2 md:col-span-1">
          <DateField
            title={"Birthday"}
            minDate={subtractYears(100).toJSDate()}
            maxDate={subtractYears(13).toJSDate()}
            value={birthDate}
            setValue={setBirthDate}
            disabled={false}
          />
        </div>
        <div className="col-span-2 size-full">
          <SelectorField
            title={"Gender"}
            value={getGenderDisplayName(gender)}
            setValue={setGender}
            options={genderOptions}
          />
        </div>
      </div>
      <div className="flex w-full place-content-center">
        <Button isLoading={isPending} onClick={handleEditProfile}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

export { EditDetailsModal };
