import { AxiosError } from "axios";
import { z } from "zod";
const InternalErrorSchema = z.object({
  message: z.string(),
  code: z.string(),
});
export function getBLASTApiError(e: unknown) {
  if (e instanceof AxiosError) {
    const error = e.response?.data;
    const data = InternalErrorSchema.safeParse(error);
    if (data.success) {
      return data.data;
    }
  }
  return null;
}
