import { useQuery } from "@tanstack/react-query";
import { usePointsApi } from "../../ApiProvider/hooks";

export const useGetPurchases = () => {
  const pointsApi = usePointsApi();
  return useQuery({
    queryKey: ["pointsPurchases"],
    queryFn: pointsApi.getPointsPurchases,
    staleTime: 1000 * 60 * 5,
  });
};
