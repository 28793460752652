import { z } from "zod";
import { UseQueryResult } from "@tanstack/react-query";
import { createContext } from "react";
import { TeamMap } from "../../helpers/formatBracketData/types";
import { AllPlayers, CurrentState, PickedPlayers } from "./types";
import { Series } from "../../../../api/data/tournament/shared/types/Series";
import { Category } from "../../types/Category";
import { FantasyEvent, FantasyPlayer } from "../../../../api/data/sanity/shared/types/fantasy";
import { FantasyPlayerPickRates } from "../../../../api/FantasyDataBucketApiClient/schemas";
import { MyPicksSchema } from "../../../../api/data/fantasy/getUserPicksDetailed";

export interface FantasyProviderValue {
  fantasyQuery: UseQueryResult<FantasyEvent>;
  myPicksQuery: UseQueryResult<z.infer<typeof MyPicksSchema> | null>;
  methods: {
    handlePlayerClick: (player: FantasyPlayer, category: string) => void;
    handleRemovePlayer: (category: Category) => void;
    handleChangeTempCaptain: (player: FantasyPlayer | null) => void;
    handlePlayersSubmit: () => void;
    handleEditCaptain: () => void;
    handleEditPicks: () => void;
    handleMobilePlayerClick: (player: FantasyPlayer) => void;
    handleMobilePlayerClose: () => void;
  };
  values: {
    pickRates: FantasyPlayerPickRates | null;
    pickedPlayers: PickedPlayers;
    pickedTeams: string[];
    tournamentTeamsData: TeamMap | undefined;
    arePicksLocked: boolean;
    currentState: CurrentState;
    tempChosenCaptain: FantasyPlayer | null;
    isSubmitting: boolean;
    overrideAutoState: boolean;
    allPlayers: AllPlayers;
    matches: Record<string, Series>;
    mobilePlayer: FantasyPlayer | null;
    tournamentInProgress: boolean | undefined;
  };
}

export const FantasyContext = createContext<FantasyProviderValue | undefined>(undefined);
