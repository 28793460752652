import { Button } from "@blastorg/portal-pattern-library";
import { useCSGuessrGame } from "../../../../../../hooks/useCSGuessrGame";
import classnames from "classnames";
import { useCallback, useMemo, useState } from "react";
import { DateTime } from "luxon";

const CS_GUESSR_RELEASE_DATE: DateTime | undefined = DateTime.fromISO("2023-09-01T00:00:00.000Z");

const getCsGuesserNumber = (): number => {
  // days from release date (utc)
  const today = DateTime.utc();

  const version = Math.floor(today.diff(CS_GUESSR_RELEASE_DATE, "days").days) + 1;

  return version;
};

const ShareButton = ({ className }: { className?: string }) => {
  const { rounds } = useCSGuessrGame();
  const [isCopied, setIsCopied] = useState(false);

  const shareText = useMemo(() => {
    if (!rounds.every((round) => !!round.userAnswer)) return "";

    let text = `CS Guessr ${getCsGuesserNumber()}\n`;
    let totalScore = 0;
    for (const index in rounds) {
      const round = rounds[index];

      if (!round.userAnswer) continue;

      text += `\n📍 Round ${Number(index) + 1}: [`;

      // add based on score ▮ or ▯: i.e. if score is 75: ▮▮▮▮▮▮▮▯▯▯
      const score = round.userAnswer.score;
      totalScore += score;
      const scoreString = "▮".repeat(Math.floor(score / 10)) + "▯".repeat(10 - Math.floor(score / 10));
      text += `${scoreString}] ${score} ${score ? "✅" : "❌"}`;
    }

    text += `\n\nScore: ${totalScore} / 500`;

    if (totalScore > 350) {
      text += " 🔥";
    }

    text += `\n\n${
      window.location.href.includes("blast.tv") ? "https://blast.tv/cs-guessr" : "https://blast-dev.tv/cs-guessr"
    }`;

    return text;
  }, [rounds]);

  const handleClick = useCallback(() => {
    navigator.clipboard.writeText(shareText);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 4000);
  }, [shareText]);

  return (
    <Button variant={isCopied ? "success" : "default"} className={classnames(className)} onClick={handleClick}>
      {isCopied ? "Results copied!" : "Share your results"}
    </Button>
  );
};

export { ShareButton };
