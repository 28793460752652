import { z } from "zod";
import { PointsProductSchema } from "../schemas";

export const PointsPurchaseSchema = z.object({
  id: z.string().uuid(),
  userId: z.string(),
  purchaseDate: z.string().datetime(),
  productId: z.string(),
  productName: PointsProductSchema.shape.name,
  productAction: PointsProductSchema.shape.action,
  productPrice: PointsProductSchema.shape.price,
  transactionId: z.string(),
});

export type PointsPurchase = z.infer<typeof PointsPurchaseSchema>;

export const PointsPurchaseResponseSchema = z.object({
  purchases: PointsPurchaseSchema.array().optional(),
  winner: z.string().optional(),
  resultList: z.array(z.string()).optional(),
});
