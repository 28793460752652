import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../shared/queryKeys";
import { getFantasySeasons } from ".";

export const useFetchFantasySeasons = () => {
  return useQuery({
    queryKey: queryKeys.seasons(),
    queryFn: () => getFantasySeasons(),
  });
};
