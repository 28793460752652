import { useMutation, useQueryClient } from "@tanstack/react-query";
import { usePointsApi } from "../../ApiProvider/hooks";
import { useAuth } from "../../../auth/AuthProvider/hooks/useAuth";
import { PurchaseRequestBody } from "../schemas";
import { useAlert } from "../../../providers/AlertProvider/hooks/useAlert";
import { getBLASTApiError } from "../../../routes/ProfilePage/shared/helpers/getBLASTApiError";
import { queryKeys } from "../../UsersApiClient/queryKeys";

interface UsePurchaseProductProps {
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
}

const usePurchaseProduct = ({ onSuccess, onError }: UsePurchaseProductProps) => {
  const pointsApi = usePointsApi();
  const { userId } = useAuth();
  const alert = useAlert();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: { id: string; body: PurchaseRequestBody }) => pointsApi.purchaseProduct(data.id, data.body),
    onSuccess() {
      if (userId) {
        queryClient.invalidateQueries({
          queryKey: ["userWallet", userId],
        });
        queryClient.invalidateQueries({
          queryKey: ["pointsProducts"],
        });
        queryClient.invalidateQueries({
          queryKey: ["pointsPurchases"],
        });
        queryClient.invalidateQueries({ queryKey: queryKeys.all });
      }

      onSuccess?.();
    },
    onError(error) {
      const apiErrorResult = getBLASTApiError(error);

      queryClient.invalidateQueries({
        queryKey: ["pointsProducts"],
      });

      if (apiErrorResult) {
        alert.showFailureAlert(apiErrorResult.message);
        return;
      } else {
        alert.showFailureAlert("Failed to purchase product, please try again later");
      }
      onError?.(error);
    },
  });
};

export { usePurchaseProduct };
