import { useQuery } from "@tanstack/react-query";
import { getVideoClip } from ".";

export const useGetVideoClip = (eventId?: string) => {
  return useQuery({
    queryKey: ["videoClip", eventId],
    queryFn: eventId ? () => getVideoClip({ eventId }) : undefined,
    enabled: !!eventId,
  });
};
