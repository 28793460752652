import { environment } from "../../../config";

export const handleGaEvent = ({ category, label, action }: { category: string; label: string; action: string }) => {
  if (environment !== "development" && environment !== "production") {
    // GA is only enabled in production and development and not local
    return;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.dataLayer.push({
    event: "Tracking Event", // Default tag in GTM
    "Event Category": category,
    "Event Action": action,
    "Event Label": label,
  });
};

export const trackInternalNavigation = ({
  category,
  label,
  route,
}: {
  category: string;
  label: string;
  route: string;
}) => {
  handleGaEvent({
    category,
    label,
    action: `Reroute: ${route}`,
  });
};
