import { createClient } from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";
import { Image } from "../../data/sanity/types/Image";
import { environment, sanityDataset } from "../../../config";
import { clientWithBudget } from "./clientWithBudget";

const sanityClient = createClient({
  // Find your project ID and dataset in `sanity.json` in your studio project
  projectId: "6znhzi10",
  dataset: sanityDataset ?? "production",
  apiVersion: "2023-05-31", // use the current UTC date - see "specifying API version"!
  useCdn: environment === "production", // Gives fast, cheap responses using a globally distributed cache.
  // Set this to false if your application requires the freshest possible
  // data always (potentially slightly slower and a bit more expensive).
});

const builder = imageUrlBuilder(sanityClient);

export const urlFor = (source: Image) => {
  return builder.image(source);
};

export const client = clientWithBudget(sanityClient);
