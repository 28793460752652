import { Button } from "@blastorg/portal-pattern-library";
import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useMemo, useState } from "react";
import { z } from "zod";
import { InputField } from "../../../../../../../../../shared/components/InputField";
import { useAuthApi } from "../../../../../../../../../api/ApiProvider/hooks";
import { getBLASTApiError } from "../../../../../../../shared/helpers/getBLASTApiError";
interface DeleteProfilePasswordConfirmationModalProps {
  closeModal: () => void;
  passwordToConfirm: string;
}

const ChangePassword = ({ closeModal, passwordToConfirm }: DeleteProfilePasswordConfirmationModalProps) => {
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { changePassword } = useAuthApi();

  const containsEightCharacters = useMemo(() => {
    const passwordSchema = z.string().min(8);
    const passwordStatus = passwordSchema.safeParse(password);

    return passwordStatus.success;
  }, [password]);

  const passwordsMatch = useMemo(() => {
    return password === repeatPassword && password.length > 0;
  }, [password, repeatPassword]);

  const isDisabled = useMemo(() => {
    return !containsEightCharacters || !passwordsMatch;
  }, [containsEightCharacters, passwordsMatch]);

  const handleChangingPassword = async () => {
    setIsLoading(true);
    try {
      await changePassword({
        previousPassword: passwordToConfirm,
        newPassword: password,
      });
      setIsLoading(false);
      closeModal();
    } catch (error) {
      setIsLoading(false);
      const internalError = getBLASTApiError(error);

      if (internalError) {
        setError(internalError.message);
        return;
      }
      setError("Something went wrong. Please try again.");
    }
  };

  return (
    <div className="flex w-screen flex-col gap-7 p-7 text-left md:w-96">
      <div className="w-full pt-5 text-center">
        <span className="font-style-mobile-h3">Change Password</span>
      </div>

      <div className="flex w-full flex-col gap-2">
        <label htmlFor="password" className="font-style-label-3">
          New Password
        </label>
        <InputField
          type="password"
          placeholder="Enter your password"
          value={password}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
          required
          autoCapitalize="off"
        />
      </div>
      <div className="flex w-full flex-col gap-2">
        <label htmlFor="password" className="font-style-label-3">
          Repeat Password
        </label>
        <InputField
          type="password"
          placeholder="Enter your password"
          value={repeatPassword}
          onChange={(event) => {
            setRepeatPassword(event.target.value);
          }}
          required
          autoCapitalize="off"
        />
      </div>
      <div className="flex w-full flex-col gap-5 rounded-small bg-canvas-95 py-4 text-center">
        <span className="font-style-label-2">Password Requirements</span>
        <div className="flex flex-col gap-2">
          <div className="flex w-full items-center gap-2 px-6">
            {containsEightCharacters ? (
              <CheckIcon className="size-4 text-green" />
            ) : (
              <XMarkIcon className="size-4 text-red" />
            )}
            <span className="font-style-b2-body-copy">Contains at least 8 characters</span>
          </div>
          <div className="flex w-full items-center gap-2 px-6">
            {passwordsMatch ? <CheckIcon className="size-4 text-green" /> : <XMarkIcon className="size-4 text-red" />}
            <span className="font-style-b2-body-copy">Passwords match</span>
          </div>
        </div>
      </div>
      <div>
        <div className="flex w-full place-content-center items-center gap-2">
          <Button isDisabled={isDisabled} onClick={handleChangingPassword} isLoading={isLoading}>
            Change password
          </Button>
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
        </div>
        <div className="h-4">{error && <span className="truncate text-red font-style-b3-body-copy">{error}</span>}</div>
      </div>
    </div>
  );
};

export { ChangePassword };
