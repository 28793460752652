import { blastAssetsApi } from "../../../apiClients/blastAssetsApi";

interface Emoji {
  id: string;
  name: string;
  orderIndex: number;
  isUnlockOnly?: boolean;
  code: string;
  hidden?: boolean;
  score?: number;
  isUnlocked?: boolean;
}

export const getEmojis = async (): Promise<Emoji[]> => {
  const { data } = await blastAssetsApi.get(`/emojis/index.json`);
  return data;
};
