import { useContext, useState } from "react";
import { FavoriteTeamsModal } from "./components/FavoriteTeamsModal";
import { GameId, GameIdContext } from "@providers/GameIdProvider";
import { GameIcon } from "@shared/components/GameIcon";
import classNames from "classnames";
import { FavoriteTeamsForGame } from "./components/FavoriteTeamsForGame";
import { useUserProfile } from "../../../../../../auth/useUserProfile";

const FavoriteTeamsSection = () => {
  const [showFavoriteTeamsModal, setShowFavoriteTeamsModal] = useState(false);
  const userProfile = useUserProfile();
  const { enabledGames } = useContext(GameIdContext);

  // select the users preferred game (if it is enabled), otherwise default to the first enabled game
  const preferredGame = userProfile?.preferredGames?.[0];

  const [selectedGame, setSelectedGame] = useState<GameId>(
    enabledGames.find((game) => game === preferredGame) ?? enabledGames[0],
  );

  const gameButtons = enabledGames.map((gameId) => (
    <button
      key={gameId}
      className={classNames("button button-secondary hover:border-white", {
        "border-neutral-50": selectedGame !== gameId,
        "border-white": selectedGame === gameId,
      })}
      onClick={() => setSelectedGame(gameId)}
    >
      <GameIcon gameId={gameId} color={selectedGame == gameId ? "#fff" : "#8D858C"} />
    </button>
  ));

  return (
    <>
      {showFavoriteTeamsModal && (
        <FavoriteTeamsModal
          onClose={() => setShowFavoriteTeamsModal(false)}
          selectedGame={selectedGame}
          key={selectedGame}
        />
      )}
      <div className="col-span-1 flex flex-col rounded-small bg-canvas-95 p-7 max-md:col-span-2 max-md:p-5">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <span className="font-style-label-3">Favorite teams</span>

            <div className="hidden gap-2 md:flex">{gameButtons}</div>
          </div>

          <button
            onClick={() => setShowFavoriteTeamsModal(true)}
            className="button button-tertiary"
            title="Edit Favourite Teams"
          >
            Edit
          </button>
        </div>
        <div className="mt-4 flex gap-2 md:hidden">{gameButtons}</div>
        <div className="mt-4 h-0.5 w-full bg-neutral-50/30" />
        <div className="mt-5 flex w-full flex-wrap items-center gap-4">
          <FavoriteTeamsForGame game={selectedGame} />
        </div>
      </div>
    </>
  );
};

export { FavoriteTeamsSection };
