import { Button, Typography } from "@blastorg/portal-pattern-library";
import { Hero } from "../../../../shared/components/Hero";
import { Sponsor } from "./components/Sponsor";
import { useState } from "react";
import { HowToPlayModal } from "../HowToPlayModal";
import { FantasyEvent } from "../../../../api/data/sanity/shared/types/fantasy";

interface FantasyHeaderProps {
  fantasyEvent: FantasyEvent;
  isSeasonLeaderboard?: boolean;
}

export function FantasyHeader({ fantasyEvent: fantasyData, isSeasonLeaderboard }: FantasyHeaderProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <div className="">
        <Hero>
          <Hero.Background image={fantasyData.headerImage} />

          <div className="left-0 top-10 flex w-full flex-col items-center justify-center pb-5  sd:pb-6 xl:pb-8">
            <div className="flex flex-col place-content-center text-center">
              <Typography variant="desktopH1" color="white" component="h1" className="hidden md:block">
                {isSeasonLeaderboard ? `${fantasyData.season.seasonNameTitle} Season` : fantasyData.eventNameTitle}
              </Typography>
              <Typography variant="desktopH1" color="white" component="h1" className="hidden md:block">
                Fantasy
              </Typography>
              <Typography variant="mobileH1" color="white" component="h1" className="block md:hidden">
                {isSeasonLeaderboard ? `${fantasyData.season.seasonNameTitle} Season` : fantasyData.eventNameTitle}
              </Typography>
              <Typography variant="mobileH1" color="white" component="h1" className="block md:hidden">
                Fantasy
              </Typography>

              <Sponsor fantasyData={fantasyData} />

              <div className="flex place-content-center pt-5  ">
                <Button variant="tertiary" className="" onClick={toggleModal}>
                  How to play
                </Button>
              </div>
            </div>
          </div>
        </Hero>
      </div>
      <HowToPlayModal isOpen={isModalOpen} onClose={toggleModal} />
    </>
  );
}
