import { DateTime } from "luxon";
import { useState } from "react";

interface DateFieldProps {
  title: string;
  disabled: boolean;
  minDate: Date;
  maxDate: Date;
  value: Date;
  setValue: (value: Date) => void;
}

const DateField = ({ title, disabled, minDate, maxDate, value, setValue }: DateFieldProps) => {
  const [inputValue, setInputValue] = useState(DateTime.fromJSDate(value).toFormat("yyyy-MM-dd"));
  return (
    <div className="relative inline-block w-full text-left">
      <span className="mb-1.5 inline-flex w-full place-content-center font-style-label-3">{title}</span>
      <input
        className="w-full rounded-small border border-neutral-50 bg-transparent px-3 py-2.5 text-white font-style-b2-body-copy focus:border focus:border-purple focus:outline-none focus:ring-0"
        type={"date"}
        min={DateTime.fromISO(minDate.toISOString()).toFormat("yyyy-MM-dd")}
        max={DateTime.fromISO(maxDate.toISOString()).toFormat("yyyy-MM-dd")}
        value={inputValue}
        disabled={disabled}
        onChange={(e) => {
          setInputValue(e.target.value);
          setValue(new Date(e.target.value));
        }}
      />
    </div>
  );
};

export { DateField };
