export const queryKeys = {
  all: ["fantasy"] as const,
  leaderBoard: ({
    seasonId,
    eventId,
    leagueId,
    calculationId,
    offset,
  }: {
    seasonId?: string;
    eventId?: string;
    leagueId?: string;
    calculationId?: string;
    offset?: number;
  }) => [queryKeys.all, "leaderboard", seasonId, eventId, leagueId, calculationId, offset] as const,
  myLeagues: () => [queryKeys.all, "myLeagues"] as const,
  myLeague: ({ eventId, seasonId }: { eventId?: string; seasonId?: string }) =>
    [queryKeys.myLeagues(), eventId, seasonId] as const,
  myPicksDetailed: ({ eventId, seasonId }: { eventId?: string; seasonId?: string }) =>
    [queryKeys.all, "myPicksDetailed", eventId, seasonId] as const,
  leagueDetails: ({ leagueId }: { leagueId: string | undefined }) =>
    [queryKeys.all, "leagueDetails", leagueId] as const,
};
