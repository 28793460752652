import { SVGProps } from "react";

export function BackgroundImage(props: SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox="0 0 1440 660" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2363_17210)">
        <g style={{ mixBlendMode: "screen" }} opacity="0.6">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="currentColor"
            d="M1180 -111.138L1041.4 181.13L1483.4 528.213L1180 -111.138ZM1045.21 533.287L1120.65 692.094L1392 667.211L1348.61 575.54L906.607 228.458L666.833 733.881L963.097 706.649L1045.21 533.287ZM1477.77 778.076L1718.12 1284.91L543.824 1284.91L752.602 844.747L1048.75 817.515L948.746 1028.21L1313.09 1028.21L1206.3 803.077L1477.77 778.076Z"
          />
        </g>
        <g style={{ mixBlendMode: "screen" }} opacity="0.6">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="currentColor"
            d="M-468.336 744.205L-198.954 648.165L-258.709 153.844L-468.336 744.205ZM18.7232 425.369L70.7433 278.68L-114.643 124.146L-144.768 208.724L-85.0126 703.044L380.892 537.018L178.447 368.337L18.7232 425.369ZM-99.0455 0.989364L67.23 -466.909L802.212 269.25L396.489 413.861L194.115 245.251L388.366 176.074L160.33 -52.3274L86.487 155.523L-99.0455 0.989364Z"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2363_17210">
          <rect width="1440" height="660" fill="white" transform="translate(0.125)" />
        </clipPath>
      </defs>
    </svg>
  );
}
