import { useCallback, useRef } from "react";
import { CSGuessrPosition } from "../../../../../../../../../api/data/csGuessr/shared/types";

export const useGestures = ({
  imageRef,
  onSetPosition: setPosition,
  disabled,
}: {
  imageRef: React.RefObject<HTMLImageElement>;
  onSetPosition: (position: CSGuessrPosition) => void;
  disabled?: boolean;
}) => {
  const mouseDownPositionRef = useRef<CSGuessrPosition | undefined>(undefined);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
      if (disabled || !imageRef.current) return;

      const { offsetX, offsetY } = event.nativeEvent;

      const { width, height } = imageRef.current;

      const x = (offsetX / width) * 100;
      const y = (offsetY / height) * 100;

      setPosition({ x, y });
    },
    [setPosition, disabled, imageRef],
  );

  const handleMouseDown = useCallback((event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    mouseDownPositionRef.current = {
      x: event.nativeEvent.screenX,
      y: event.nativeEvent.screenY,
    };
  }, []);

  const handleMouseUp = useCallback(
    (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
      const mouseDownPosition = mouseDownPositionRef.current;
      const hasMoved =
        !!mouseDownPosition &&
        (Math.abs(mouseDownPosition.x - event.nativeEvent.screenX) > 1 ||
          Math.abs(mouseDownPosition.y - event.nativeEvent.screenY) > 1);

      if (!hasMoved) {
        handleClick(event);
      }

      mouseDownPositionRef.current = undefined;
    },
    [handleClick],
  );

  return {
    handleMouseDown,
    handleMouseUp,
  };
};
