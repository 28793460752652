import { Button, Typography } from "@blastorg/portal-pattern-library";
import { useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTimeout } from "usehooks-ts";
import { IconButton } from "../../../../../../shared/components/Icon";
import { FantasyEvent } from "../../../../../../api/data/sanity/shared/types/fantasy";
import { GetLeagueDetails } from "../../../../../../api/data/fantasy/getLeagueDetails";

export function LeaderboardActionsHeader({
  fantasyEvent,
  seasonId,
  eventId,
  league,
  handleLeaveLeague,
}: {
  fantasyEvent: FantasyEvent;
  seasonId: string;
  eventId: string;
  league: GetLeagueDetails | null | undefined;
  handleLeaveLeague: () => void;
}) {
  const location = useLocation();
  const [copyJoinCodeClicked, setCopyJoinCodeClicked] = useState<boolean>(false);
  const [copyJoinLinkClicked, setCopyJoinLinkClicked] = useState<boolean>(false);

  useTimeout(() => setCopyJoinCodeClicked(false), copyJoinCodeClicked ? 1000 : null);
  useTimeout(() => setCopyJoinLinkClicked(false), copyJoinLinkClicked ? 1000 : null);

  const thisLeague = useMemo(() => {
    if (league && !league.isPublic) {
      return league;
    } else {
      if (seasonId === eventId) {
        return {
          joinCode: "",
          leagueName: `${fantasyEvent.season.seasonNameTitle} Season`,
          leagueId: fantasyEvent.season.uid,
          memberCount: 0,
        };
      }
      return {
        joinCode: "",
        leagueName: fantasyEvent.eventNameTitle,
        leagueId: fantasyEvent.uid,
        memberCount: 0,
      };
    }
  }, [league, seasonId, eventId, fantasyEvent]);

  const handleCopyCode = () => {
    setCopyJoinCodeClicked(true);
    navigator.clipboard.writeText(thisLeague?.joinCode || "");
  };

  const handleAutoJoin = () => {
    setCopyJoinLinkClicked(true);
    // Get the full URL
    const url = `${window.location.href}`;
    const path = location.pathname;

    // Replace the path with the auto-join path
    const newUrl = url.replace(path, `/fantasy/join/${thisLeague?.joinCode}`);

    navigator.clipboard.writeText(newUrl);
  };

  return (
    <div className="flex w-full place-content-center place-items-center">
      <div className="flex w-full flex-col md:flex-row md:place-content-between md:place-items-center">
        {thisLeague ? (
          <div className="flex place-items-center gap-5 pl-6 md:place-content-center md:pl-0">
            <Link to="/fantasy">
              <IconButton icon="arrowCircleLeftClear" onClick={() => null} label="Toggle navigation" />
            </Link>
            <Typography variant="desktopH3">{thisLeague.leagueName}</Typography>
          </div>
        ) : (
          <div className="hidden place-content-center place-items-center gap-5 md:flex">
            <div className="size-6 animate-pulse rounded-full bg-canvas-90" />
            <div className="h-6 w-24 animate-pulse rounded-small bg-canvas-90" />
          </div>
        )}
        {!!league && !league.isPublic && (
          <div className="flex flex-col gap-2">
            {thisLeague ? (
              <div className="flex items-center gap-5 transition-colors duration-[250ms]">
                <Button variant="tertiary" onClick={handleLeaveLeague} className="hidden md:flex">
                  Exit League
                </Button>
                <Button
                  className="hidden w-[12.5rem] md:block"
                  variant={copyJoinCodeClicked ? "success" : "secondary"}
                  onClick={handleCopyCode}
                >
                  {copyJoinCodeClicked ? "Copied!" : "Copy league code"}
                </Button>
                <Button
                  className="hidden w-[12.5rem] md:block"
                  variant={copyJoinLinkClicked ? "success" : "secondary"}
                  onClick={handleAutoJoin}
                >
                  {copyJoinLinkClicked ? "Copied!" : "Copy join link"}
                </Button>
                <div className="mt-4 flex  w-full place-content-center gap-8 md:hidden">
                  <Button className="block " variant={"tertiary"} onClick={handleCopyCode}>
                    {copyJoinCodeClicked ? "Copied!" : "Copy league code"}
                  </Button>
                  <Button className="block " variant={"tertiary"} onClick={handleAutoJoin}>
                    {copyJoinLinkClicked ? "Copied!" : "Copy join link"}
                  </Button>
                </div>
              </div>
            ) : (
              <div className="hidden place-items-center gap-5 md:flex">
                <div className="h-4 w-24 animate-pulse rounded-small bg-canvas-90"></div>
                <div className="h-8 w-56 animate-pulse rounded-small bg-canvas-90"></div>
                <div className="h-8 w-56 animate-pulse rounded-small bg-canvas-90"></div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
