import { useMemo } from "react";
import { useUserProfile } from "../../../../../auth/useUserProfile";
import { useGetAvatars } from "../../../../../api/data/assets/getAvatars/useGetAvatars";
import { useAvatarModal } from "../../../../../providers/AvatarModal/useAvatarModal";
import { generateImageUrl } from "../../../../../api/data/assets/helpers/generateImageUrl";
import { generateAssetsId } from "../../../../../api/data/assets/helpers/generateAssetsId";

export function AvatarsRewards() {
  const user = useUserProfile();

  const { data: avatars, isLoading } = useGetAvatars();

  const avatarsModal = useAvatarModal();

  const avatarsToShow = useMemo(() => {
    if (!user?.avatars) return [];

    return avatars?.filter((avatar) => user.avatars?.includes(avatar.id));
  }, [user, avatars]);

  const randomLoading = useMemo(() => {
    // Generate random number between 1 and 5
    return Math.floor(Math.random() * 5) + 1;
  }, []);

  return (
    <div className="flex h-fit w-full flex-col gap-3 rounded-small bg-canvas-95 px-5 py-4">
      <div className="">
        <span className="font-style-label-3">Avatars</span>
      </div>
      <div className="h-px w-full bg-neutral-50/50" />
      <div className="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
        {avatarsToShow?.map((avatar) => (
          <div
            key={avatar.id}
            className="flex cursor-pointer flex-col place-content-center items-center rounded pb-2 text-center hover:bg-canvas-80"
            onClick={() => {
              avatarsModal.setNewAvatarId(avatar.id);
              avatarsModal.setIsOpen(true);
            }}
          >
            <img
              src={generateImageUrl("avatars", generateAssetsId(avatar.id, "3d"), {
                format: "auto",
                height: "200",
              })}
              className="h-24"
            />
            <span className="text-neutral-20 font-style-b3-body-copy">{avatar.name}</span>
          </div>
        ))}

        {isLoading && avatarsToShow?.length === 0
          ? Array.from({ length: randomLoading })?.map((_, index) => (
              <div
                key={index}
                className="flex cursor-pointer flex-col place-content-center items-center gap-3 rounded pb-2 text-center hover:bg-canvas-80"
              >
                <div className="h-20 w-16 animate-pulse rounded-small bg-neutral-50/20 py-2" />
                <div className="h-4 w-24 animate-pulse rounded-small bg-neutral-50/20" />
              </div>
            ))
          : null}
      </div>
      {!isLoading && avatarsToShow?.length === 0 ? (
        <div className="w-full items-center py-12 text-center">
          <span className="text-neutral-20 font-style-b3-body-copy">Redeem Avatars through cases!</span>
        </div>
      ) : null}
    </div>
  );
}
