import classNames from "classnames";
import { Typography } from "@blastorg/portal-pattern-library";
import { baseAssetsURL } from "../../../../../../config";
import { CaptainIcon } from "../../../../components/CaptainIcon";
import { useAuth } from "../../../../../../auth/AuthProvider/hooks/useAuth";
import { generateImageUrl } from "../../../../../../api/data/assets/helpers/generateImageUrl";
import { generateAssetsId } from "../../../../../../api/data/assets/helpers/generateAssetsId";
import { FantasyPlayer } from "../../../../../../api/data/sanity/shared/types/fantasy";
import { Loader } from "../../../../../../shared/components/Loader";
import { z } from "zod";
import {
  FantasyLeaderboardRowSchema,
  FantasyLeaderboardRowType,
} from "../../../../../../api/data/fantasy/getLeaderboard";
import InfiniteScroll from "react-infinite-scroll-component";
import { useEffect, useState } from "react";
import { GetLeaderBoardType } from "../../../../../../api/data/fantasy/getLeaderboard";

interface LeaderboardPlayers {
  diamondPlayers: FantasyPlayer[];
  platinumPlayers: FantasyPlayer[];
  goldPlayers: FantasyPlayer[];
  silverPlayers: FantasyPlayer[];
  bronzePlayers: FantasyPlayer[];
}
interface Levels {
  diamond: string[];
  platinum: string[];
  gold: string[];
  silver: string[];
  bronze: string[];
}

function LeaderboardRow({
  row,
  players,
  isStaticUserRow,
}: {
  row: z.infer<typeof FantasyLeaderboardRowSchema>;
  players: LeaderboardPlayers;
  isStaticUserRow?: boolean;
}) {
  const auth = useAuth();

  const levels = {
    diamond: players.diamondPlayers.map((player) => player.nickname),
    platinum: players.platinumPlayers.map((player) => player.nickname),
    gold: players.goldPlayers.map((player) => player.nickname),
    silver: players.silverPlayers.map((player) => player.nickname),
    bronze: players.bronzePlayers.map((player) => player.nickname),
  };

  const nicknameToLevel: { [key: string]: number } = {};
  Object.keys(levels).forEach((level, i) => {
    levels[level as keyof Levels].forEach((nickname) => {
      nicknameToLevel[nickname] = i;
    });
  });

  const sortedPicks = row.picks.sort((a, b) => nicknameToLevel[a.nickname] - nicknameToLevel[b.nickname]);

  return (
    <tr
      key={row.userId}
      className={classNames(auth.userId === row.userId ? "bg-canvas-95/5 backdrop-contrast-[.85]" : "")}
    >
      <td className="w-52 border border-x-0 border-canvas-80 py-4 pl-4 text-left">
        <Typography variant="label3" color="inherit">
          {row.position ?? "-"}
        </Typography>
      </td>
      <td className="w-64 border border-x-0 border-canvas-80 text-center">
        <div className="flex items-center gap-2">
          <img
            className="size-8"
            src={generateImageUrl("avatars", generateAssetsId(row.userAvatar ?? "", "2d"), {
              width: "64",
              height: "64",
              format: "auto",
            })}
            alt="avatar"
          />
          <Typography variant="label3" color="inherit">
            {row.username}
          </Typography>
        </div>
      </td>
      <td className="hidden w-[48rem] border border-x-0 border-canvas-80 text-center md:table-cell">
        <div className="flex place-content-center gap-1">
          {sortedPicks.map((pick, index) => (
            <div key={`${row.userId}_${pick.nickname}`} className="flex place-content-center place-items-center gap-1">
              {pick.isCaptain && <CaptainIcon className="size-3 fill-white" />}
              <Typography variant="label3">{pick.nickname} </Typography>
              {index !== row.picks.length - 1 && <div className="size-0.5 rounded-full bg-white" />}
            </div>
          ))}
        </div>
      </td>
      <td className={classNames(isStaticUserRow ? "pr-6" : "pr-4", "border border-x-0 border-canvas-80  text-right")}>
        <Typography variant="label3" color="inherit">
          {row.points ?? "-"}
        </Typography>
      </td>
    </tr>
  );
}

export function Leaderboard({
  leaderboard,
  players,
  isLoading,
  getNextData,
}: {
  leaderboard?: GetLeaderBoardType;
  players: LeaderboardPlayers;
  isLoading: boolean;
  getNextData: (calculationId: string, offset: number) => Promise<GetLeaderBoardType>;
}) {
  const [leaderboardRows, setLeaderboardRows] = useState(leaderboard?.positions || []);
  const [calculationId, setCalculationId] = useState(leaderboard?.calculationId ?? "");
  const [endOfActiveUsersFound, setEndOfActiveUsersFound] = useState<boolean>(
    leaderboard?.endOfActiveUsersFound !== undefined ? leaderboard.endOfActiveUsersFound : true,
  );
  const [userPosition, setUserPosition] = useState<FantasyLeaderboardRowType | undefined>(leaderboard?.userPosition);

  useEffect(() => {
    setCalculationId(leaderboard?.calculationId ?? "");
    setLeaderboardRows(leaderboard?.positions || []);
    setEndOfActiveUsersFound(
      leaderboard?.endOfActiveUsersFound !== undefined ? leaderboard.endOfActiveUsersFound : true,
    );
    setUserPosition(leaderboard?.userPosition);
  }, [leaderboard]);

  return (
    <div
      style={{
        backgroundImage: `url(${baseAssetsURL}/fantasy/utils/leaderboard-background.svg)`,
      }}
      className="mx-4 mt-8 w-full bg-center bg-repeat"
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <InfiniteScroll
            dataLength={leaderboardRows.length}
            next={async () => {
              const nextData = await getNextData(calculationId, leaderboardRows.length);
              setEndOfActiveUsersFound(
                nextData.endOfActiveUsersFound !== undefined ? nextData.endOfActiveUsersFound : true,
              );
              setLeaderboardRows((prev) => [...prev, ...nextData.positions]);
            }}
            hasMore={
              endOfActiveUsersFound !== undefined
                ? !endOfActiveUsersFound
                : leaderboard
                  ? leaderboard?.playerCount > leaderboardRows.length
                  : false
            }
            loader={<h3 className="w-full text-center">Loading</h3>}
            height={leaderboardRows.length >= 10 ? 515 : leaderboardRows.length * 49 + 29}
            className="scrollbar-thin scrollbar-track-canvas-95 scrollbar-thumb-canvas-80 scrollbar-thumb-rounded-full"
          >
            <table className="w-full table-auto border-collapse text-center">
              <thead className="text-neutral-50">
                <tr>
                  <th className="pb-3 pl-4 text-left">
                    <Typography variant="label3" color="inherit" className=" hidden md:block">
                      position
                    </Typography>
                    <Typography variant="label3" color="inherit" className=" block md:hidden">
                      pos
                    </Typography>
                  </th>
                  <th className="pb-3 pl-6 text-left md:pl-0 md:text-left">
                    <Typography variant="label3" color="inherit">
                      Player
                    </Typography>
                  </th>
                  <th className="hidden pb-3 text-center md:table-cell">
                    <Typography variant="label3" color="inherit">
                      Chosen team
                    </Typography>
                  </th>
                  <th className="pb-3 pr-4 text-right">
                    <Typography variant="label3" color="inherit">
                      Total points
                    </Typography>
                  </th>
                </tr>
              </thead>
              <tbody>
                {leaderboardRows.map((row) => (
                  <LeaderboardRow key={row.userId} row={row} players={players} />
                ))}
              </tbody>
            </table>
          </InfiniteScroll>
          {/* Show static user row if large leaderboard and user is in leaderboard */}
          {userPosition && leaderboard && leaderboard.playerCount >= 15 && (
            <table className="w-full table-auto border-collapse text-center ">
              <tbody>
                <LeaderboardRow key={userPosition.userId} row={userPosition} players={players} isStaticUserRow />
              </tbody>
            </table>
          )}
        </>
      )}
    </div>
  );
}
