export type AdType =
  | "sideRail"
  | "leaderboard"
  | "skyscraper"
  | "mpu"
  | "masthead"
  | "mobileMpu"
  | "mobileBanner"
  | "richMedia"
  | "takeOverBanner";

export const adData: { [key in AdType]: { placementId: string; className: string; dataDisplayType?: string } } = {
  sideRail: {
    // Desktop - SideRail (65e039099105a208f7635f5f) - 300x600, 160x600, 300x250
    placementId: "65e039099105a208f7635f5f",
    className: "h-[600px] w-[300px] flex justify-center items-start",
  },
  leaderboard: {
    // Desktop - Leaderboard (65e0392c6bd1060b85e60dc2) - 728x90
    placementId: "65e0392c6bd1060b85e60dc2",
    className: "h-[90px] w-[728px]",
  },
  masthead: {
    // Masthead (65eee3ff5daddd2733903a5e) - 970x250
    placementId: "65eee3ff5daddd2733903a5e",
    className: "h-[250px] w-[970px]",
  },
  mpu: {
    // MPU (65eee40d6294df129ac48a0a) - 300x600
    placementId: "65eee40d6294df129ac48a0a",
    className: "h-[600px] w-[300px]",
  },
  skyscraper: {
    // Skyscraper (65eee4205daddd2733903a60) - 160x600
    placementId: "65eee4205daddd2733903a60",
    className: "h-[600px] w-[160px]",
  },
  mobileMpu: {
    // Mobile - MPU (65ef0faa5daddd2733903a66) - 300x250, 336x280
    placementId: "65ef0faa5daddd2733903a66",
    className: "h-[280px] w-[336px] flex justify-center items-center",
  },
  mobileBanner: {
    // Mobile - Banner (65ef0f845daddd2733903a64) - 300x50, 300x100, 320x100
    placementId: "65ef0f845daddd2733903a64",
    className: "h-[100px] w-[320px] flex justify-center items-center",
  },
  richMedia: {
    // Rich Media (65eff0ba5daddd2733903a6c) - 1x1
    placementId: "65eff0ba5daddd2733903a6c",
    className: "w-[1px] h-[1px]",
  },
  takeOverBanner: {
    // Takeover Banner (66211c3b50e90801e258b59e)
    placementId: "66211c3b50e90801e258b59e",
    className: "",
    dataDisplayType: "hybrid-banner",
  },
};
