import { toRem } from "@blastorg/portal-pattern-library";
import { navigationHeightPx } from "../../../../../../styles/constants";
import { FOOTER_MIN_HEIGHT } from "../../../../../../shared/components/Footer/constants";
import classnames from "classnames";

const CSGuessrPageSlot = ({ children, className }: { children: React.ReactNode; className?: string }) => {
  return (
    <div
      className={classnames(
        "mx-auto flex max-w-screen-sd flex-col pb-8 max-sd:mx-8 max-md:mx-auto max-md:max-w-[30rem] max-md:px-4",
        className,
      )}
      style={{
        minHeight: `calc(100vh - ${toRem(navigationHeightPx)} - ${toRem(FOOTER_MIN_HEIGHT)})`,
      }}
    >
      {children}
    </div>
  );
};

export { CSGuessrPageSlot };
