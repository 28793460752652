import { Button } from "@blastorg/portal-pattern-library";
import { useCSGuessrGame } from "../../../../../../hooks/useCSGuessrGame";
import { useMemo } from "react";
import classnames from "classnames";

interface NextRoundButtonProps {
  className?: string;
}

const NextRoundButton = ({ className }: NextRoundButtonProps) => {
  const { goToNextRound, rounds, currentRoundIndex } = useCSGuessrGame();

  const isLastRound = useMemo(() => {
    return currentRoundIndex === rounds.length - 1;
  }, [currentRoundIndex, rounds]);

  return (
    <Button className={classnames("w-fit", className)} variant="default" onClick={goToNextRound}>
      {isLastRound ? "See results" : "Next round"}
    </Button>
  );
};

export { NextRoundButton };
