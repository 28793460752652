import { Typography, toRem } from "@blastorg/portal-pattern-library";
import { BLASTLogo } from "./assets/BLASTLogo";
import { InstagramLogo } from "./assets/InstagramLogo";
import { TikTokLogo } from "./assets/TikTokLogo";
import { TwitterLogo } from "./assets/TwitterLogo";
import { FooterItem } from "./components/FooterItem";
import { FOOTER_MIN_HEIGHT } from "./constants";
import classNames from "classnames";

export function Footer({ className }: { className?: string }) {
  return (
    <div className={classNames("flex w-full flex-col justify-center bg-canvas-95", className)}>
      <div
        className="m-auto flex w-full flex-col items-center justify-center gap-10 divide-x-0 divide-y-2 divide-solid divide-neutral-50 py-10 no-underline lg:w-fit lg:py-0"
        style={{
          minHeight: toRem(FOOTER_MIN_HEIGHT),
        }}
      >
        <div className="flex flex-col place-items-center gap-5 px-7 text-neutral-50 lg:flex-row lg:place-content-between lg:items-center lg:gap-12">
          <div className="flex flex-col justify-center text-neutral-50">
            <BLASTLogo className="size-6" />
          </div>
          <FooterItem text="Terms of use" linkTo="/privacy-policy" />
          <FooterItem isNewPage={true} text="About" linkTo="https://about.blast.tv/" />
          <FooterItem text="Support@blast.tv" linkTo="mailto:support@blast.tv" />
          <FooterItem text="Press" linkTo="/press" />
          <div className="flex w-full place-content-center items-center  justify-between lg:w-fit lg:gap-12">
            <a
              className="no-underline"
              href="https://twitter.com/BLASTtv"
              rel="noreferrer"
              target={"_blank"}
              aria-label="Open BLAST X account in a new window"
            >
              <div className="text-neutral-50">
                <TwitterLogo />
              </div>
            </a>
            <a
              href="https://www.instagram.com/blastpremier"
              rel="noreferrer"
              target={"_blank"}
              aria-label="Open BLAST instagram account in a new window"
            >
              <div>
                <InstagramLogo className="text-neutral-50" />
              </div>
            </a>
            <a
              href="https://www.tiktok.com/@blasttv"
              rel="noreferrer"
              target={"_blank"}
              aria-label="Open BLAST tiktok account in a new window"
            >
              <div>
                <TikTokLogo className="text-neutral-50" />{" "}
              </div>
            </a>
          </div>
        </div>
        <div className="w-full pt-10 text-center">
          <Typography variant="b2BodyCopy" color="neutral50">
            BLAST ApS., Hauser Plads 1, 3., 1127 Copenhagen
          </Typography>
        </div>
      </div>
    </div>
  );
}
