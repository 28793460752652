import { UseQueryResult } from "@tanstack/react-query";
import { useMemo } from "react";
import { AllPlayers } from "../../types";
import { FantasyEvent } from "../../../../../../api/data/sanity/shared/types/fantasy";

export const useAllPlayers = (fantasyData: UseQueryResult<FantasyEvent>) => {
  return useMemo(() => {
    if (!fantasyData) {
      return {};
    }

    // Convert players to object by their id
    const players: AllPlayers = {};

    const allCategories = [
      ...(fantasyData.data?.goldPlayers || []),
      ...(fantasyData.data?.silverPlayers || []),
      ...(fantasyData.data?.bronzePlayers || []),
      ...(fantasyData.data?.platinumPlayers || []),
      ...(fantasyData.data?.diamondPlayers || []),
    ];

    allCategories.forEach((player) => {
      players[player.playerId] = player;
    });

    return players;
  }, [fantasyData]);
};
