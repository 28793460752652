import { useQuery } from "@tanstack/react-query";
import { getGhostPage } from "../../../../api/data/sanity/getGhostPage";

export function useFetchSyntheticPage() {
  return useQuery({
    queryKey: ["ghostPage"],
    queryFn: () => getGhostPage(),
    retry: true, // Infinitely retry failing requests
  });
}
