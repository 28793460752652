import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../../../../auth/AuthProvider/hooks/useAuth";
import { queryKeys } from "../shared/queryKeys";
import { getFantasyEvent } from ".";

export const useFetchFantasyEvent = () => {
  const { userEmail } = useAuth();

  return useQuery({
    queryKey: queryKeys.event(),
    queryFn: () => getFantasyEvent(userEmail),
  });
};
