import { Container, Video } from "./styled";
import teeveeRollIn from "./assets/teevee-roll-in.webm";
import teeveeDots from "./assets/teevee-dots.webm";
import teeveeHappy from "./assets/teevee-happy.webm";
import teeveeVerify from "./assets/teevee-verify.webm";
import teeveeCross from "./assets/teevee-cross.webm";
import { useEffect, useRef, useState } from "react";
import { breakpoints } from "@blastorg/portal-pattern-library";
import { useMediaQuery } from "react-responsive";

export type TeeVeeState = "roll-in" | "happy" | "dots" | "verify";

interface TeeVeeAnimationProps {
  state?: TeeVeeState;
  showError?: boolean;
}

function TeeVeeAnimation({ state: initialState, showError }: TeeVeeAnimationProps) {
  const [state, setState] = useState<TeeVeeState>(initialState ?? "roll-in");
  const [isCollapsed, setIsCollapsed] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const onEnded = () => {
    if (showError) {
      return;
    }

    switch (state) {
      case "roll-in":
        setState("happy");
        break;

      case "happy":
        setState("dots");
        break;

      case "dots":
        videoRef.current?.play().catch(() => {
          // play was prevented
        });
        break;

      case "verify":
        setIsCollapsed(true);
        break;
    }
  };

  const isTablet = useMediaQuery({ maxWidth: breakpoints.tablet });

  useEffect(() => {
    if (initialState === "verify") {
      setState("verify");
    }
  }, [initialState]);

  if (isTablet || !initialState) {
    return null;
  }

  return (
    <Container isCollapsed={isCollapsed}>
      <Video ref={videoRef} key={`${state}-${showError}`} autoPlay muted width={128} onEnded={onEnded}>
        {showError ? (
          <source src={teeveeCross} type="video/webm" />
        ) : (
          <>
            {state === "roll-in" && <source src={teeveeRollIn} type="video/webm" />}
            {state === "happy" && <source src={teeveeHappy} type="video/webm" />}
            {state === "dots" && <source src={teeveeDots} type="video/webm" />}
            {state === "verify" && <source src={teeveeVerify} type="video/webm" />}
          </>
        )}
      </Video>
    </Container>
  );
}

export { TeeVeeAnimation };
