import { ReactNode, useEffect, useMemo, useState } from "react";
import { useAuthModal } from "../../../../auth/AuthModalProvider";
import { useAuth } from "../../../../auth/AuthProvider/hooks/useAuth";
import { useFetchSeriesByTournament } from "../../../../api/data/tournament/getSeriesByTournamentId/useFetchSeriesByTournament";
import { getTeamsData } from "../../helpers/formatBracketData/combineBracketsAndGetTeams";
import { useFetchFantasyEvent } from "../../../../api/data/sanity/getFantasyEvent/useFetchFantasyEvent";
import { useGetUserPicksDetailed } from "../../../../api/data/fantasy/getUserPicksDetailed/useGetUserPicksDetailed";
import { usePutUserPicks } from "../../../../api/data/fantasy/putUserPicks/usePutUserPicks";
import { Category } from "../../types/Category";
import { useAllPlayers } from "./hooks/memo/useAllPlayers";
import { useArePicksLocked } from "./hooks/memo/useArePicksLocked";
import { useGroupMatches } from "./hooks/memo/useGroupMatches";
import { useFantasyStateAutoUpdateEffect } from "./hooks/useFantasyStateAutoUpdateEffect";
import { useMatchApiDataWithPickedPlayersEffect } from "./hooks/useMatchApiDataWithPickedPlayersEffect";
import { FantasyContext, FantasyProviderValue } from "./provider";
import { CurrentState, PickedPlayers } from "./types";
import { FantasyPlayer } from "../../../../api/data/sanity/shared/types/fantasy";
import { useFetchPlayerPickRates } from "./hooks/useFetchPlayerPickRates";
import { FantasyPlayerPickRates } from "../../../../api/FantasyDataBucketApiClient/schemas";
import { rudderstack } from "../../../../rudderstack";
import { useFetchTournamentBrackets } from "../../../../api/data/tournament/getBracketByTournamentId/useFetchTournamentBrackets";

const useCreatePlayerPickemsProviderValue = (): FantasyProviderValue => {
  const fantasyEvent = useFetchFantasyEvent();
  const auth = useAuth();
  const myPicks = useGetUserPicksDetailed({
    seasonId: fantasyEvent.data?.season?.uid,
    eventId: fantasyEvent.data?.uid,
    calculationSchemaId: fantasyEvent.data?.calculationSchemaId,
    enabled: Boolean(
      auth.userId && fantasyEvent.data?.season?.uid && fantasyEvent.data?.uid && fantasyEvent.data?.calculationSchemaId,
    ),
  });
  const pickRates = useFetchPlayerPickRates({
    seasonId: fantasyEvent.data?.season?.uid,
    eventId: fantasyEvent.data?.uid,
  });
  const authModal = useAuthModal();
  const [currentState, setCurrentState] = useState<CurrentState>("loading");
  const [mobilePlayer, setMobilePlayer] = useState<FantasyPlayer | null>(null);
  const [pickedPlayers, setPickedPlayers] = useState<PickedPlayers>({});
  const [playerPickRates, setPlayerPickRates] = useState<FantasyPlayerPickRates | null>(null);
  const [tempChosenCaptain, setTempChosenCaptain] = useState<FantasyPlayer | null>(null);
  const [tempPreviousCaptainCategory, setTempPreviousCaptainCategory] = useState<Category | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [overrideAutoState, setOverrideAutoState] = useState(true);
  const { mutate: submitPicks } = usePutUserPicks({
    payload: undefined,

    onSuccess: () => {
      setIsSubmitting(false);
      myPicks.refetch();
    },
  });

  const handleMobilePlayerClick = (player: FantasyPlayer) => {
    setMobilePlayer(player);
  };

  const handleMobilePlayerClose = () => {
    setMobilePlayer(null);
  };

  useEffect(() => {
    if (!auth.userId) {
      setOverrideAutoState(false);
    }
  }, [auth.userId]);

  const tournament = useFetchSeriesByTournament({
    tournamentId: fantasyEvent.data?.tournamentId || "",
    enabled: fantasyEvent.data?.tournamentId !== undefined,
  });

  const matches = useGroupMatches(tournament);

  const tournamentBracket = useFetchTournamentBrackets({ tournamentId: fantasyEvent.data?.tournamentId || "" });

  const tournamentInProgress = useMemo(() => {
    return tournamentBracket.data?.some((bracket) => bracket.matches?.some((match) => match.isCompleted === false));
  }, [tournamentBracket.data]);

  const allPlayers = useAllPlayers(fantasyEvent);
  const arePicksLocked = useArePicksLocked(fantasyEvent.data?.endTime);
  useMatchApiDataWithPickedPlayersEffect(fantasyEvent, myPicks, setPickedPlayers, setOverrideAutoState);
  useFantasyStateAutoUpdateEffect(
    setCurrentState,
    myPicks,
    overrideAutoState,
    pickedPlayers,
    isSubmitting,
    fantasyEvent.data,
    auth.userId,
  );

  useEffect(() => {
    setPlayerPickRates(pickRates.data ?? null);
  }, [pickRates.data]);

  const pickedTeams = useMemo(() => {
    const teams = Object.values(pickedPlayers).map((player) => player.teamId);

    // Create count object
    const count = teams.reduce((acc: { [key: string]: number }, team) => {
      acc[team] = (acc[team] || 0) + 1;
      return acc;
    }, {});

    // Filter teams that occur more than once
    const duplicateTeams = Object.entries(count)
      .filter(([, count]) => count > 1)
      .map(([team]) => team);

    return duplicateTeams;
  }, [pickedPlayers]);

  const handlePlayerClick = (player: FantasyPlayer, category: string) => {
    if (!auth.userId) {
      authModal?.openModal("register");
      return;
    }
    if (isSubmitting) return;
    if (arePicksLocked) return;

    const isCaptain = tempPreviousCaptainCategory === category ? true : false;

    if (isCaptain) {
      setTempPreviousCaptainCategory(null);
      setTempChosenCaptain(player);
    }

    setPickedPlayers({
      ...pickedPlayers,
      [category]: {
        ...player,
        isCaptain,
      },
    });
  };

  const handleRemovePlayer = (category: Category) => {
    const newPickedPlayers = { ...pickedPlayers };
    if (newPickedPlayers[category]?.isCaptain) {
      setTempPreviousCaptainCategory(category);
    }
    delete newPickedPlayers[category];
    setPickedPlayers(newPickedPlayers);
  };

  const handleEditPicks = () => {
    setCurrentState("pickingPlayers");
    setOverrideAutoState(true);
  };

  const handleEditCaptain = () => {
    setCurrentState("pickingCaptain");
    setOverrideAutoState(true);
  };

  const tournamentTeamsData = useMemo(() => {
    const teams = getTeamsData(tournament.data || []);
    return teams;
  }, [tournament.data]);

  const handleChangeTempCaptain = (player: FantasyPlayer | null) => {
    setPickedPlayers((prev) => {
      if (!prev) return prev;
      const newPickedPlayers = { ...prev };
      Object.entries(newPickedPlayers).forEach(([category, player]) => {
        newPickedPlayers[category] = {
          ...player,
          isCaptain: false,
        };
      });
      return newPickedPlayers;
    });
    setTempChosenCaptain((prev) => (prev?.playerId === player?.playerId ? null : player));
  };

  const handlePlayersSubmit = () => {
    if (!auth.userId) {
      authModal?.openModal("register");
      return;
    }

    const captain = tempChosenCaptain || Object.values(pickedPlayers).find((player) => player.isCaptain);

    if (!captain) return;

    const newPickedPlayers = { ...pickedPlayers };
    // Find the player category of the temp captain
    const category = Object.entries(newPickedPlayers).find(([, player]) => player.playerId === captain.playerId);
    if (!category) return;
    if (!fantasyEvent.data) return;

    setIsSubmitting(true);

    const picks = Object.entries(newPickedPlayers).map(([category, player]) => {
      const cat = category as Category;
      return {
        category: cat,
        playerId: player.playerId,
        isCaptain: player.playerId === captain.playerId,
        playerName: player.nickname,
      };
    });

    if (!myPicks.data?.picks?.length) {
      rudderstack?.track("Minigame Started", {
        context: "fantasy",
      });
    }

    submitPicks({
      seasonId: fantasyEvent.data.season.uid,
      eventId: fantasyEvent.data.uid,
      userPicks: picks,
    });

    setOverrideAutoState(false);
  };

  return {
    fantasyQuery: fantasyEvent,
    myPicksQuery: myPicks,
    methods: {
      handlePlayerClick,
      handleRemovePlayer,
      handleChangeTempCaptain,
      handlePlayersSubmit,
      handleEditPicks,
      handleEditCaptain,
      handleMobilePlayerClick,
      handleMobilePlayerClose,
    },
    values: {
      currentState,
      pickedPlayers,
      pickedTeams,
      pickRates: playerPickRates,
      tournamentTeamsData,
      arePicksLocked,
      tempChosenCaptain,
      isSubmitting,
      overrideAutoState,
      allPlayers,
      matches,
      mobilePlayer,
      tournamentInProgress,
    },
  };
};

export const FantasyProvider = ({ children }: { children: ReactNode }) => {
  const playerPickemsProviderValue = useCreatePlayerPickemsProviderValue();

  return <FantasyContext.Provider value={playerPickemsProviderValue}>{children}</FantasyContext.Provider>;
};
