import { useState, useCallback, useRef, useEffect } from "react";
import { tsParticles, Container } from "tsparticles-engine";
import type { Engine } from "tsparticles-engine";
import { loadFull } from "tsparticles";
import type { ISourceOptions, RecursivePartial, IOptions, ICoordinates } from "tsparticles-engine";

const baseEmitterConfig = (
  direction: string,
  position: RecursivePartial<ICoordinates>,
  imageSrc?: string,
): RecursivePartial<IOptions> => {
  const shape = imageSrc
    ? {
        type: "image",
        options: {
          image: {
            src: imageSrc,
            width: 100,
            height: 100,
            gif: true,
            fill: true,
          },
        },
      }
    : {
        type: "char",
        options: {
          char: {
            value: ["🦄"],
            font: "16px serif",
            style: "",
            weight: "400",
            fill: true,
          },
        },
      };
  return {
    direction,
    life: {
      count: 1,
      duration: 1,
    },
    rate: {
      quantity: 2,
      delay: Number.MIN_VALUE,
    },
    position,
    particles: {
      shape,
      move: {
        enable: true,
        angle: {
          value: 45,
          offset: {
            max: 35,
            min: -35,
          },
        },
        random: true,
        speed: 55,
        gravity: {
          enable: true,
          acceleration: 25,
        },
        outModes: {
          default: "destroy",
        },
      },
      rotate: {
        value: {
          min: 0,
          max: 360,
        },
        direction: "random",
        animation: {
          enable: true,
          speed: {
            min: -55,
            max: 55,
          },
        },
      },
      tilt: {
        direction: "random",
        enable: true,
        value: {
          min: 0,
          max: 360,
        },
        animation: {
          enable: true,
          speed: {
            min: -10,
            max: 10,
          },
        },
      },
      wobble: {
        distance: 30,
        enable: true,
        speed: {
          min: -45,
          max: 45,
        },
      },
      size: {
        value: { min: 30, max: 40 },
      },
      number: {
        value: 0,
      },
      opacity: {
        value: 1,
      },
    },
  };
};

const configs: ISourceOptions = {
  backgroundMode: {
    enable: true,
    zIndex: 50,
  },
  emitters: [baseEmitterConfig("top-right", { x: 0, y: 40 }), baseEmitterConfig("top-left", { x: 100, y: 40 })],
};

function useEmojiRain() {
  const isParticlesLoadedRef = useRef(false);
  const containerRef = useRef<Container | undefined>();
  const [isCountdownInit, setIsCountdownInit] = useState(false);
  const [counter, setCounter] = useState(0);

  const particlesInit = useCallback(async (engine: Engine) => {
    if (!isParticlesLoadedRef.current) {
      await loadFull(engine);
      isParticlesLoadedRef.current = true;
    }
  }, []);
  const triggerConfetti = useCallback(async (imageSrc?: string) => {
    // Stop and clear the current particles container if it exists
    if (containerRef.current) {
      containerRef.current.stop();
      containerRef.current.destroy();
      containerRef.current = undefined;
    }

    const newConfigs = {
      ...configs,
      emitters: [
        baseEmitterConfig("top-right", { x: 0, y: 40 }, imageSrc),
        baseEmitterConfig("top-left", { x: 100, y: 40 }, imageSrc),
      ],
    };

    const loadedContainer = await tsParticles.load("tsparticles", newConfigs);
    containerRef.current = loadedContainer;
  }, []);

  const triggerCounterRain = useCallback(() => {
    if (!isCountdownInit) {
      setIsCountdownInit(true);
      setTimeout(() => {
        setCounter(0);
        setIsCountdownInit(false);
      }, 3000);
    }

    const newCounter = counter + 1;
    setCounter(newCounter);

    if (newCounter === 5) {
      triggerConfetti();
      setCounter(0);
    }
  }, [counter, isCountdownInit, triggerConfetti]);

  useEffect(() => {
    return () => {
      containerRef.current?.destroy();
      containerRef.current = undefined;
    };
  }, []);

  return { triggerConfetti, triggerCounterRain, particlesInit };
}

export { useEmojiRain };
