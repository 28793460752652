import axios, { AxiosError } from "axios";

export type ErrorResponse = {
  code: string;
  message: string;
  issues?: { [key: number]: string };
};

export function isAxiosError<ResponseType>(error: unknown): error is AxiosError<ResponseType> {
  return axios.isAxiosError(error);
}
