import classNames from "classnames";
import { useState } from "react";

export function TeamImage({ url, alt, name }: { url: string; alt: string; name: string }) {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div className="flex items-center gap-2 pl-2">
      <div className="w-9">
        {!isLoaded && <div className="size-9 animate-pulse rounded-full bg-canvas-80" />}
        <img
          className={classNames("h-9 max-w-[2.25rem]", {
            hidden: !isLoaded,
          })}
          alt={alt}
          onLoad={() => setIsLoaded(true)}
          src={url}
          draggable={false}
        />
      </div>
      <p className="font-style-b2-body-copy">{name}</p>
    </div>
  );
}
