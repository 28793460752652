import { ReactNode, createContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { useBreakpoints } from "@blastorg/portal-pattern-library";
import { isSignupPage } from "../../hooks/useSignupCheck";
import { NavBarDesktop } from "./components/NavBarDesktop";
import { NavBarMobile } from "./components/NavBarMobile";
import { useGetSettings } from "../../../api/data/sanity/getSettings/useGetSettings";

export interface NavBarProviderValue {
  isNavTransparent: boolean;
  setIsNavTransparent: (transparent: boolean) => void;
}

const NavbarComponent = () => {
  const location = useLocation();
  const hideNavBar = isSignupPage(location.pathname);
  const isEmbedPage = location.pathname.includes("/embed");
  const { isSmallDesktop } = useBreakpoints();

  const { data } = useGetSettings();

  if (hideNavBar || isEmbedPage) {
    return null;
  }

  return isSmallDesktop ? (
    <NavBarDesktop nav={data?.nav} isAutomaticLiveEnabled={data?.settings?.enableAutomaticLiveState} />
  ) : (
    <NavBarMobile nav={data?.nav} isAutomaticLiveEnabled={data?.settings?.enableAutomaticLiveState} />
  );
  null;
};

export const NavBarContext = createContext<NavBarProviderValue | undefined>(undefined);

export const NavBarProvider = ({ children }: { children: ReactNode }) => {
  const [isNavTransparent, setIsNavTransparent] = useState(false);

  return (
    <NavBarContext.Provider
      value={{
        isNavTransparent,
        setIsNavTransparent,
      }}
    >
      <NavbarComponent />
      {children}
    </NavBarContext.Provider>
  );
};
