import { useCSGuessrGame } from "../../../../../../hooks/useCSGuessrGame";
import { useEffect, useState } from "react";
import { SelectingMapState } from "./components/SelectingMapState";
import { GuessingState } from "./components/GuessingState";
import { ResultsState } from "./components/ResultsState";
import classNames from "classnames";

const GameControlsArea = ({ className }: { className?: string }) => {
  const { state } = useCSGuessrGame();
  const [showingMapState, setShowingMapState] = useState<boolean>(false);
  const [showingGuessingState, setShowingGuessingState] = useState<boolean>(false);
  const [showingResultsState, setShowingResultsState] = useState<boolean>(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    if (state === "SELECTING_MAP") {
      setShowingResultsState(false);
      setShowingGuessingState(false);
      setShowingMapState(true);
    }

    if (state === "SELECTING_POSITION") {
      setShowingMapState(false);
      setShowingResultsState(false);
      setShowingGuessingState(true);
    }

    if (state === "SHOWING_ANSWER") {
      setShowingMapState(false);
      setShowingGuessingState(false);
      setShowingResultsState(true);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [state]);

  return (
    <div className={classNames("relative size-full", className)}>
      <SelectingMapState show={showingMapState} />
      <GuessingState show={showingGuessingState} />
      <ResultsState show={showingResultsState} />
    </div>
  );
};

export { GameControlsArea };
