import { ReactNode, createContext, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MetaTagsInfo } from "../../api/data/sanity/types/MetaTagsInfo";
import { useUpdateEffect } from "@shared/hooks/useUpdateEffect";

interface MetaContextContent {
  route: string;
  title: string;
  notFound?: boolean;
  metaTagsInfo?: MetaTagsInfo;
}

export interface MetaContextType {
  metaContext: MetaContextContent | null;
  updateMetaTags: (title: string, notFound?: boolean, metaTagsInfo?: MetaTagsInfo) => void;
}

// Create a context for the meta information
export const MetaContext = createContext<MetaContextType | undefined>(undefined);

// Meta provider component
export function MetaProvider({ children }: { children: ReactNode }) {
  const defaultTitle = "BLAST.tv - The home of the best CS tournaments, CS news and esports community!";
  const [metaContextContent, setMetaContextContent] = useState<MetaContextContent | null>(null);
  const location = useLocation();

  useEffect(() => {
    if (!metaContextContent) {
      document.title = defaultTitle;
      return;
    }
    document.title = metaContextContent.title;
  }, [metaContextContent]);

  const updateMetaTags = useCallback(
    (title: string, notFound?: boolean, metaTagsInfo?: MetaTagsInfo) => {
      if (title === document.title) return;
      setMetaContextContent({
        route: location.pathname,
        title,
        notFound,
        metaTagsInfo,
      });
    },
    [location.pathname],
  );

  useUpdateEffect(() => {
    if (metaContextContent?.route === location.pathname) return;
    if (metaContextContent?.title === defaultTitle) return;
    updateMetaTags(defaultTitle, false);
  }, [location.pathname, metaContextContent, updateMetaTags]);

  // Create a meta object to be passed as value to the context
  const meta = {
    metaContext: metaContextContent,
    updateMetaTags,
  };

  return <MetaContext.Provider value={meta}>{children}</MetaContext.Provider>;
}
