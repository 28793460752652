import { baseAssetsURL } from "../../../config";
import { Map } from "./types";

const mapBaseUrl = `${baseAssetsURL}/images/cs-guessr/maps`;
const mapBackgroundBaseUrl = `${baseAssetsURL}/images/cs-guessr/map-backgrounds`;
const mapPinsBaseUrl = `${baseAssetsURL}/images/cs-guessr/map-pins`;

export const ancientMap: Map = {
  id: "de_ancient",
  title: "Ancient",
  image: `${mapBaseUrl}/de_ancient`,
  backgroundImage: `${mapBackgroundBaseUrl}/de_ancient`,
  logoImage: `${mapPinsBaseUrl}/de_ancient`,
};

export const anubisMap: Map = {
  id: "de_anubis",
  title: "Anubis",
  image: `${mapBaseUrl}/de_anubis`,
  backgroundImage: `${mapBackgroundBaseUrl}/de_anubis`,
  logoImage: `${mapPinsBaseUrl}/de_anubis`,
};

export const infernoMap: Map = {
  id: "de_inferno",
  title: "Inferno",
  image: `${mapBaseUrl}/de_inferno`,
  backgroundImage: `${mapBackgroundBaseUrl}/de_inferno`,
  logoImage: `${mapPinsBaseUrl}/de_inferno`,
};

export const mirageMap: Map = {
  id: "de_mirage",
  title: "Mirage",
  image: `${mapBaseUrl}/de_mirage`,
  backgroundImage: `${mapBackgroundBaseUrl}/de_mirage`,
  logoImage: `${mapPinsBaseUrl}/de_mirage`,
};

export const nukeUpperMap: Map = {
  id: "de_nuke",
  title: "Nuke Top",
  image: `${mapBaseUrl}/de_nuke`,
  backgroundImage: `${mapBackgroundBaseUrl}/de_nuke`,
  logoImage: `${mapPinsBaseUrl}/de_nuke`,
};

export const nukeLowerMap: Map = {
  id: "de_nuke_lower",
  title: "Nuke Lower",
  image: `${mapBaseUrl}/de_nuke_lower`,
  backgroundImage: `${mapBackgroundBaseUrl}/de_nuke_lower`,
  logoImage: `${mapPinsBaseUrl}/de_nuke`,
};

export const overpassMap: Map = {
  id: "de_overpass",
  title: "Overpass",
  image: `${mapBaseUrl}/de_overpass`,
  backgroundImage: `${mapBackgroundBaseUrl}/de_overpass`,
  logoImage: `${mapPinsBaseUrl}/de_overpass`,
};
export const vertigoUpperMap: Map = {
  id: "de_vertigo",
  title: "Vertigo Top",
  image: `${mapBaseUrl}/de_vertigo`,
  backgroundImage: `${mapBackgroundBaseUrl}/de_vertigo`,
  logoImage: `${mapPinsBaseUrl}/de_vertigo`,
};

export const vertigoLowerMap: Map = {
  id: "de_vertigo_lower",
  title: "Vertigo Lower",
  image: `${mapBaseUrl}/de_vertigo_lower`,
  backgroundImage: `${mapBackgroundBaseUrl}/de_vertigo_lower`,
  logoImage: `${mapPinsBaseUrl}/de_vertigo`,
};

export const cs2Maps: Map[] = [
  ancientMap,
  anubisMap,
  infernoMap,
  mirageMap,
  overpassMap,
  vertigoUpperMap,
  vertigoLowerMap,
  nukeUpperMap,
  nukeLowerMap,
];
