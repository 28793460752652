import { useState } from "react";
import { InputField } from "../../../../../../../../../shared/components/InputField";
import { Button } from "@blastorg/portal-pattern-library";
import { useAuthApi } from "../../../../../../../../../api/ApiProvider/hooks";
import { getBLASTApiError } from "../../../../../../../shared/helpers/getBLASTApiError";

interface ConfirmPasswordProps {
  closeModal: () => void;
  moveNext: (password: string) => void;
}

export function ConfirmPassword({ closeModal, moveNext }: ConfirmPasswordProps) {
  const [password, setPassword] = useState("");

  const [error, setError] = useState<string | null>(null);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const { checkPassword } = useAuthApi();

  const submitPassword = async () => {
    setPasswordLoading(true);

    try {
      const checkedStatus = await checkPassword({
        password,
      });

      if (!checkedStatus) {
        setError("Password is incorrect");
        setPasswordLoading(false);
        return;
      }

      moveNext(password);
      setPasswordLoading(false);
    } catch (error) {
      setPasswordLoading(false);
      const internalError = getBLASTApiError(error);
      if (internalError) {
        setError(internalError.message);
        return;
      }
      setError("Something went wrong. Please try again.");
    }
  };

  return (
    <div className="flex flex-col gap-2 p-7 text-left md:w-[23rem]">
      <div className="w-full text-center">
        <span className="font-style-mobile-h3">Confirm Password</span>
      </div>

      <div className="flex w-full flex-col py-5 text-center">
        <span className="text-neutral-20 font-style-b3-body-copy">
          In order to verify the ownership of your account, please enter your current password.
        </span>
      </div>
      <div className="flex w-full gap-2">
        <InputField
          type="password"
          placeholder="Enter your password"
          value={password}
          onChange={(event) => {
            if (passwordLoading) return;
            setPassword(event.target.value);
          }}
          required
          autoCapitalize="off"
        />
      </div>

      <div className="flex flex-col items-center pt-5">
        <div className="flex w-full place-content-center gap-3 ">
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button onClick={submitPassword} isDisabled={!password} isLoading={passwordLoading}>
            Proceed
          </Button>
        </div>

        <div className="h-4 pt-1">{error && <span className="text-red font-style-b3-body-copy">{error}</span>}</div>
      </div>
    </div>
  );
}
