import { AvatarsRewards } from "./components/AvatarsRewards";
import { EmojiRewards } from "./components/EmojiRewards";
import { HighEndRewards } from "./components/HighEndRewards";

export function ProfileRewards() {
  return (
    <div className="grid grid-cols-1 gap-5 pb-16 md:grid-cols-2">
      <HighEndRewards />
      <AvatarsRewards />
      <EmojiRewards />
    </div>
  );
}
