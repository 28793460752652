import { Transition } from "@headlessui/react";
import { Map } from "@shared/constants/csMaps/types";
import classNames from "classnames";
import { STATE_TRANSITION_DURATION } from "../../constants";

interface MapSelectionProps {
  show: boolean;
  maps: Map[];
  onSelect: (map: Map) => void;
}

const MapSelection = ({ show, maps, onSelect }: MapSelectionProps) => {
  return (
    <Transition
      show={show}
      as="div"
      enter="absolute transition-all"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="absolute transition-all"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      style={{
        transitionDuration: `${STATE_TRANSITION_DURATION}ms`,
      }}
      className="grid size-full grid-cols-3 grid-rows-3 gap-2 max-md:gap-1"
    >
      {maps.map((map, index) => (
        <button
          key={index}
          className={classNames(
            "size-full rounded-small border-none bg-canvas-95 text-neutral-0 outline-none",
            "cursor-pointer transition-all duration-300 ease-in-out hover:bg-canvas-90",
            "relative flex flex-col items-center justify-center overflow-hidden p-2",
          )}
          onClick={() => onSelect(map)}
        >
          <img
            src={map.backgroundImage + "?width=200"}
            alt=""
            className="pointer-events-none absolute left-0 top-0 z-0 size-full object-cover opacity-20"
          />
          <img
            src={map.logoImage + "?height=32"}
            alt=""
            className="pointer-events-none h-8 object-contain max-md:h-6"
          />
          <span className="pointer-events-none z-[1] mt-2 flex w-fit items-center justify-center text-center text-neutral-0 font-style-label-3">
            {map.title}
          </span>
        </button>
      ))}
    </Transition>
  );
};

export { MapSelection };
