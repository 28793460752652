import { Transition } from "@headlessui/react";
import { STATE_TRANSITION_DURATION } from "../../../../constants";

const SelectingMapState = ({ show }: { show: boolean }) => {
  return (
    <Transition
      show={show}
      enter="transition-all"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-all"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className="absolute left-0 top-0 my-5 block w-full text-center font-style-label-3 max-md:mb-2 max-md:mt-0 max-md:text-left"
      style={{
        transitionDuration: `${STATE_TRANSITION_DURATION}ms`,
      }}
    >
      Which map does this image belong to?
    </Transition>
  );
};

export { SelectingMapState };
