import { z } from "zod";
import { CSGuessrPositionSchema } from "./CSGuessrPositionSchema";

export const UserAnswerResponseSchema = z.object({
  id: z.string(),
  mapId: z.string(),
  position: CSGuessrPositionSchema,
  image: z.string(),
  guessMapId: z.string(),
  guessPosition: CSGuessrPositionSchema,
  score: z.number(),
  distance: z.number(),
});
