import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../queryKeys";
import { getSeriesByTournamentId } from ".";

export type SeriesByTournament = Awaited<ReturnType<typeof getSeriesByTournamentId>>;

const useFetchSeriesByTournament = ({ tournamentId, enabled }: { tournamentId?: string; enabled?: boolean }) => {
  return useQuery({
    queryKey: queryKeys.seriesByTournamentId(tournamentId),
    queryFn: () => (tournamentId ? getSeriesByTournamentId(tournamentId) : undefined),
    enabled,
    staleTime: 5000,
  });
};

export { useFetchSeriesByTournament };
