import { Typography } from "@blastorg/portal-pattern-library";

export function NoLeaderboard() {
  return (
    <div className=" h-32 w-full bg-center bg-repeat md:h-96 ">
      <div className="flex h-full place-content-center  place-items-center ">
        <Typography variant="mobileH4" color="white" className="block md:hidden">
          Waiting for the first round to finish
        </Typography>
        <Typography variant="desktopH3" color="white" className="hidden md:block">
          Waiting for the first round to finish
        </Typography>
      </div>
    </div>
  );
}
