import { PageSlot } from "@blastorg/portal-pattern-library";
import { YoutubeVideoModule as YoutubeVideoModuleType } from "@data/sanity/types/YoutubeVideoModule";
import YoutubeVideoModule from "@shared/components/cmsModules/YoutubeVideoModule";
import { useEmojiRain } from "../Live/components/shared/LiveButton/hooks/useEmojiRain/useEmojiRain";
import Particles from "react-tsparticles";
import { useTimeout } from "usehooks-ts";

function RainbowSixPage() {
  const sanityVideoYoutubeContent: YoutubeVideoModuleType = {
    videoId: "7YqgdFjX5QA",
    autoplay: true,
    loop: true,
    _key: "7YqgdFjX5QA",
    _type: "youtubeVideoModule",
    name: "Rainbow Six Siege",
  };

  const { triggerConfetti, particlesInit } = useEmojiRain();

  const imageUrl = "https://assets.blast.tv/social/r6gottem.png";

  useTimeout(() => {
    triggerConfetti(imageUrl);
  }, 500);

  return (
    <>
      <img src={imageUrl} className="invisible absolute size-px" />
      <Particles init={particlesInit} />
      <div className="min-h-fill-available m-nav mb-24">
        <PageSlot>
          <YoutubeVideoModule content={sanityVideoYoutubeContent} />
        </PageSlot>
      </div>
    </>
  );
}

export { RainbowSixPage };
