import { useQuery } from "@tanstack/react-query";
import { getEmojis } from ".";

function useGetEmojis() {
  return useQuery({
    queryKey: ["emojis"],
    queryFn: getEmojis,
    gcTime: Infinity,
  });
}

export { useGetEmojis };
