import { ReactNode, createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useGetLocations } from "../../../../api/data/csGuessr/getLocationsForToday/useGetLocations";
import { useGetUserAnswers } from "../../../../api/data/csGuessr/getUserAnswers/useGetUserAnswers";
import { Map } from "@shared/constants/csMaps/types";
import { cs2Maps } from "@shared/constants/csMaps";
import { usePostUserAnswer } from "../../../../api/data/csGuessr/postUserAnswer/postUserAnswer";
import { Round, State } from "./types";
import { useAuth } from "../../../../auth/AuthProvider/hooks/useAuth";
import { useCallToAction } from "../../../../shared/hooks/useCallToAction";
import {
  CSGuessrLocation,
  CSGuessrPosition,
  CSGuessrUserAnswer,
  PostUserAnswerParams,
} from "../../../../api/data/csGuessr/shared/types";
import { rudderstack } from "../../../../rudderstack";

export interface CSGuessrGameValue {
  isLoading: boolean;
  isSubmittingAnswer: boolean;
  locations: CSGuessrLocation[];
  rounds: { location: CSGuessrLocation; userAnswer?: CSGuessrUserAnswer }[];
  currentRoundIndex?: number;
  currentRound?: Round;
  submitUserAnswer: () => void;
  state: State;
  maps: Map[];
  selectedMap?: Map;
  selectMap: (map: Map | undefined) => void;
  goToNextRound: () => void;
  selectedPosition?: CSGuessrPosition;
  setSelectedPosition: (position: CSGuessrPosition | undefined) => void;
  isHowToPlayOpen: boolean;
  openHowToPlay: () => void;
  closeHowToPlay: () => void;
  dailyScore: number;
}

export const CSGuessrGameContext = createContext<CSGuessrGameValue | undefined>(undefined);

const useCSGuessrGameValue = (): CSGuessrGameValue => {
  const auth = useAuth();
  const { data: locations, isLoading: isFetchingLocations } = useGetLocations();
  const {
    data: userAnswers,
    isLoading: isFetchingUserAnswers,
    refetch: refetchUserAnswers,
  } = useGetUserAnswers({ enabled: auth.isAuthenticated });
  const [rounds, setRounds] = useState<Round[]>([]);
  const [state, setState] = useState<State>("GAME_ENDED");
  const [currentRoundIndex, setCurrentRoundIndex] = useState<number | undefined>(undefined);
  const [selectedMap, setSelectedMap] = useState<Map | undefined>(undefined);
  const [selectedPosition, setSelectedPosition] = useState<CSGuessrPosition | undefined>(undefined);
  const handleCallToAction = useCallToAction();
  const [isHowToPlayOpen, setIsHowToPlayOpen] = useState(false);
  const { mutate: mutateSubmitUserAnswer, isPending: isSubmittingUserAnswer } = usePostUserAnswer({
    onSuccess: (answer) => {
      setRounds((prev) => {
        const newRounds = [...prev];
        for (const round of newRounds) {
          if (round.location.id === answer.location.id) {
            round.userAnswer = answer;
          }
        }

        return newRounds;
      });
    },
  });

  const allRoundsAnswered = useMemo(() => rounds.every((round) => round.userAnswer !== undefined), [rounds]);

  const currentRound = useMemo(() => {
    if (!rounds.length || currentRoundIndex === undefined) return undefined;

    return rounds[currentRoundIndex];
  }, [rounds, currentRoundIndex]);

  const submitUserAnswer = useCallback(() => {
    if (!selectedMap || !selectedPosition || !currentRound) return;

    const params: PostUserAnswerParams = {
      locationId: currentRound.location.id,
      guessMapId: selectedMap.id,
      guessPosition: selectedPosition,
    };

    if (!userAnswers) {
      rudderstack?.track("Minigame Started", {
        context: "csguessr",
      });
    }

    mutateSubmitUserAnswer(params);
  }, [currentRound, mutateSubmitUserAnswer, selectedMap, selectedPosition, userAnswers]);

  useEffect(() => {
    if (!locations) return;

    const newUserAnswers = auth.isAuthenticated ? userAnswers : [];
    const rounds = locations.map((location) => ({
      location,
      userAnswer: newUserAnswers?.find((answer) => answer.location.id === location.id),
    }));

    setRounds(rounds);

    const currentRoundIndex = rounds.findIndex((round) => round.userAnswer === undefined);
    setCurrentRoundIndex(currentRoundIndex);
  }, [locations, userAnswers, auth.isAuthenticated]);

  useEffect(() => {
    if (allRoundsAnswered && currentRoundIndex !== rounds.length - 1) {
      setState("GAME_ENDED");
      return;
    }

    if (!selectedMap) {
      setState("SELECTING_MAP");
    } else if (currentRound?.userAnswer) {
      setState("SHOWING_ANSWER");
    } else {
      setState("SELECTING_POSITION");
    }
  }, [rounds, currentRound?.userAnswer, selectedMap, allRoundsAnswered, currentRoundIndex]);

  const goToNextRound = useCallback(() => {
    if (allRoundsAnswered) {
      setState("GAME_ENDED");
    } else {
      setSelectedMap(undefined);
      setSelectedPosition(undefined);
      setCurrentRoundIndex((prev) => (prev !== undefined ? prev + 1 : undefined));
      setState("SELECTING_MAP");
    }
  }, [setSelectedMap, setSelectedPosition, setCurrentRoundIndex, setState, allRoundsAnswered]);

  useEffect(() => {
    if (auth.isAuthenticated) {
      refetchUserAnswers();
    } else {
      setSelectedMap(undefined);
      setSelectedPosition(undefined);
      setState("SELECTING_MAP");
    }
  }, [auth.isAuthenticated, refetchUserAnswers, setSelectedMap, setSelectedPosition, setState]);

  const selectMap = useCallback(
    (map: Map | undefined) => {
      if (!auth.isAuthenticated) {
        return handleCallToAction({
          callToAction: {
            type: "register",
          },
        });
      } else {
        setSelectedMap(map);
      }
    },
    [setSelectedMap, auth.isAuthenticated, handleCallToAction],
  );

  const dailyScore = useMemo(() => {
    return rounds.reduce((acc, round) => {
      return acc + (round.userAnswer?.score ?? 0);
    }, 0);
  }, [rounds]);

  return {
    isLoading: isFetchingLocations || (auth.isAuthenticated && isFetchingUserAnswers),
    isSubmittingAnswer: isSubmittingUserAnswer,
    locations: locations ?? [],
    rounds,
    currentRoundIndex,
    currentRound,
    submitUserAnswer,
    selectedPosition,
    setSelectedPosition,
    goToNextRound,
    state,
    maps: cs2Maps,
    selectMap,
    selectedMap,
    dailyScore,
    isHowToPlayOpen,
    openHowToPlay: () => setIsHowToPlayOpen(true),
    closeHowToPlay: () => setIsHowToPlayOpen(false),
  };
};

const CSGuessrGameProvider = ({ children }: { children: ReactNode }) => {
  const csGuessrGameProviderValue = useCSGuessrGameValue();

  return <CSGuessrGameContext.Provider value={csGuessrGameProviderValue}>{children}</CSGuessrGameContext.Provider>;
};

export { CSGuessrGameProvider };
