import { useContext } from "react";
import { CSGuessrGameContext } from "../providers/CSGuessrGameProvider";

const useCSGuessrGame = () => {
  const csGuessrGameContext = useContext(CSGuessrGameContext);
  if (!csGuessrGameContext) {
    throw new Error("useCSGuessrGameContext must be used within a CSGuessrGameContext");
  }
  return {
    ...csGuessrGameContext,
  };
};

export { useCSGuessrGame };
