import sortBy from "lodash/sortBy";
import { useMemo } from "react";
import { Team } from "../../../api/data/tournament/shared/types/Team";
import { generateTeamLogoSrc } from "@data/tournament/shared/helpers/generateTeamLogoSrc";
import { TeamImage } from "./components/TeamImage";

export function ProfileTeams({
  teams,
  checkedTeamIds,
  onTeamClick,
}: {
  teams: Team[];
  checkedTeamIds: string[];
  onTeamClick: (teamId: string) => void;
}) {
  const sortedTeams = useMemo(() => sortBy(teams, "name"), [teams]);

  return (
    <div className="flex size-full flex-col overflow-hidden overflow-y-auto">
      <div className="grid grid-cols-1 md:grid-cols-3 lg:min-w-[44rem]">
        {sortedTeams.map((team) => {
          return (
            <div key={team.uuid} onClick={() => onTeamClick(team.uuid)}>
              <div
                className={
                  "flex h-full place-content-start items-center bg-canvas-90 p-4 hover:cursor-pointer hover:bg-canvas-80 hover:opacity-80"
                }
              >
                <input
                  type="checkbox"
                  onChange={() => onTeamClick(team.uuid)}
                  checked={checkedTeamIds.includes(team.uuid)}
                />
                <TeamImage
                  url={generateTeamLogoSrc({ id: team.uuid, width: 36, height: 36 })}
                  alt={team.name}
                  name={team.name}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
