import { client } from "../../../apiClients/sanity";
import { MetaTagsInfo } from "../types/MetaTagsInfo";

export interface SanityContentPage {
  metaTagsInfo: MetaTagsInfo;
  route: string;
  title: string;
}

const GhostQuery = () => {
  // https://www.sanity.io/docs/query-cheat-sheet

  // get the single, most recently published homepage document
  // https://www.sanity.io/docs/drafts#a4a3095a2d87
  const query = `*[_type == "ghostPage"] | order(_updatedAt desc) {
  ...,
}`;
  return query;
};

async function getGhostPage(): Promise<SanityContentPage[]> {
  const query = GhostQuery();

  const data = await client.fetch(query).catch((error) => {
    throw new Error("failed to fetch CMS data", { cause: error });
  });

  const returnData: SanityContentPage[] = [];

  for (const page of data) {
    returnData.push({
      metaTagsInfo: page?.metaTagsInfo,
      route: page?.route,
      title: page?.title,
    });
  }

  return returnData;
}

export { getGhostPage };
