import { z } from "zod";
import { baseAssetsURL } from "../../../../config";

const imageOptionsSchema = z.object({
  width: z.string().optional(),
  height: z.string().optional(),
  format: z.literal("auto"),
});

type ImageOptions = z.infer<typeof imageOptionsSchema>;
type ImageCategory = "products" | "emojis" | "avatars" | "teams" | "players" | "triggers";

export const generateImageUrl = (category: ImageCategory, filename: string, options?: ImageOptions) => {
  if (!options) return `${baseAssetsURL}/images/${category}/${filename}`;

  const searchParams = new URLSearchParams(options);

  return `${baseAssetsURL}/images/${category}/${filename}?${searchParams}`;
};
