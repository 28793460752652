import { Helmet } from "react-helmet-async";
import { useMeta } from "./meta/MetaProvider/useMeta";
import { useLocation } from "react-router-dom";
import { urlFor } from "./api/apiClients/sanity";

export function AddMetaTags() {
  const { metaContext } = useMeta();
  const location = useLocation();
  const metaTagsInfo = metaContext?.metaTagsInfo;

  if (!metaTagsInfo && metaContext?.notFound)
    return (
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
    );

  if (!metaTagsInfo) return null;
  return (
    <Helmet>
      <link rel="canonical" href={`https://blast.tv${location.pathname?.toLowerCase()}`} />
      <meta name="description" content={metaTagsInfo?.metaDescription} />

      {/* Open Graph */}
      <meta property="og:url" content={`https://blast.tv${location.pathname?.toLowerCase()}`} />
      <meta property="og:title" content={metaTagsInfo?.metaTitle} />
      <meta property="og:description" content={metaTagsInfo?.metaDescription} />
      {metaTagsInfo.metaImage ? <meta property="og:image" content={urlFor(metaTagsInfo?.metaImage).url()} /> : null}

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@BLASTtv" />
      <meta name="twitter:creator" content="@BLASTtv" />
      <meta name="twitter:title" content={metaTagsInfo?.metaTitle} />
      <meta name="twitter:description" content={metaTagsInfo?.metaDescription} />
      {metaTagsInfo.metaImage ? <meta name="twitter:image" content={urlFor(metaTagsInfo?.metaImage).url()} /> : null}

      <meta />
    </Helmet>
  );
}
