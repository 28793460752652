import { useRef, useEffect, useContext, useMemo } from "react";
import { gsap } from "gsap";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "../../../../../../../Icon";
import { GameIcon } from "@shared/components/GameIcon";
import { GameIdContext } from "@providers/GameIdProvider";
import { useOnClickOutside } from "usehooks-ts";
import classNames from "classnames";

const GameSelector = () => {
  const componentRef = useRef<HTMLDivElement>(null);

  const csIconRef = useRef<HTMLDivElement>(null);
  const rlIconRef = useRef<HTMLDivElement>(null);
  const dotaIconRef = useRef<HTMLDivElement>(null);

  const refs = useMemo(
    () => ({
      cs: csIconRef,
      rl: rlIconRef,
      dota: dotaIconRef,
    }),
    [],
  );

  const dropdownRef = useRef<HTMLDivElement>(null);
  const selectionAreaRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<HTMLDivElement>(null);
  const gameContext = useContext(GameIdContext);
  const selectionAreaOpened = useRef(false);
  const screenHeight = window.innerHeight - 200;
  const navigate = useNavigate();

  useEffect(() => {
    const changeOpacityOfGameLogos = () => {
      gameContext.enabledGames.forEach((gameId) => {
        // show selected game icon
        if (gameContext.gameId === gameId) {
          gsap.to(refs[gameId].current, { duration: 0.25, opacity: 1, top: "50%", transform: "translateY(-50%)" });
        } else {
          // hide other game icons
          gsap.to(refs[gameId].current, { duration: 0.15, opacity: 0 });
        }
      });

      // reset position of game icons
      gsap.delayedCall(0.25, () => {
        gameContext.enabledGames.forEach((gameId) => {
          if (gameContext.gameId !== gameId) {
            gsap.to(refs[gameId].current, {
              duration: 0.15,
              top: "50%",
              transform: "translateY(-50%)",
            });
          }
        });
      });
    };

    const resetOpacityOfGameLogos = () => {
      gameContext.enabledGames.forEach((gameId, index) => {
        const top = 0.5 + index * 2.5 + "rem";
        gsap.to(refs[gameId].current, { duration: 0.25, opacity: 1, top, transform: "translateY(0%)" });
      });
    };

    const toggleDropdown = () => {
      const showDropdown = gameContext.gameId !== null;
      gsap.to(dropdownRef.current, {
        duration: showDropdown ? 0.25 : 0.15,
        border: showDropdown ? "1px solid #8D858C" : "none",
        padding: showDropdown ? "0.75rem" : "0px",
        borderRadius: showDropdown ? "0.25rem" : "0px",
        height: showDropdown ? "2.5rem" : "7.5rem",
      });
    };

    if (gameContext.gameId) {
      changeOpacityOfGameLogos();
    } else {
      resetOpacityOfGameLogos();
    }
    toggleDropdown();
  }, [gameContext.gameId, gameContext.enabledGames, refs]);

  const closeSelectionArea = () => {
    gsap.to(selectionAreaRef.current, { duration: 0.25, opacity: 0, zIndex: -10 });
    gsap.to(arrowRef.current, { duration: 0.15, rotateX: 0 });
    selectionAreaOpened.current = false;
  };
  const openSelectionArea = () => {
    gsap.to(selectionAreaRef.current, { duration: 0.25, opacity: 1, zIndex: 10 });
    gsap.to(arrowRef.current, { duration: 0.15, rotateX: 180 });
    selectionAreaOpened.current = true;
  };
  const delayNavigate = (gameId?: string) => {
    if (gameId) {
      gsap.delayedCall(0.15, () => {
        navigate(`/${gameId}`);
      });
    }
  };
  const openCloseSelectionArea = (gameId?: string) => {
    if (selectionAreaOpened.current) {
      closeSelectionArea();
    } else {
      openSelectionArea();
    }

    if (gameId) {
      gsap.delayedCall(0.15, () => {
        navigate(`/${gameId}`);
      });
    }
  };

  useOnClickOutside(componentRef, closeSelectionArea);

  return (
    <div ref={componentRef} className="relative flex size-full  cursor-pointer items-center justify-between">
      <div
        ref={dropdownRef}
        className="relative flex w-[9rem] items-center justify-between"
        onClick={() => (gameContext.gameId !== null ? openCloseSelectionArea() : undefined)}
      >
        {gameContext.enabledGames.map((gameId, index) => (
          <div
            key={gameId}
            ref={refs[gameId]}
            className={classNames("absolute", {
              "top-[8px]": index === 0,
              "top-[16px]": index === 1,
              "top-[24px]": index === 2,
            })}
          >
            {gameContext.gameId !== null ? (
              <div className="flex">{GameIcon({ gameId })}</div>
            ) : (
              <Link to={`/${gameId}`}>{GameIcon({ gameId })}</Link>
            )}
          </div>
        ))}

        {gameContext.gameId && (
          <div
            ref={arrowRef}
            className="absolute right-2 top-1/2 flex h-1 -translate-y-1/2 items-center justify-center"
          >
            <Icon icon="arrowDownNavbar" className="text-yellow" />
          </div>
        )}
      </div>

      {/* Menu shown when the user clicks on the current game icon. It shows the other games the user can navigate to. */}
      <div
        ref={selectionAreaRef}
        className="absolute top-12 -z-10 w-full bg-canvas-100 opacity-0"
        style={{ height: `${screenHeight}px` }}
      >
        <div className="mt-5 flex flex-col gap-5 px-2">
          {gameContext.enabledGames.map((gameId) => {
            if (gameContext.gameId !== gameId) {
              return (
                <div
                  key={gameId}
                  onClick={() => {
                    closeSelectionArea();
                    delayNavigate(gameId);
                  }}
                >
                  {GameIcon({ gameId })}
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export const MobileGameSelection = () => {
  const gameContext = useContext(GameIdContext);

  if (gameContext.enabledGames.length === 1) return null;

  return (
    <div>
      <GameSelector />
    </div>
  );
};
