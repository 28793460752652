import { Button, CheckboxField } from "@blastorg/portal-pattern-library";
import classNames from "classnames";
import { useState } from "react";
import { useTimeout } from "usehooks-ts";
import { useUserProfile } from "../../../../../../auth/useUserProfile";
import { useUpdateProfile } from "../../../../hooks/useUpdateProfile";
import { DeleteProfileFlowModal } from "./components/DeleteProfileFlowModal";
import { ChangePasswordModal } from "./components/ChangePasswordModal";
import { Switch } from "@headlessui/react";

export const PreferencesTab = () => {
  const userProfile = useUserProfile();
  const [openDeleteProfileModal, setOpenDeleteProfileModal] = useState(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const { mutate: updateUserProfile } = useUpdateProfile({
    userId: userProfile?.id,
  });
  const [newsletterFeedback, setNewsletterFeedback] = useState<{
    message: string;
    messageType: "success" | "error";
  } | null>(null);

  useTimeout(
    () => {
      setNewsletterFeedback(null);
    },
    newsletterFeedback ? 3000 : null,
  );

  const handleNewsletterSubscribeChange = async (checked: boolean) => {
    {
      updateUserProfile(
        { optIn: checked },
        {
          onSuccess: () => {
            checked
              ? setNewsletterFeedback({
                  message: "Welcome to the club!",
                  messageType: "success",
                })
              : setNewsletterFeedback({
                  message: "Sad to see you leave :(",
                  messageType: "error",
                });
          },
        },
      );
    }
  };
  const isNewsLetter = userProfile?.optIn || false;

  const handleHighContrastModechange = async (checked: boolean) => {
    {
      updateUserProfile({ highContrastMode: checked });
    }
  };
  const isHighContrastMode = userProfile?.highContrastMode || false;

  return (
    <>
      <ChangePasswordModal isOpen={openChangePasswordModal} closeModal={() => setOpenChangePasswordModal(false)} />
      <DeleteProfileFlowModal isOpen={openDeleteProfileModal} closeModal={() => setOpenDeleteProfileModal(false)} />
      <div className="col-span-2 flex w-full flex-col rounded-small bg-canvas-95 p-7 max-md:p-5 md:col-span-1">
        <span className="font-style-label-3">Preferences</span>
        <div className="my-3 h-0.5 w-full bg-neutral-50/30" />

        <div className="mt-3 flex h-full flex-col place-content-start items-start gap-2 text-start">
          <CheckboxField
            name="isNewsletterSubscribed"
            label="I want all the latest BLAST.tv Major, BLAST Premier and BLAST.tv community news as well as exclusive
          BLAST.tv community perks!"
            isChecked={isNewsLetter}
            onChange={() => handleNewsletterSubscribeChange(!isNewsLetter)}
          />
          <div className="h-4 w-full">
            {newsletterFeedback && (
              <span
                className={classNames("mb-3 font-style-b2-body-copy", {
                  "text-red": newsletterFeedback.messageType === "error",
                  "text-green": newsletterFeedback.messageType === "success",
                })}
              >
                {newsletterFeedback.message}
              </span>
            )}
          </div>
          <div className="flex flex-col gap-2 pb-4">
            <Switch.Group>
              <div className="flex items-center">
                <Switch
                  checked={isHighContrastMode}
                  onChange={() => handleHighContrastModechange(!isHighContrastMode)}
                  className={`${
                    isHighContrastMode ? "bg-green" : "bg-canvas-80"
                  } relative inline-flex h-5 w-11 items-center rounded-full border-0 px-1 transition-colors hover:cursor-pointer focus:outline-none focus:ring-1 focus:ring-canvas-90 focus:ring-offset-1`}
                >
                  <span
                    className={`${
                      isHighContrastMode ? "translate-x-5" : "translate-x-0"
                    } inline-block size-4 rounded-full bg-white transition-transform`}
                  />
                </Switch>
                <Switch.Label className="ml-2 flex items-center gap-x-2 font-style-b2-body-copy hover:cursor-pointer">
                  High contrast mode
                  <span className="font-style-b4-body-copy">(Only Counter-Strikle)</span>
                </Switch.Label>
              </div>
            </Switch.Group>
          </div>
          <div className="flex flex-col gap-2">
            <span className="text-neutral-50 font-style-b2-body-copy">Password</span>
            <Button
              onClick={() => {
                setOpenChangePasswordModal(true);
              }}
              className="text-left"
              variant="tertiary"
            >
              Change my password
            </Button>
          </div>

          <div className="mb-7 flex flex-col gap-2">
            <span className="text-neutral-50 font-style-b2-body-copy">Account</span>
            <Button
              onClick={() => {
                setOpenDeleteProfileModal(true);
              }}
              className="text-left"
              variant="tertiary"
            >
              Delete my account
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
