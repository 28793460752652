import { CaseSpinner } from "./components/CaseSpinner";
import { Fragment, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import classNames from "classnames";

const ANIMATION_DURATION = 6000;

type CaseRouletteProps = {
  list: {
    id: string;
    name: string;
    rarity: string;
    imageSrc: string;
  }[];
  winner: {
    id: string;
    name: string;
    rarity: string;
    imageSrc: string;
  };
  inline?: boolean;
};

const CaseRoulette = (props: CaseRouletteProps) => {
  const { list, winner, inline } = props;
  const [spinnerState, setSpinnerState] = useState<"idle" | "spinning" | "completed">("idle");

  useEffect(() => {
    setSpinnerState("idle");
    setTimeout(() => {
      setSpinnerState("spinning");
    }, 10);
  }, []);

  useEffect(() => {
    if (spinnerState === "spinning") {
      const timeout = setTimeout(() => {
        setSpinnerState("completed");
      }, ANIMATION_DURATION);
      return () => clearTimeout(timeout);
    }
  }, [spinnerState]);

  const animating = spinnerState === "spinning";

  return (
    <div className={classNames("relative mx-auto size-full pt-2", {})}>
      <Transition show={spinnerState === "completed"} as={Fragment}>
        <Transition.Child
          enter="transition-all duration-500"
          enterFrom="scale-0 opacity-0"
          enterTo="scale-100 opacity-100"
          className={classNames("flex size-full flex-col items-center justify-center overflow-hidden")}
        >
          <div className={classNames("relative mx-auto", { "h-48 w-48": !inline, "h-36 w-36": inline })}>
            <Transition.Child
              enter="transition-all delay-200 duration-500"
              enterFrom="scale-0 opacity-0"
              enterTo="translate-x-0 translate-y-0 rotate-0 scale-100 opacity-100"
              className="absolute inset-0 size-full scale-100"
            >
              <div
                className={classNames(
                  "-mt-2 size-1/2 translate-x-1/2 translate-y-1/2 animate-pulse rounded-full blur-lg",
                  {
                    "bg-neutral-50/60": winner.rarity === "common",
                    "bg-gradient-to-l from-[#FFD700]/70 to-[#FFF8D3]/70": winner.rarity === "gold",
                    "bg-gradient-to-r from-[#FFFFFF]/70 via-[#A5B4FF]/70 to-[#E5B3FF]/70": winner.rarity === "diamond",
                  },
                )}
              ></div>
            </Transition.Child>
            <Transition.Child
              enter="transition-all delay-200 duration-500"
              enterFrom="translate-y-1/3 rotate-45 scale-0 opacity-0"
              enterTo="-translate-y-5 translate-x-0 rotate-0 scale-100 opacity-100"
              className="absolute inset-0 overflow-hidden rounded-full"
            >
              <img src={winner.imageSrc} alt="" className="relative z-10 h-full w-36 max-w-full object-contain" />
            </Transition.Child>
          </div>
        </Transition.Child>
      </Transition>
      {spinnerState !== "completed" && (
        <div className={classNames({ "-mt-6": inline })}>
          <CaseSpinner items={list} animating={animating} inline={true} />
        </div>
      )}
    </div>
  );
};

export { CaseRoulette };
