import styled from "styled-components";
import * as constants from "../../../styles/constants";

/**
 * This is a styled component that is used to frame the content of a module.
 * It centers content on the page and adds some padding.
 * @deprecated This component is deprecated and should not be used anymore.
 */
export const LegacyContentFrame = styled.div`
  max-width: ${constants.maxWidthContentFrame}px;

  margin: ${constants.framedContentVerticalSpacing} auto;
  padding: 0 ${constants.framedContentHorizontalSpacing};

  @media (max-width: ${constants.maxWidthMobile}) {
    margin: ${constants.framedContentVerticalSpacingMobile} auto;
    padding: 0 ${constants.framedContentHorizontalSpacingMobile};
  }
`;
