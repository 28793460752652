import { blastApi } from "../../../apiClients/blastApi";
import { z } from "zod";

export const FantasyLeaderboardRowSchema = z.object({
  userId: z.string(),
  username: z.string().optional(),
  userAvatar: z.string().optional(),
  position: z.number().optional(),
  points: z.number().optional(),
  picks: z.array(
    z.object({
      nickname: z.string(),
      isCaptain: z.boolean(),
    }),
  ),
});

export type FantasyLeaderboardRowType = z.infer<typeof FantasyLeaderboardRowSchema>;

export const GetLeaderboardSchema = z.object({
  playerCount: z.number(),
  calculationId: z.string().optional(),
  endOfActiveUsersFound: z.boolean().optional(),
  positions: z.array(FantasyLeaderboardRowSchema),
  userPosition: FantasyLeaderboardRowSchema.optional(),
});

export type GetLeaderBoardType = z.infer<typeof GetLeaderboardSchema>;

const getUrl = ({ seasonId, leagueId, eventId }: { seasonId: string; leagueId?: string; eventId?: string }) => {
  if (leagueId) {
    if (eventId) return `/v1/fantasy/seasons/${seasonId}/events/${eventId}/leagues/${leagueId}/leaderboard`;
    return `/v1/fantasy/seasons/${seasonId}/leagues/${leagueId}/leaderboard`;
  }
  if (eventId) return `/v1/fantasy/seasons/${seasonId}/events/${eventId}/leaderboard`;
  return `/v1/fantasy/seasons/${seasonId}/leaderboard`;
};

export const getLeaderboard = async ({
  seasonId,
  leagueId,
  eventId,
  calculationId,
  offset,
}: {
  seasonId?: string;
  leagueId?: string;
  eventId?: string;
  calculationId?: string;
  offset?: number;
}) => {
  if (!seasonId) throw new Error("seasonId is required to get a leaderboard");
  const url = getUrl({ seasonId, leagueId, eventId });
  const { data } = await blastApi.get(url, { params: { offset, calculationId } });
  return GetLeaderboardSchema.parse(data);
};
