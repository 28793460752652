import { z } from "zod";
import { DetailedPickSchema } from "../../../fantasy/getUserPicksDetailed";
import { ImageSchema } from "../schemas/ImageSchema";

export interface FantasySeasonRef {
  _type: "reference";
  _ref: string;
}

const SponsorSchema = z.object({
  alignment: z.enum(["left", "right", "center"]).optional(),
  title: z.string().optional(),
  image: ImageSchema.optional(),
  width: z.number().optional(),
});

const FantasyPlayerSchema = z.object({
  playerId: z.string(),
  title: z.string(),
  teamId: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  nickname: z.string(),
  team: z.object({
    uuid: z.string(),
    name: z.string(),
    shorthand: z.string(),
  }),
  isCaptain: z.boolean().optional(),
  details: DetailedPickSchema.optional(),
});

const SeasonSchema = z.object({
  startTime: z.string(),
  title: z.string(),
  seasonNameTitle: z.string(),
  endTime: z.string(),
  uid: z.string(),
});

export const FantasyEventSchema = z.object({
  title: z.string(),
  headerImage: ImageSchema,
  eventNameTitle: z.string(),
  tournamentId: z.string(),
  uid: z.string(),
  season: SeasonSchema,
  endTime: z.string(),
  diamondPlayers: z.array(FantasyPlayerSchema),
  platinumPlayers: z.array(FantasyPlayerSchema),
  goldPlayers: z.array(FantasyPlayerSchema),
  silverPlayers: z.array(FantasyPlayerSchema),
  bronzePlayers: z.array(FantasyPlayerSchema),
  sponsorBanner: SponsorSchema,
  calculationSchemaId: z.string(),
});

export const CurrentSeasonSchema = z.object({
  uid: z.string(),
  seasonNameTitle: z.string(),
  events: z.array(FantasyEventSchema),
});

export type FantasyEvent = z.infer<typeof FantasyEventSchema>;
export type FantasyPlayer = z.infer<typeof FantasyPlayerSchema>;
